var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"display-settings__table",attrs:{"data-cy":"table-other-settings"}},[_c('h2',{staticClass:"settings__subtitle",domProps:{"textContent":_vm._s(_vm.$t('other-settings'))}}),_vm._v(" "),_c('label',{staticClass:"settings__label",attrs:{"for":"table-width"},domProps:{"textContent":_vm._s(_vm.$t('display-mode'))}}),_vm._v(" "),_c('button-group',{attrs:{"id":"table-width","value":_vm.getOtherSetting('widetable')},on:{"input":function($event){return _vm.wideTablehandler($event)}}},[_c('radio',{attrs:{"selected-value":"hide","data-value":"hide"}},[_vm._v("\n      "+_vm._s(_vm.$t("standard-table-width"))+"\n    ")]),_vm._v(" "),_c('radio',{attrs:{"selected-value":"show","data-value":"show"}},[_vm._v("\n      "+_vm._s(_vm.$t("extra-wide-table-width"))+"\n    ")])],1),_vm._v(" "),_c('label',{staticClass:"settings__label",attrs:{"for":"list-projects"}},[_vm._v("\n    "+_vm._s(_vm.$t('projects-list-sorting'))+"\n    "),_c('span',{staticClass:"kw kw-info",on:{"mouseover":function($event){_vm.tooltip({ text: _vm.$t('projects-list-sorting-tooltip'), event: $event })}}})]),_vm._v(" "),_c('v-select',{attrs:{"id":"list-projects","options-label":"label","options-value":"value","options":_vm.projectsSort,"value":_vm.getDisplaySetting('category_sort')},on:{"change":function (e) { return _vm.save({ setting: 'category_sort', value: e }); }}}),_vm._v(" "),_c('label',{staticClass:"settings__label",attrs:{"for":"reporting-currency"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('reporting-currency'))}}),_vm._v(" "),_c('span',{staticClass:"kw kw-info",on:{"mouseover":function($event){_vm.tooltip({
                  text: _vm.$t('reporting-currency-tooltip'),
                  event: $event,
              })}}})]),_vm._v(" "),_c('custom-v-select',{attrs:{"id":"reporting-currency","data-cy":"user-reporting-currency","options-label":"label","options-value":"value","options":_vm.getCurrencies,"search":"","search-key":"label","value":_vm.initialCurrency},on:{"change":function (e) { return _vm.save({ setting: 'currency_code', value: e }); }}}),_vm._v(" "),_c('label',{staticClass:"settings__label",attrs:{"for":"project-metrics"}},[_vm._v("\n    "+_vm._s(_vm.$t('kw-pos-tracking-comparison-metrics'))+"\n    "),_c('span',{staticClass:"kw kw-info",on:{"mouseover":function($event){_vm.tooltip({
                text: _vm.$t('kw-pos-tracking-comparison-metrics-tooltip'),
                event: $event,
            })}}})]),_vm._v(" "),_c('v-select',{attrs:{"id":"project-metrics","options-label":"label","options-value":"value","options":_vm.periodConfig,"value":_vm.getDisplaySetting('metrics')},on:{"change":function (e) { return _vm.save({ setting: 'metrics', value: e }); }}}),_vm._v(" "),_c('label',{staticClass:"settings__label",attrs:{"for":"metric-ranges"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('metrics-buckets'))}}),_vm._v(" "),_c('span',{staticClass:"kw kw-info",on:{"mouseover":function($event){_vm.tooltip({
                  text: _vm.$t('metrics-buckets-tooltip'),
                  event: $event,
              })}}})]),_vm._v(" "),_c('div',{staticClass:"display-settings__ranges",attrs:{"id":"metric-ranges"}},_vm._l((_vm.metrics_range),function(range,i){return _c('kw-input-field',{key:i,staticClass:"text-input",attrs:{"maxlength":"3","placeholder":_vm.$t('metrics-range-placeholder'),"type":"text","name":"metricsRanges","error-data":_vm.rangeInputError(i)},on:{"blur":function($event){return _vm.debouncedHandler(_vm.setRange)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.debouncedHandler(_vm.setRange)}},model:{value:(_vm.metrics_range[i]),callback:function ($$v) {_vm.$set(_vm.metrics_range, i, $$v)},expression:"metrics_range[i]"}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }