<template>
    <div class="fix-finish-wrapper">
        <div v-if="loading" class="placeholder placeholder_animate">
            <div class="placeholder__item placeholder__item_import_validate">
                <div
                    v-for="i in 7"
                    :key="i"
                    class="placeholder__import_validate placeholder__import_validate"
                >
                    <div v-for="j in 20" :key="j" />
                </div>
            </div>
        </div>
        <template v-else>
            <div class="step3-description">
                <span
                    class="unmatched-keywords"
                    v-text="$t('unmatched-keywords')"
                />
                <span v-text="$t('following-correctly-keywords')" />
            </div>
            <new-table-with-header
                :columnDefs="columns"
                :defaultColDef="defaultColumns"
                :gridOptions="gridOptions"
                :rowData="rowsData"
                :style="'width: 100%; height: 373px;font-size: 12px;'"
                :columnTypes="columnTypes"
                :totalRowsCount="validRowsCount"
                :fileName="fileName"
                @onCellValueChanged="onCellValueChanged"
            />
            <div class="btn-group">
                <kw-button
                    button-type="primary"
                    :loading="importLoading"
                    :disabled="importLoading"
                    :button-text="$t('import')"
                    :data-cy="'fix-finish-import-btn'"
                    @click="importClickHandler"
                />
                <kw-button
                    button-type="secondary"
                    :disabled="importLoading"
                    :button-text="$t('cancel')"
                    :data-cy="'fix-finish-cancel-btn'"
                    @click="cancelClickHandler"
                />
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Events, EventBus } from '@/events';
import { IMPORT_DATA_STEPS } from '@/constants';
import NewTableWithHeader from './NewTableWithHeader';
import KeywordFieldRenderer from './custom-renderers/KeywordFieldRenderer';
import ConfirmImportingKeywords from 'components/modals/ConfirmImportingKeywords';

export default {
    name: 'ValidateRows',
    props: {
        activityLogId: {
            type: Number,
            required: true,
        },
        fileName: {
            type: String,
            required: true,
        },
        totalRowsCount: {
            type: Number,
            required: true,
        },
    },
    components: { NewTableWithHeader, KeywordFieldRenderer },
    data() {
        return {
            loading: true,
            accepting: false,
            updatingRows: [],
            validRowsCount: null,
            gridApi: null,
            gridColumnApi: null,
            defaultColumns: {
                resizable: true,
                singleClickEdit: true,
                type: 'editableColumn',
                cellRenderer: 'KeywordFieldRenderer',
            },
            gridOptions: {
                onGridReady: params => {
                    this.gridApi = params.api;
                    this.gridColumnApi = params.columnApi;
                    this.scrollToNextError();
                },
            },
            columns: [],
            rowsData: [],
            notValidData: {},
            columnTypes: {
                editableColumn: {
                    editable: this.getCellError,
                    cellStyle: this.getCellStyle,
                },
            },
        };
    },
    async created() {
        try {
            this.notValidData = await this.fetchInvalidRows(this.activityLogId);
            this.generateRowsData();
            this.loading = false;
        } catch (error) {
            this.errored = true;
        }
    },
    mounted() {
        EventBus.emit(Events.IMPORT_FINISH_VIEW_EVENT, {
            num_keywords: this.totalRowsCount,
        });
    },
    computed: {
        importLoading() {
            return !!(this.updatingRows.length || this.accepting);
        },
    },
    methods: {
        ...mapActions([
            'fetchInvalidRows',
            'updateInvalidRow',
            'acceptImporting',
        ]),
        getCellError(params) {
            return this.rowsData[params.node.rowIndex]?.errors[
                params.colDef.field
            ];
        },
        isCellEdited(params) {
            return this.rowsData[params.node.rowIndex].changes.includes(
                params.colDef.field,
            );
        },
        getCellStyle(params) {
            if (this.getCellError(params) && !this.isCellEdited(params)) {
                return {
                    color: '#CB1A02',
                    cursor: 'pointer',
                };
            }

            return {};
        },
        generateRowsData() {
            this.validRowsCount =
                this.totalRowsCount - Object.keys(this.notValidData).length;

            this.rowsData = [];

            for (let rowIndex in this.notValidData) {
                let row = _.clone(this.notValidData[rowIndex]);
                let rowData = row.data;

                if (!this.columns.length) {
                    for (let key in rowData) {
                        this.columns.push({
                            field: key,
                        });
                    }
                }

                rowData.rowIndex = rowIndex;
                rowData.errors = row.errors;
                rowData.updating = this.updatingRows.includes(rowIndex);
                rowData.changes = [];
                this.rowsData.push(rowData);
            }
        },
        importClickHandler() {
            if (Object.keys(this.notValidData).length === 0) {
                this.finishImport();
            } else {
                this.$modal.show(
                    ConfirmImportingKeywords,
                    {
                        validRowsCount: this.validRowsCount,
                        callback: this.finishImport,
                    },
                    {
                        classes: 'v--modal center-modal-popup',
                        clickToClose:
                            window.screen.width >
                            window.MOBILE_WIDTH_RESOLUTION_PHONE,
                        height: 'auto',
                        name: 'ConfirmImportingKeywords',
                        pivotX: 0,
                        width: 345,
                    },
                );
            }
        },
        cancelClickHandler() {
            this.$emit('step-down', {
                step: IMPORT_DATA_STEPS.STEP_1,
                cancellation: true,
            });
        },
        async onCellValueChanged(event) {
            event.data.changes.push(event.colDef.field);
            let rowIndex = event.data.rowIndex;

            var rowNodes = this.gridApi.getDisplayedRowAtIndex(event.rowIndex);
            this.gridApi.redrawRows({ rowNodes: [rowNodes] });

            let hasErrorInRow = false;

            for (let err in event.data.errors) {
                if (!event.data.changes.includes(err)) {
                    hasErrorInRow = true;
                }
            }

            if (!hasErrorInRow) {
                try {
                    event.data.updating = true;
                    this.updatingRows.push(rowIndex);
                    this.gridApi.redrawRows();

                    const row = await this.updateInvalidRow({
                        id: this.activityLogId,
                        rowIndex: rowIndex,
                        tableData: event.data,
                    });

                    let index = this.updatingRows.indexOf(rowIndex);

                    if (index !== -1) {
                        this.updatingRows.splice(index, 1);
                    }

                    event.data.updating = false;
                    event.data.changes = [];

                    if (Object.keys(row.data).length) {
                        this.notValidData[rowIndex] = row.data[rowIndex];
                    } else if (row !== false) {
                        delete this.notValidData[rowIndex];
                    }

                    this.generateRowsData();
                } catch (error) {
                    console.log(error);
                }
            }

            this.scrollToNextError();
        },
        async finishImport(skipInvalidRows = false) {
            try {
                this.accepting = true;
                let notValidRowsCount = Object.keys(this.notValidData).length;

                if (notValidRowsCount === 0 || skipInvalidRows) {
                    const res = await this.acceptImporting({
                        id: this.activityLogId,
                        skipInvalidRows: skipInvalidRows,
                    });

                    if (notValidRowsCount) {
                        EventBus.emit(Events.IMPORT_ERROR_EVENT, {
                            num_keywords: notValidRowsCount,
                            error_message:
                            'Import process start and skip not valid rows',
                        });
                    }

                    if (res !== false) {
                        this.$router.push({ name: 'Overview' });
                    }
                }
            } catch (error) {
                return false;
            } finally {
                this.accepting = false;
            }
        },
        scrollToNextError() {
            for (let rowIndex in this.rowsData) {
                let rowData = this.rowsData[rowIndex];
                if (!rowData.updating) {
                    for (let key in rowData.errors) {
                        if (!rowData.changes.includes(key)) {
                            this.scrollToCell(rowIndex, key);
                            return;
                        }
                    }
                }
            }
        },
        scrollToCell(rowIndex, colKey) {
            const rowNode = this.gridApi.getRowNode(rowIndex);

            if (rowNode) {
                const colDef = this.gridColumnApi.getColumn(colKey);

                if (colDef) {
                    const colId = colDef.colId;
                    this.gridApi.ensureColumnVisible(colId, 'middle');
                    this.gridApi.ensureIndexVisible(rowNode.rowIndex, 'top');
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.fix-finish-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 9px;
    font-size: 13px;
    font-family: 'Roboto';

    .placeholder {
        margin-top: 30px;
    }

    .step3-description {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 35px;
        .unmatched-keywords {
            font-weight: 500;
        }
    }

    ::v-deep .ag-theme-alpine {
        --ag-font-size: 12px;
        --ag-header-foreground-color: #0074d9;
        --ag-cell-horizontal-border: 2px solid #edeef0;
        --ag-background-color: #fcfcfc;
        --ag-header-background-color: #fcfcfc;
    }

    .btn-group {
        display: flex;
        gap: 2%;
    }
}
</style>
