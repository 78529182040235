<template>
    <div class="suggestions-placeholder">
        <div class="table" style="pointer-events: none;">
            <keyword-suggestions-table
                :loading="false"
                :suggestions="mockData"
                preventClick
            />
        </div>
        <div class="info">
            <p>{{$t('connect-your-google-search-console-account')}}</p>
            <kw-button
                @click="$emit('onConnectAccount')"
                :button-text="$t('connect-gsc-account')"
            />
        </div>
    </div>
</template>

<script>
    import KeywordSuggestionsTable from './KeywordSuggestionsTable.vue'

    export default {
        name: "SuggestionsPlaceholder",
        components: {
            'keyword-suggestions-table': KeywordSuggestionsTable
        },
        data() {
            return {
               mockData: [
                    {
                        "kw": "keyword academy",
                        "ctr": 0.026,
                        "clicks": 1,
                        "position": 1.051,
                        "impressions": 39
                    },
                    {
                        "kw": "academy seo",
                        "ctr": 0,
                        "clicks": 0,
                        "position": 97.556,
                        "impressions": 9
                    },
                    {
                        "kw": "keyword #1",
                        "ctr": 0,
                        "clicks": 0,
                        "position": 83.5,
                        "impressions": 2
                    },
                    {
                        "kw": "keyword 1",
                        "ctr": 0,
                        "clicks": 0,
                        "position": 93,
                        "impressions": 4
                    },
                    {
                        "kw": "keyword 11",
                        "ctr": 0,
                        "clicks": 0,
                        "position": 91,
                        "impressions": 1
                    },
                    {
                        "kw": "keyword 13",
                        "ctr": 0,
                        "clicks": 0,
                        "position": 37.078,
                        "impressions": 64
                    },
               ]
            }
        },
      
       
    }
</script>

<style lang="scss" scoped>
    .suggestions-placeholder {
        width: 100%;
        position: relative;
        .table {
            width: 100%;
        }
        .info {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            backdrop-filter: blur(6px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            p {
                max-width: 540px;
                text-align: center;
                font-size: 16px;
                margin: 0;
            }
            .kw-button {
                height: 40px;
            }
        }
    }
</style>