<template>
    <select v-model="value" tabindex="0" @change="change" :ref="'select'">
        <option v-for="option in options" :value="option" :key="option">
            {{ getOption(option) }}
        </option>
    </select>
</template>
<script>
export default {
    name: 'DropdownEditor',
    data() {
        return {
            value: null,
            options: [],
        };
    },
    methods: {
        getValue() {
            return this.value;
        },
        getOption(option) {
            return option ?? this.$t('select-field');
        },
        change() {
            this.params.api.stopEditing();
        },
    },
    created() {
        this.options = this.params.values;
        this.value = this.params.value;
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.select.dispatchEvent(new Event("click"));
        });
    },
};
</script>

<style lang="scss" scoped>
select {
    width: 100%;
    height: 100%;
}
</style>
