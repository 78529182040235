<template>
  <div
      v-click-outside="onClose"
      :class="`date-ranges-dropdown dropdown_container${exact ? ' exact-date' : ''}`"
  >
    <div
        class="button-wrapper" 
        @click="onToggle"
    >
        <kw-button
            v-if="!hideButton"
            button-type="secondary"
            :button-text="getLabel(value)"
            :class="`date-picker-btn${isOpen ? ' active' : ''}`"
        />
        <slot />
    </div>
      <custom-dropdown-body
          ref="dateRangePicker"
          position="bottom-right"
          alignTo="right"
          adjust
      >
          <div class="v-context">
              <date-range-picker-body
                :defaultKey="defaultKey"
                :defaultValue="value"
                @close="onClose"
                @apply="onApply"
                :exact="exact"
                :ignoreDates="ignoreDates"
              />
          </div>
      </custom-dropdown-body>
  </div>
</template>

<script>
import DateRangePikerBody from './DateRangePikerBody.vue';
import { datePickerList } from './datePickerList'

export default {
  name: 'DateRangePicker',
  components: {
    'date-range-picker-body': DateRangePikerBody
  },
  props: {
    value: {
      required: false,
    },
    exact: {
      type: Boolean,
      default: false,
      required: false
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    defaultKey: {
      type: String,
      required: false,
      default: null
    },
    ignoreDates: {
      type: Array,
      required: false
    }
  },
  data(){
    return {
      isOpen: false
    }
  },
  methods: {
      onToggle() {
        this.$refs.dateRangePicker.toggleDropdownOpen();
        this.isOpen = this.$refs.dateRangePicker.getIsDropdownOpened;
      },
      onClose() {
        if (this.$refs.dateRangePicker?.getIsDropdownOpened) {
          this.$refs.dateRangePicker.closeDropdown();
          this.isOpen = false;
        }
      },
      onApply(value, key, isCustom) {
        this.$emit('onApply', value, key, isCustom);
        this.onClose();
      },
      getLabel(value) {
        if(!value && this.defaultKey){
          return datePickerList[this.defaultKey].label;
        }
        // if(value && typeof value==='object' && value.length > 1) {
        //   return value[1] ? 'Custom' : datePickerList.last_month.label
        // }
        return value ? 'Custom' : datePickerList.last_month.label
      }
  },
}
</script>

<style lang="scss" scoped>
.date-ranges-dropdown {
  // flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-self: center;
  // max-width: calc(100% - 198px);

  .v-context {
    padding: 0;
  }

  &::after {
      display: none;
  }
  /deep/ .custom_dropdown_body {
    box-shadow: none !important;
    margin-top: 4px !important;
    z-index: 1000;
  }

  &.exact-date {
    max-width: 134px;
    // width: 100px;
    // min-width: calc(100% - 290px);
    flex: unset;
  }
}

.button-wrapper {
  max-width: 100%;
}

button.kw-button.kw-btn-secondary.date-picker-btn {
  max-width: 100%;
  height: 22px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  color: kw-color(kw-grey, 1);

  /deep/ span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.active {
    border: 1px solid kw-color(kw-blue, 1);
    color: kw-color(kw-blue, 1);
    background-color: kw-color(kw-light-blue, 11);
  }
}

.v-context {
  position: relative;
}
</style>
