export default (i18n, toastr) => {
	return res => {
		if (!res) return;
		
		const { config } = res;

		if (config.url.includes('/deleted-keywords')) {
			if (config.method === 'post') {
				toastr.s(i18n.t('keyword-suggestions-deleted'));
			} else if (config.method === 'put') {
				toastr.s(i18n.t('keyword-suggestions-restored'));
			}
		}

		if (config.url.includes('/filter')) {
			if (config.method === 'post') {
				toastr.s(i18n.t('filter-success-added'));
			}
		}

		return res;
	};
};
