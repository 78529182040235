<template>
    <div class="location-wrap pt-12">
        <label
            :for="`${autocompleteType}-${locations.length - 1}`"
            class="location-label"
        >
            <span v-text="$t('locations')" />
            <span
                class="kw kw-info"
                @mouseover="tooltip({text: $t('location-tooltip'), event: $event})"
            />
            <span
                v-if="keywordType !== 'map'"
                class="required-hint"
                v-text="$t('optional')"
            />
            <span
                v-else
                class="label-required"
            >
                {{ $t('required-symbol') }}
            </span>
        </label>
        <div
            v-for="(location, i) in locations"
            :key="i"
            class="input-wrapper"
        >
            <custom-google-autocomplete
                :id="`${autocompleteType}-${i}`"
                :ref="`${refName}-${i}`"
                :class="textInputClasses(i)"
                data-cy="add-keyword-web-location"
                :types="geotype"
                :placeholder="$t('zip-or-postal-code')"
                @placechanged="(addressData, placeResultData, id) => setLocationData(id, placeResultData, i)"
                @inputChange="object => resetLocationData(object, i)"
            />
            <span
                v-show="locations.length > 1"
                data-cy="kw-delete-location"
                class="kw kw-trash-2"
                @click="removeLocation(i)"
            />
        </div>
        <kw-button
            :button-text="$t('add-location')"
            data-cy="kw-add-location"
            button-type="text"
            prepend-icon-class="kw kw-plus"
            @click="addLocation"
        />
    </div>
</template>

<script>
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    import CustomGoogleAutocomplete from '@/components/ui-elements/CustomGoogleAutocomplete';
    export default {
        name: 'AddLocations',
        components: {
            'custom-google-autocomplete': CustomGoogleAutocomplete,
        },
        mixins: [ToolTipRenderMixin],
        props: {
            autocompleteType: {
                type: String,
                required: true,
            },
            locations: {
                type: Array,
                required: true,
            },
            addLocation: {
                type: Function,
                required: true,
            },
            keywordType: {
                type: String,
                required: false,
                default: '',
            },
            refName: {
                type: String,
                required: true,
            },
            removeLocation: {
                type: Function,
                required: true,
            },
            setLocation: {
                type: Function,
                required: true,
            },
            enableValidation: {
                type: Boolean,
                required: false,
                default: false,
            },
            invalid: {
                type: Boolean,
                required: false,
                default: false,
            },
            setLocationErrors: {
                type: Function,
                required: false,
                default: () => {},
            },
        },
        data () {
            return {
                geotype: '',
            };
        },
        mounted () {
            this.$nextTick(() => {
                this.locations.forEach((l, i) => {
                    if (this.$refs[`${this.refName}-${i}`]) {
                        this.$refs[`${this.refName}-${i}`][0].autocompleteText = l;
                    }
                });
            });
        },
        methods: {
            setLocationData (id, {formatted_address}, index) {
                // document.getElementById(id).value = formatted_address;
                this.setLocation(index, document.getElementById(id).value);
                this.setLocationErrors();
                this.touched = true;
            },
            resetLocationData (object, index) {
                if (!object.newVal.length) {
                    this.setLocation(index, '');
                }

                this.touched = true;
            },
            textInputClasses (index) {
                return {
                    'text-input': true,
                    'text-input_invalid': this.enableValidation && this.invalid && !this.locations[index].length,
                };
            },
        },
    }
</script>

<style lang="scss" scoped>
button {
    &.kw-btn-primary {
        .span {
            &.kw-plus {
                font-size: 10px;
            }
        }

        .button-loader {
            position: relative !important;
            background-color: transparent !important;
            border-color: transparent transparent transparent kw-color(kw-white, 1) !important;
            left: 0 !important;
            height: 18px !important;
            min-width: 18px !important;
            width: 18px !important;
        }
    }
}
.location-wrap {
    border-top: 1px solid #DEE5EC;
    padding: 12px 0 8px;
    .kw-info {
        margin: 0 4px;
    }
}
.required-hint {
    font-size: 13px;
    color: #828F9C;
    cursor: default;
    text-transform: capitalize;
}
.input-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: 8px;
    width: 100%;
    .text-input {
        flex: 1 1 auto;
    }
}
.kw-trash-2 {
    color: kw-color(kw-grey, 1);
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    padding: 0 0 0 8px;
}
</style>