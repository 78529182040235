export default (i18n, toastr, apiCodes) => {
    return error => {
        if (!error) return Promise.reject(i18n.t('unexpected-error'));

        const { config, response } = error;

        if (!config || !response) {
            return Promise.reject(error);
        }

        const { errors } = response.data;

        if (config.method === 'post' && config.url.includes('/update_card')) {
            toastr.e(i18n.t('update-card-error'));
        }

        if (config.method === 'put' && config.url.includes('/password')) {
            response.data.errors.some(el => el.code === apiCodes.WRONG_USER_PASSWORD)
                ? toastr.e(i18n.t('invalid-current-password'))
                : toastr.e(i18n.t('settings-update-error'));
        }

        if (config.method === 'put' &&
            !config.url.includes('/password') &&
            config.url.includes('/self')
        ) {
            toastr.e(i18n.t('settings-update-error'));
        }

        if ((config.method === 'patch' && config.url.includes('/myself') && !config.params.fromSettings) ||
            (config.method === 'get' && config.url.includes('/verify')) ||
            (config.method === 'post' && config.url.includes('/request-to-upgrade-plan')) ||
            (config.method === 'post' && config.url.includes('/reset-on-demand-refresh-limit'))
        ) {
            errors?.forEach(element => {
                toastr.e(element.detail);
            });
        }

        return Promise.reject(error);
    };
};