<template>
    <div v-if="!socialLoading" data-cy="login-form" class="login-form">
        <social-sign-in
            :open-social-sign-in="openSocialSignIn"
            :title="$t('log-in-with')"
            :social-media-list="INTEGRATED_SOCIAL_MEDIA_NAMES"
        />
        <divider :text="$t('or')" />
        <form :novalidate="true" @submit.prevent="validateForm">
            <div class="form-group">
                <kw-input-field
                    id="login"
                    name="email"
                    type="email"
                    data-cy="login"
                    class="text-input"
                    :label="$t('login-form.email')"
                    placeholder="email@email.com"
                    v-model.trim="username"
                    v-validate="'required|email'"
                    :error-data="emailFieldError"
                />
            </div>
            <div class="form-group password-wrapper">
                <kw-input-field
                    id="password"
                    name="password"
                    data-cy="password"
                    class="text-input"
                    :type="fieldType"
                    :label="$t('login-form.password')"
                    placeholder="••••••••"
                    v-model.trim="password"
                />
                <div
                    @click="togglePasswordVisibility"
                    class="password-visibility"
                >
                    <div>
                        <span :class="`kw ${eyeIcon}`" />
                    </div>
                </div>
            </div>
            <div class="errors">
                <span v-if="firstError">{{ firstError }}</span>
            </div>
            <kw-button
                class="login-submit"
                type="submit"
                data-cy="login-submit"
                :loading="loading"
                :button-disabled="isDisabled"
                button-type="action"
                :button-text="$t('login')"
            />
            <router-link
                :to="{ name: 'forgotPassword' }"
                class="forgot-password"
            >
                {{ $t('login-form.forgot-password') }}
            </router-link>
        </form>
    </div>
    <loader v-else color="0074D9" size="100" width="4" />
</template>

<script>
import { mapActions } from 'vuex';
import { cookieGetters } from '@/helpers/cookieManager';
import SocialSignInMixin from '@/mixins/SocialSignInMixin';

export default {
    name: 'Login',
    mixins: [SocialSignInMixin],
    data() {
        return {
            username: '',
            password: '',
            errorMsg: '',
            loading: false,
            showPassword: false,
            validated: false,
        };
    },
    computed: {
        emailFieldError() {
            return {
                error: this.validated && this.errors.collect('email').length,
            };
        },
        isDisabled() {
            return !!(
                (this.validated && this.errors.all().length) ||
                !this.password ||
                !this.username
            );
        },
        firstError() {
            return this.validated && this.errors.all().length
                ? this.errors.items[0].msg
                : this.errorMsg || false;
        },
        eyeIcon() {
            return this.showPassword ? 'kw-eye' : 'kw-eye-off';
        },
        fieldType() {
            return this.showPassword ? 'text' : 'password';
        },
    },
    methods: {
        ...mapActions(['logIn', 'reset']),
        async validateForm() {
            this.validated = true;
            await this.$validator.validateAll();

            if (this.errors.all().length) {
                return;
            }

            await this.signIn();
        },
        async signIn() {
            this.errorMsg = '';
            this.loading = true;

            if (!this.errors.all().length) {
                this.reset();
                let error = await this.logIn({
                    username: this.username,
                    password: this.password,
                });

                this.errorMsg = this.getErrorMessage(error?.response?.data?.errors?.[0]);

                this.$nextTick(() => this.$validator.reset());
            }

            this.loading = false;
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        getErrorMessage(error) {
            switch (error?.code) {
                case '2017':
                    return this.$t('auth-errors.user-disabled');
                case '401':
                    return error.message;
                default:
                    return this.$t('auth-errors.login');
            }
        }
    },
};
</script>

<style>
.login-form {
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
}
</style>
<style lang="scss" scoped>
.login-form {
    /deep/ .divider {
        margin-bottom: 8px;
    }

    form {
        display: flex;
        justify-content: center;
        flex-direction: column;

        /deep/ .form-group {
            margin-bottom: 8px;

            &:nth-child(2) {
                margin-bottom: 6px;
            }
        }

        /deep/ .kw__input {
            height: 50px;
        }

        /deep/ .field__label {
            color: var(--black, #15394f);
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 8px;
        }

        .password-wrapper {
            position: relative;

            .password-visibility {
                cursor: pointer;
                width: 30px;
                height: 30px;
                position: absolute;
                top: 42px;
                right: 0;
            }

            .password-visibility span {
                width: 25px;
                height: 25px;
                font-size: 20px;
                color: #0074d9;
            }
        }

        .errors {
            height: 14px;
            color: #f04438;
            font-family: Aeonik;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .login-submit {
            margin-top: 32px;
            margin-bottom: 24px;
        }

        /deep/ .kw-button {
            height: 50px;
            width: 100%;
            color: #fff;
            text-align: center;
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
        }

        .forgot-password {
            align-self: stretch;
            text-decoration: none;
            color: var(--black, #15394f);
            text-align: center;
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    @media (max-width: 980px) {
        padding-bottom: 0;
    }
}
</style>
