<template functional>
    <div class="social-sign-in">
        <div v-if="props.title">{{ props.title }}</div>
        <div class="social-sign-in-icons">
            <div
                v-for="socialMedia in props.socialMediaList"
                :key="socialMedia"
                :class="`kw-social kw-social-${socialMedia}`"
                @click="() => props.openSocialSignIn(socialMedia)"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SocialSignIn',
    props: {
        title: {
            type: String,
            required: false,
            default: () => null,
        },
        socialMediaList: {
            type: Array,
            required: true,
        },
        openSocialSignIn: {
            type: Function,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.social-sign-in {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    color: #828f9c;
    text-align: center;
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    .social-sign-in-icons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        // width: 232px;

        .kw-social {
            cursor: pointer;
        }
    }
}
</style>
