<template>
    <div class="current-filters-wrapper">
        <div class="filters">
            <span class="title">{{ $t('filters') }}:</span>
            <slot />
        </div>
        <div class="button-wrapper">
            <kw-button
                button-type="text"
                button-text="Save filters"
                prepend-icon-class="kw kw-plus"
                @click="$emit('onSave')"
            />
            <kw-button
                button-type="text"
                button-text="Reset filters"
                prepend-icon-class="kw kw-rotate-ccw"
                @click="$emit('onReset')"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "CurrentFilters",
    }
</script>

<style lang="scss" scoped>
    .current-filters-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .filters {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            align-items: center;
            min-height: 32px;
            margin: 10px 0;
            & > span.title {
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.41px;
                color: kw-color(kw-grey, 1);
                text-transform: capitalize;
            }
        }
        .button-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            button {
                font-family: 'Inter', sans-serif;
            }
        }
    }
</style>