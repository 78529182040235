var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showLoader)?_c('rotating-update-icon'):_c('div',{staticClass:"url-component-wrapper"},[(_vm.notFound)?_c('span',{staticClass:"kw kw-not-found url-not-found",attrs:{"data-important-tooltip":_vm.urlTooltip(_vm.$t('not-found'))}}):[(
                _vm.params.data.grank && _vm.lockedURL
            )?_c('span',{staticClass:"kw kw-lock",attrs:{"data-important-tooltip":_vm.$t('https-detected')}}):_vm._e(),_vm._v(" "),(_vm.params.data.url_tracking_method==='exact')?_c('span',{staticClass:"kw kw-crosshair target_exact_icon",on:{"mouseover":function($event){_vm.tooltip({
                    text: _vm.$t('tracking-method', { type: _vm.type, ignoreSub: _vm.ignoreSub }),
                    event: $event,
                })}}}):_vm._e(),_vm._v(" "),(_vm.params.data.url_tracking_method==='broad')?_c('span',{staticClass:"icon-wrapper",on:{"mouseover":function($event){_vm.tooltip({
                    text: _vm.$t('tracking-method', { type: _vm.type, ignoreSub: _vm.ignoreSub }),
                    event: $event,
                })}}},[_c('span',{staticClass:"kw kw-maximize-2"}),_vm._v(" "),_c('span',{staticClass:"kw kw-maximize-2"})]):_vm._e()],_vm._v(" "),(_vm.notFound)?_c('div',[_c('span',{staticClass:"not-found",domProps:{"innerHTML":_vm._s(_vm.$t('not-found'))}})]):_c('div',{staticClass:"table-cell__content table-cell__content_url"},[_c('a',{staticClass:"table-cell__url-link",attrs:{"data-important-tooltip":_vm.urlTooltip(_vm.params.data.fullUrl),"href":_vm.params.data.fullUrl,"target":"_blank"},domProps:{"innerHTML":_vm._s(_vm.clearUrl(_vm.params.data.fullUrl))}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }