<template>
    <div class="date-range-picker-wrapper">
      <div class="date-list">
        <div
          v-for="(item,key) in dateList"
          :key="item.label"
          @click="pickDate(key)"
          :class="{'date-item': true, 'active': dateKey === key}"
        >
          {{ item.label }}
        </div>
      </div>
 
      <div class="date-range-picker">
        <div :class="`calendar-wrapper${exact ? ' exact-date' : ''}`">
            <date-picker
              key="1"
              v-model="dateValue"
              valueType="format"
              type="date"
              open
              :range="!exact"
              :lang="lang"
              append-to-body
              inline
              :disabled-date="disabledAfterToday"
              :get-year-panel="getYearPanel"
              @change="onChange"
              :partial-update="true"
            ></date-picker>
            <date-picker
              v-if="exact"
              key="2"
              v-model="exactDate"
              valueType="format"
              type="date"
              open
              :lang="lang"
              append-to-body
              inline
              :disabled-date="disabledExceptThisMonth"
              @change="onChangeCurrentMonth"
            ></date-picker>
        </div>
        <div class="footer">
          <div class="inputs-wrapper">
            <input
              :value="startDate"
              class="form-control custom-input date-input"
              :placeholder="$t('start-date')"
              type="text"
              readonly
              disabled
            />

            <template  v-if="!exact">
              <span class="dash"></span>

              <input
                :value="endtDate"
                class="form-control custom-input date-input"
                :placeholder="$t('end-date')"
                type="text"
                readonly
                disabled
              />
            </template>
          </div>
  
          <div class="buttons-wrapper">
            <kw-button
                :button-text="$t('close')"
                data-cy="kw-add-location"
                button-type="secondary"
                @click="onClose"
            />
  
            <kw-button
              :button-text="$t('apply')"
              data-cy="kw-add-location"
              button-type="primary"
              @click="onApply"
              :disabled="isApplyDisabled"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import DatePicker from "vue2-datepicker";
  import {switchDateFormat} from '@/helpers/format-date-service';
  import "vue2-datepicker/index.css";
  import { datePickerList, exactDatePickerList } from './datePickerList'
  const { updateCalendars } = DatePicker.CalendarRange.methods

  DatePicker.CalendarRange.methods.updateCalendars = function(calendars, adjustIndex = 0) {
    updateCalendars.call(this, calendars, adjustIndex)
  }
  
  export default {
    components: { DatePicker },
    name: "DateRangePickerBody",
    props: {
      exact: {
        type: Boolean,
        default: false,
        required: false
      },
      defaultValue: {
        required: false
      },
      defaultKey: {
        type: String,
        required: false,
        default: null
      },
      ignoreDates: {
        type: Array,
        required: false
      }
    },
    data() {
      return {
        dateKey: this.defaultKey,
        dateValue: null,
        exactDate: null,
        dateList: this.exact ? exactDatePickerList : datePickerList,
        isCustom: false,
        lang: {
          formatLocale: {
            firstDayOfWeek: 1
          }
        }
      };
    },
    mounted() {
      if (this.ignoreDates?.length) {
        const clonedDateList = _.cloneDeep(this.dateList);
        this.ignoreDates.forEach(key => delete clonedDateList[key]);
        this.dateList = clonedDateList;
      }
      if (this.defaultKey && !this.defaultValue) {
        this.dateValue = this.dateList[this.defaultKey].value;
      } else if (!this.exact && this.defaultValue?.length > 1) {
        this.dateValue = this.defaultValue;
      } else if (this.defaultValue) {
        new Date(this.defaultValue).getMonth() < new Date().getMonth()
          ? this.dateValue = this.defaultValue
          : this.exactDate = this.defaultValue;
      }
    },
    computed: {
      startDate() {
        if (this.exact) {
          const date = this.dateValue ?? this.exactDate;
          return date ? switchDateFormat(new Date(date), 'note') : '';
        }
        return this.dateValue?.[0] ? switchDateFormat(new Date(this.dateValue[0]), 'note') : '';
      },
      endtDate() {
        return this.dateValue?.[1] ? switchDateFormat(new Date(this.dateValue[1]), 'note') : '';
      },
      isApplyDisabled(){
        return !this.exact && !this.dateValue?.[1];
      }
    },
    methods: {
      disabledAfterToday(date) {
        return date > new Date();
      },
      disabledExceptThisMonth(date) {
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth();
        return date > new Date() || (date.getFullYear() !== currentYear || date.getMonth() !== currentMonth);
      },
      onClose() {
        this.$emit('close');
      },
      onApply() {
        const value = this.exact ? (this.dateValue ?? this.exactDate) : this.dateValue;
        this.$emit('apply', value, this.dateKey, this.isCustom);
      },
      getYearPanel() {
        const currentYear = new Date().getFullYear();
        const yearPairs = [];
  
        for (let year = 2010; year <= currentYear; year += 2) {
          const pair = [year, year + 1];
          yearPairs.push(pair);
        }
  
        return yearPairs;
      },
      pickDate(key) {
        if (this.exact) {
          this.exactDate = null;
        }
        this.dateKey = key;
        this.dateValue = this.dateList[key].value;
        this.isCustom = false;
      },
      onChange(val) {
        if (val) {
          this.exactDate = null;
          this.isCustom = true;
        }
      },
      onChangeCurrentMonth(val) {
        if (val) {
          this.dateValue = null;
        }
      },
    },
    
  };
  </script>
  
<style lang="scss" scoped>
    .date-range-picker-wrapper {
      display: flex;
      background-color: kw-color(kw-white, 1);
      .date-list {
        padding: 24px 16px;
        .date-item {
            padding: 9px 12px;
            height: 36px;
            cursor: pointer;
            white-space: nowrap;
            font-family: Roboto;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;

            &:hover,
            &.active{
                background-color: kw-color(kw-white, 7);
            }
        }
      }
      .calendar-wrapper {
        display: flex;
        &.exact-date {
          /deep/ .mx-datepicker:nth-child(2){
            .mx-datepicker-main {
              border-left: none;
            }
            .mx-calendar-header {
              pointer-events: none;
              .mx-btn-icon-left,
              .mx-btn-icon-right{
                display: none;
              }
            }
          }
        }
      }
    }
    .date-range-picker {
      .date-input {
        width: 140px;
        height: 40px;
        background-color: kw-color(kw-white, 1);
        cursor: initial;
      }
      .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        border-left: 1px solid kw-color(kw-light-grey, 1);
  
        .inputs-wrapper {
          display: flex;
          align-items: center;
          gap: 12px;
          .dash {
            display: inline-block;
            width: 6px;
            border-top: 1px solid kw-color(kw-black, 1);
          }
        }
  
        .buttons-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
    /deep/ .mx-datepicker-main {
      color: kw-color(kw-black, 1);
    }
    /deep/ .mx-calendar {
      width: 328px;
    }
    /deep/ .mx-calendar-header {
      font-family: Roboto;
      .mx-calendar-header-label {
        display: flex;
        justify-content: center;
        
        button {
            color: kw-color(kw-black, 1);
            font-family: Roboto;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
        }
      }
      .mx-btn-icon-double-left,
      .mx-btn-icon-double-right {
        display: none;
      }
      .mx-btn-text {
        .mx-icon-right,
        .mx-icon-left {
          color: kw-color(kw-black, 1);
        }
      }
    }
    /deep/ .mx-table-date {
      font-family: Roboto;
      border-collapse: separate;
      border-spacing: 0 3px;
      padding: 2px;
      td {
        width: 42px;
        height: 42px;
        font-size: 14px;
      }
      th {
        width: 40px;
        height: 40px;
        text-align: center;
        color: kw-color(kw-grey, 1);
        font-size: 13px;
      }
  
      .mx-date-row {
        .cell:first-child {
          border-top-left-radius: 50%;
          border-bottom-left-radius: 50%;
        }
        .cell:last-child {
          border-top-right-radius: 50%;
          border-bottom-right-radius: 50%;
        }
        .active {
          background-color: kw-color(kw-blue, 1);
          border-radius: 50%;
          position: relative;
          z-index: 1;
        }
      }
    }
    // .mx-calendar-content .cell.in-range,
    // .mx-calendar-content .cell.hover-in-range {
    //     &:not(.not-current-month) {
    //         background-color: #F0F5FA;
    //     }
    // }
    /deep/ .mx-calendar-content {
      height: 320px;
  
      .cell.in-range,
      .cell.hover-in-range {
        &:not(.not-current-month) {
          background-color: #f0f5fa;
        }
      }
      .mx-table-date {
        .cell {
          &.disabled {
            background-color: white;
          }
          &.hover-in-range,
          &.in-range {
            position: relative;
  
            &>div{
              position: relative;
              z-index: 2;
            }
  
            &:not(.not-current-month) {
              // &:not(:last-child):not(:first-child) {
              //     &::before {
              //         content: '';
              //         position: absolute;
              //         top: 0;
              //         left: -15px;
              //         width: 15px;
              //         height: 100%;
              //         background-color: #F0F5FA;
              //     }
              //     &::after {
              //         content: '';
              //         position: absolute;
              //         top: 0;
              //         right: -15px;
              //         width: 15px;
              //         height: 100%;
              //         background-color: #F0F5FA;
              //     }
              // }
              &:not(:last-child) {
                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: -15px;
                  width: 15px;
                  height: 100%;
                  background-color: #f0f5fa;
                }
              }
              &:not(:first-child) {
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: -15px;
                  width: 15px;
                  height: 100%;
                  background-color: #f0f5fa;
                }
              }
            }
          }
          &.hover-in-range {
            background-color: #f0f5fa;
          }
          &:hover {
            border-radius: 50%;
          }
        }
      }
    }
</style>
