<template>
    <div
        class="custom-modal custom-modal_confirm-import"
        data-cy="modal-confirm-import"
    >
        <div class="custom-modal__header">
            {{ $t('import-keywords') }}
            <closer @click="close" />
        </div>
        <div class="custom-modal__body">
            {{ $t('import-keywords-note', {count: validRowsCount}) }}
        </div>
        <div class="custom-modal__footer">
            <kw-button
                :button-text="$t('cancel')"
                button-type="text"
                data-cy="cancel-import-modal"
                @click="close"
            />
            <kw-button
                :button-text="$t('import-data')"
                button-type="primary"
                data-cy="import-button-in-confirm-import-modal"
                @click="confirm"
            />
        </div>

        <global-events @keyup.enter="confirm" />
    </div>
</template>

<script>
export default {
    name: 'ConfirmImportingKeywords',
    props: {
        validRowsCount: {
            type: Number,
            required: true,
        },
        callback: {
            type: Function,
            required: true,
        },
    },
    methods: {
        confirm() {
            this.callback(true);
            this.close();
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/modal';

.custom-modal_confirm-import {
    padding: 8px;

    .custom-modal__body,
    .custom-modal__header {
        padding: 8px;
        margin: 0;
    }

    .custom-modal__footer {
        display: flex;
        justify-content: space-evenly;
    }
}

</style>
