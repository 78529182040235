import {
    SET_GSC_USERNAME,
    SET_GSC_USERNAME_IS_FETCHING,
    SET_GSC_PROFILES,
    SET_GSC_PROFILES_IS_FETCHING,
    SET_GSC_SUGGESTIONS,
    SET_GSC_SUGGESTIONS_IS_FETCHING,
    SET_GSC_SUGGESTIONS_SORT,
    SET_GSC_SUGGESTIONS_DELETING,
    SET_GSC_DELETED_SUGGESTIONS,
    SET_GSC_DELETED_SUGGESTIONS_IS_FETCHING,
    SET_GSC_SUGGESTIONS_RESTORING,
    SET_GSC_KEYWORDS_FROM_SUGGESTIONS_CREATING,
    SET_GSC_PROFILE_CONNECTION,
    SET_GSC_PROFILE_CONNECTIONS,
    SET_GSC_PROFILE_CONNECTIONS_CREATING,
    SET_GSC_PROFILE_CONNECTIONS_UPDATING,
    SET_GSC_PROFILE_CONNECTIONS_DELETING,
    SET_GSC_PROFILE_CONNECTIONS_IS_FETCHING,
    SET_GSC_FILTERS,
    SET_GSC_RELATED_KEYWORD_DATA,
    SET_GSC_COMPETITORS_DATA,
    SET_GSC_RELATED_KEYWORD_SORT,
    SET_GSC_COMPETITORS_SORT,
    SET_SAVED_FILTER_LIST,
    SET_RELATED_KW_FILTERS,
    SET_COMPETITOR_KW_FILTERS,
    SET_CURRENT_SAVED_FILTER,
} from '../mutations';
import i18n from '@/i18n';
import apiFactory from '@/api';
import { GTM_EVENTS } from '@/constants';
import toastr from '@/helpers/init-toastr';
import { sortSuggestions } from '@/store/utils';
import { Events, EventBus } from '@/events';
import { matchesFilter, parseGSCFilters } from '../../helpers';

const gscApi = apiFactory.get('gsc');
const googleSearchConsole = {
    state: {
        GSCUsername: null,
        GSCUsernameIsFetching: false,

        GSCProfiles: [],
        GSCProfilesIsFetching: false,

        GSCSuggestions: [],
        GSCSuggestionsIsFetching: false,
        GSCSuggestionsSort: {
            field: 'clicks',
            direction: 'desc',
        },

        GSCSuggestionsDeleting: false,
        GSCDeletedSuggestions: [],
        GSCDeletedSuggestionsIsFetching: false,
        GSCSuggestionsRestoring: false,

        GSCKeywordsFromSuggestionsCreating: false,

        GSCProfileConnections: [],
        GSCProfileCurrentConnection: null,
        GSCProfileConnectionsCreating: false,
        GSCProfileConnectionsUpdating: false,
        GSCProfileConnectionsDeleting: false,
        GSCProfileConnectionsIsFetching: false,
        GSCfilters: null,
        relatedKWfilters: null,
        competitorKWfilters: null,
        GSCCompetitorsData: [],
        GSCRelatedKeywordData: [],
        GSCRelatedKeywordSort: {},
        GSCCompetitorsSort: {},
        savedFilterList: [],
        currentSavedFilter: {
            suggestions: null,
            related: null,
            competitors: null,
        },
    },
    mutations: {
        /** Google Search Console Username */
        [SET_GSC_USERNAME]: (state, payload) => state.GSCUsername = payload,
        [SET_GSC_USERNAME_IS_FETCHING]: (state, payload) => state.GSCUsernameIsFetching = payload,
        /** Google Search Console Profiles */
        [SET_GSC_PROFILES]: (state, payload) => state.GSCProfiles = payload,
        [SET_GSC_PROFILES_IS_FETCHING]: (state, payload) => state.GSCProfilesIsFetching = payload,
        /** Google Search Console Suggestions (suggested keywords) */
        [SET_GSC_SUGGESTIONS]: (state, payload) => state.GSCSuggestions = payload,
        [SET_GSC_SUGGESTIONS_IS_FETCHING]: (state, payload) => state.GSCSuggestionsIsFetching = payload,
        [SET_GSC_SUGGESTIONS_SORT]: (state, payload) => state.GSCSuggestionsSort = payload,
        /** Google Search Console Deleted Suggestions (Deleted suggested keywords) */
        [SET_GSC_SUGGESTIONS_DELETING]: (state, payload) => state.GSCSuggestionsDeleting = payload,
        [SET_GSC_DELETED_SUGGESTIONS]: (state, payload) => state.GSCDeletedSuggestions = payload,
        [SET_GSC_DELETED_SUGGESTIONS_IS_FETCHING]: (state, payload) => state.GSCDeletedSuggestionsIsFetching = payload,
        [SET_GSC_SUGGESTIONS_RESTORING]: (state, payload) => state.GSCSuggestionsRestoring = payload,
        /** Google Search Console Create Keywords From Suggestions */
        [SET_GSC_KEYWORDS_FROM_SUGGESTIONS_CREATING]: (state, payload) => state.GSCKeywordsFromSuggestionsCreating = payload,
        /** Google Search Console Connections (profile - project connections with rules) */
        [SET_GSC_PROFILE_CONNECTION]: (state, payload) => state.GSCProfileCurrentConnection = payload,
        [SET_GSC_PROFILE_CONNECTIONS]: (state, payload) => state.GSCProfileConnections = payload,
        [SET_GSC_PROFILE_CONNECTIONS_CREATING]: (state, payload) => state.GSCProfileConnectionsCreating = payload,
        [SET_GSC_PROFILE_CONNECTIONS_UPDATING]: (state, payload) => state.GSCProfileConnectionsUpdating = payload,
        [SET_GSC_PROFILE_CONNECTIONS_DELETING]: (state, payload) => state.GSCProfileConnectionsDeleting = payload,
        [SET_GSC_PROFILE_CONNECTIONS_DELETING]: (state, payload) => state.GSCProfileConnectionsDeleting = payload,
        [SET_GSC_PROFILE_CONNECTIONS_IS_FETCHING]: (state, payload) => state.GSCProfileConnectionsIsFetching = payload,
        [SET_GSC_FILTERS]: (state, payload) => state.GSCfilters = payload,
        [SET_RELATED_KW_FILTERS]: (state, payload) => state.relatedKWfilters = payload,
        [SET_COMPETITOR_KW_FILTERS]: (state, payload) => state.competitorKWfilters = payload,
        [SET_GSC_RELATED_KEYWORD_DATA]: (state, payload) => state.GSCRelatedKeywordData = payload,
        [SET_GSC_COMPETITORS_DATA]: (state, payload) => state.GSCCompetitorsData = payload,
        [SET_GSC_RELATED_KEYWORD_SORT]: (state, payload) => state.GSCRelatedKeywordSort = payload,
        [SET_GSC_COMPETITORS_SORT]: (state, payload) => state.GSCCompetitorsSort = payload,
        [SET_SAVED_FILTER_LIST]: (state, payload) => state.savedFilterList = payload,
        [SET_CURRENT_SAVED_FILTER]: (state, payload) => state.currentSavedFilter = payload,
    },
    getters: {
        /** Google Search Console Username */
        getGSCUsername: state => state.GSCUsername,
        getGSCUsernameIsFetching: state => state.GSCUsernameIsFetching,
        /** Google Search Console Profiles */
        getGSCProfiles: state => state.GSCProfiles,
        getGSCProfilesIsFetching: state => state.GSCProfilesIsFetching,
        /** Google Search Console Suggestions (suggested keywords) */
        getGSCSuggestions: state => state.GSCSuggestions,
        getGSCSuggestionsIsFetching: state => state.GSCSuggestionsIsFetching,
        getGSCSuggestionsSort: state => state.GSCSuggestionsSort,
        /** Google Search Console Deleted Suggestions (Deleted suggested keywords) */
        getGSCSuggestionsDeleting: state => state.GSCSuggestionsDeleting,
        getGSCDeletedSuggestions: state => state.GSCDeletedSuggestions,
        getGSCDeletedSuggestionsIsFetching: state => state.GSCDeletedSuggestionsIsFetching,
        getGSCSuggestionsRestoring: state => state.GSCSuggestionsRestoring,
        /** Google Search Console Create Keywords From Suggestions */
        getGSCKeywordsFromSuggestionsCreating: state => state.GSCKeywordsFromSuggestionsCreating,
        /** Google Search Console Connections (profile - project connections with rules) */
        getGSCProfileConnections: state => state.GSCProfileConnections,
        getGSCProfileCurrentConnection: state => state.GSCProfileCurrentConnection,
        getGSCProfileConnectionsCreating: state => state.GSCProfileConnectionsCreating,
        getGSCProfileConnectionsUpdating: state => state.GSCProfileConnectionsUpdating,
        getGSCProfileConnectionsDeleting: state => state.GSCProfileConnectionsDeleting,
        getGSCProfileConnectionsIsFetching: state => state.GSCProfileConnectionsIsFetching,
        getGSCFilters: state => state.GSCfilters,
        getRelatedKWfilters: state => state.relatedKWfilters,
        getCompetitorKWfilters: state => state.competitorKWfilters,
        getGSCCompetitorsData: state => {
            let result = state.GSCCompetitorsData;
            
            if (state.competitorKWfilters && state.GSCCompetitorsData) {
                result = state.GSCCompetitorsData.filter(item => {
                    const {
                        word_count,
                        keyword_term,
                        monthlySearches,
                        competition,
                        cpc,
                    } = state.competitorKWfilters;
                    const wordLength = item.keyword.split(' ').length;
                    if (
                        matchesFilter(word_count?.filter, wordLength, word_count?.value, word_count?.max) &&
                        matchesFilter(monthlySearches?.filter, item.monthlySearches, monthlySearches?.value, monthlySearches?.max) &&
                        matchesFilter(cpc?.filter, item.cpc, cpc?.value, cpc?.max) &&
                        (!keyword_term || item.keyword.includes(keyword_term.value)) &&
                        (!competition || item.competition.toLowerCase() === competition.value.toLowerCase())
                    ) {
                        return item;
                    }
                });
            }
            return sortSuggestions(result, state.GSCCompetitorsSort);
        },
        getGSCRelatedKeywordData: state => {
            let result = state.GSCRelatedKeywordData;
            if (state.relatedKWfilters && state.GSCRelatedKeywordData) {
                result = state.GSCRelatedKeywordData.filter(item => {
                    
                    const {
                        word_count,
                        keyword_term,
                        monthlySearches,
                        competition,
                        cpc,
                    } = state.relatedKWfilters;
                    const wordLength = item.keyword.split(' ').length;
                    
                    if (
                        matchesFilter(word_count?.filter, wordLength, word_count?.value, word_count?.max) &&
                        matchesFilter(monthlySearches?.filter, item.monthlySearches, monthlySearches?.value, monthlySearches?.max) &&
                        matchesFilter(cpc?.filter, item.cpc, cpc?.value, cpc?.max) &&
                        (!keyword_term || item.keyword.includes(keyword_term.value)) &&
                        (!competition || item.competition.toLowerCase() === competition.value.toLowerCase())
                    ) {
                        return item;
                    }
                });
            }
            return sortSuggestions(result, state.GSCRelatedKeywordSort);
        },
        getRelatedKeywordsSort: state => state.GSCRelatedKeywordSort,
        getCompetitorsSort: state => state.GSCCompetitorsSort,
        getSavedFilterList: state => state.savedFilterList,
        getCurrentSavedFilter: state => state.currentSavedFilter,
    },
    actions: {
        async fetchGSCAccount({ getters, commit }) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(
                    i18n.t('upgrade-for-google', { service: i18n.t('google-search-console') }),
                );
                return false;
            }

            try {
                commit(SET_GSC_USERNAME_IS_FETCHING, true);
                const res = await gscApi.fetchAccount();
                commit(SET_GSC_USERNAME, res?.data?.username);
            } catch (e) {
                commit(SET_GSC_USERNAME, false);
            } finally {
                commit(SET_GSC_USERNAME_IS_FETCHING, false);
            }
        },
        async fetchGSCProfiles({ commit, getters }) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(
                    i18n.t('upgrade-for-google', { service: i18n.t('google-search-console') }),
                );
                return false;
            }

            const { getGSCUsername } = getters;

            if (getGSCUsername) {
                try {
                    commit(SET_GSC_PROFILES_IS_FETCHING, true);
                    const res = await gscApi.fetchProfiles();

                    if (res?.data?.profiles) {
                        commit(
                            SET_GSC_PROFILES,
                            res.data.profiles.map(el => {
                                let domain = el.replace('sc-domain:', '');

                                return {
                                    label: `GSC: ${domain}`,
                                    value: el,
                                };
                            }),
                        );
                    }
                } catch (e) {
                    commit(SET_GSC_PROFILES, []);
                } finally {
                    commit(SET_GSC_PROFILES_IS_FETCHING, false);
                }
            }
        },
        async fetchGSCSuggestions({ commit, getters }) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(
                    i18n.t('upgrade-for-google', { service: i18n.t('google-search-console') }),
                );
                return false;
            }
            const { getGSCSuggestionsSort, getGSCProfileCurrentConnection, getGSCFilters } = getters;

            if (getGSCProfileCurrentConnection?.id) {
                try {
                    commit(SET_GSC_SUGGESTIONS_IS_FETCHING, true);
                    const parsedFilters = { params: parseGSCFilters(getGSCFilters) };
                    const res = await gscApi.fetchSuggestions(getGSCProfileCurrentConnection.id, parsedFilters);

                    commit(
                        SET_GSC_SUGGESTIONS,
                        sortSuggestions(res?.data?.data ?? [], getGSCSuggestionsSort),
                    );
                } catch (e) {
                    commit(SET_GSC_SUGGESTIONS, []);
                } finally {
                    commit(SET_GSC_SUGGESTIONS_IS_FETCHING, false);
                }
            } else {
                commit(SET_GSC_SUGGESTIONS, []);
            }
        },
        async fetchGSCDeletedSuggestions({ commit, getters }) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(
                    i18n.t('upgrade-for-google', { service: i18n.t('google-search-console') }),
                );
                return false;
            }

            const { getGSCProfileCurrentConnection } = getters;

            if (getGSCProfileCurrentConnection?.id) {
                try {
                    commit(SET_GSC_DELETED_SUGGESTIONS_IS_FETCHING, true);
                    const res = await gscApi.fetchDeletedSuggestions(
                        getGSCProfileCurrentConnection.id,
                    );
                    commit(SET_GSC_DELETED_SUGGESTIONS, res?.data?.data ?? []);
                } catch (e) {
                    commit(SET_GSC_DELETED_SUGGESTIONS, []);
                } finally {
                    commit(SET_GSC_DELETED_SUGGESTIONS_IS_FETCHING, false);
                }
            }
        },
        async fetchGSCProfileConnection({ commit, getters, dispatch }, data) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(
                    i18n.t('upgrade-for-google', { service: i18n.t('google-search-console') }),
                );
                return false;
            }

            const { getCurrentProject } = getters;

            if (data?.id || getCurrentProject?.id) {
                try {
                    commit(SET_GSC_PROFILE_CONNECTIONS_IS_FETCHING, true);
                    let queryData = {
                        params: {
                            project_id: data?.project_id ?? getCurrentProject?.project_id,
                        },
                    };
                    const res = await gscApi.fetchProfileConnection(queryData);

                    if (res?.data?.data) {
                        let connection = res.data.data;
                        connection.originalDomain = connection.profile_url;
                        connection.domain = connection.profile_url.replace('sc-domain:', '');

                        commit(SET_GSC_PROFILE_CONNECTION, connection);
                        dispatch('fetchGSCSuggestions');
                        dispatch('fetchGSCDeletedSuggestions');
                    }
                } catch (e) {
                    commit(SET_GSC_PROFILE_CONNECTION, null);
                } finally {
                    commit(SET_GSC_PROFILE_CONNECTIONS_IS_FETCHING, false);
                }
            } else if (getCurrentProject) {
                commit(SET_GSC_PROFILE_CONNECTION, null);
            }
        },
        async fetchGSCProfileConnections({ getters, commit }) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(
                    i18n.t('upgrade-for-google', { service: i18n.t('google-search-console') }),
                );
                return false;
            }

            commit(SET_GSC_PROFILE_CONNECTIONS_IS_FETCHING, true);

            try {
                const res = await gscApi.fetchProfileConnections();

                if (res?.data?.data) {
                    commit(
                        SET_GSC_PROFILE_CONNECTIONS,
                        res.data.data.map(el => {
                            el.originalDomain = el.domain;
                            el.domain = el.domain.replace('sc-domain:', '');

                            return el;
                        }),
                    );
                }
            } catch (e) {
                commit(SET_GSC_PROFILE_CONNECTIONS, []);
            } finally {
                commit(SET_GSC_PROFILE_CONNECTIONS_IS_FETCHING, false);
            }
        },
        async deleteGSCSuggestions({ commit, getters, dispatch }, keywords) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(
                    i18n.t('upgrade-for-google', { service: i18n.t('google-search-console') }),
                );
                return false;
            }

            const { getGSCProfileCurrentConnection } = getters;

            if (getGSCProfileCurrentConnection?.id) {
                try {
                    commit(SET_GSC_SUGGESTIONS_DELETING, true);
                    const queryData = {
                        data: { keywords },
                    };

                    await gscApi.deleteSuggestions(getGSCProfileCurrentConnection.id, queryData);
                    dispatch('fetchGSCSuggestions');
                    dispatch('fetchGSCDeletedSuggestions');
                } catch (e) {
                    //
                } finally {
                    commit(SET_GSC_SUGGESTIONS_DELETING, false);
                }
            }
        },
        async restoreGSCSuggestions({ commit, getters, dispatch }, keywords) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(
                    i18n.t('upgrade-for-google', { service: i18n.t('google-search-console') }),
                );
                return false;
            }

            const { getGSCProfileCurrentConnection, getUserData } = getters;

            if (getGSCProfileCurrentConnection?.id) {
                try {
                    commit(SET_GSC_SUGGESTIONS_RESTORING, true);
                    const queryData = {
                        data: { keywords },
                    };

                    await gscApi.restoreSuggestions(getGSCProfileCurrentConnection.id, queryData);
                    dispatch('fetchGSCSuggestions');
                    dispatch('fetchGSCDeletedSuggestions');

                    /**
                     * GTM custom event suggestions_restored
                     */
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: GTM_EVENTS.SUGGESTIONS_RESTORED,
                        uid: getUserData.userId,
                    });
                } catch (e) {
                    //
                } finally {
                    commit(SET_GSC_SUGGESTIONS_RESTORING, false);
                }
            }
        },
        async createGSCProfileConnections(
            { commit, getters, dispatch },
            { profile, selectedProjects, deleteId, fetchAll, rules, auto_add },
        ) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(
                    i18n.t('upgrade-for-google', { service: i18n.t('google-search-console') }),
                );
                return false;
            }

            let project_ids = [];

            if (selectedProjects.length) {
                for (let i = 0; i < selectedProjects.length; i++) {
                    project_ids.push(selectedProjects[i].project_id);
                }
            }

            const queryData = {
                data: {
                    profile_url: profile,
                    project_ids: project_ids,
                    rules,
                    auto_add
                },
            };

            commit(SET_GSC_PROFILE_CONNECTIONS_CREATING, true);

            try {
                const res = await gscApi.createProfileConnections(queryData);
                EventBus.emit(Events.CREATE_GSC_PROFILE, queryData.data);

                if (res && res.status === 200) {
                    if (deleteId) {
                        dispatch('deleteGSCProfileConnection', {
                            connectionId: deleteId,
                            fetchAll,
                            update: true,
                        });
                    } else if (fetchAll) {
                        dispatch('fetchGSCProfileConnections');
                    } else {
                        dispatch('fetchGSCProfileConnection', {
                            id: res.data.data[0].project.name,
                        });
                    }
                    return res.data.data;
                }
            } catch (e) {
                throw e;
            } finally {
                commit(SET_GSC_PROFILE_CONNECTIONS_CREATING, false);
            }
        },
        async updateGSCProfileConnection(
            { commit, getters, dispatch },
            { profile, connection, rules, fetchAll, auto_add },
        ) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(
                    i18n.t('upgrade-for-google', { service: i18n.t('google-search-console') }),
                );
                return false;
            }

            const queryData = {
                data: {
                    rules: rules,
                    auto_add: rules.autoAdd || auto_add,
                    profile_url: profile,
                },
            };

            commit(SET_GSC_PROFILE_CONNECTIONS_UPDATING, true);

            try {
                await gscApi.updateProfileConnection(connection, queryData);

                if (fetchAll) {
                    dispatch('fetchGSCProfileConnections');
                } else {
                    dispatch('fetchGSCProfileConnection');
                }
            } catch (e) {
                throw e;
            } finally {
                commit(SET_GSC_PROFILE_CONNECTIONS_UPDATING, false);
            }
        },
        async deleteGSCProfileConnection(
            { commit, getters, dispatch },
            { connectionId, fetchAll, update },
        ) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(
                    i18n.t('upgrade-for-google', { service: i18n.t('google-search-console') }),
                );
                return false;
            }

            commit(SET_GSC_PROFILE_CONNECTIONS_DELETING, true);

            try {
                await gscApi.deleteProfileConnection(connectionId);

                if (fetchAll) {
                    dispatch('fetchGSCProfileConnections');
                } else {
                    commit(SET_GSC_PROFILE_CONNECTION, null);
                }

                let item = i18n.t('profile-project-connection');

                if (update) {
                    toastr.s(i18n.t('item-update-success', { item }));
                } else {
                    toastr.s(i18n.t('item-delete-success', { item }));
                }
            } catch (e) {
                throw e;
            } finally {
                commit(SET_GSC_PROFILE_CONNECTIONS_DELETING, false);
                commit(SET_GSC_SUGGESTIONS, []);
            }
        },
        setKeywordsFromSuggestionsCreating({ commit, dispatch }, value) {
            commit(SET_GSC_KEYWORDS_FROM_SUGGESTIONS_CREATING, value);
            if (!value) {
                dispatch('fetchGSCSuggestions');
            }
        },
        GSCSorterChanged({ getters, commit }, payload) {
            commit(SET_GSC_SUGGESTIONS_IS_FETCHING, true);
            const { getGSCSuggestionsSort, getGSCSuggestions } = getters;

            let newSort = {
                field: payload,
                direction:
                    getGSCSuggestionsSort.field === payload
                        ? getGSCSuggestionsSort.direction === 'asc' ? 'desc' : 'asc'
                        : 'desc',
            };

            commit(SET_GSC_SUGGESTIONS_SORT, newSort);
            commit(SET_GSC_SUGGESTIONS, sortSuggestions(getGSCSuggestions, newSort));
            commit(SET_GSC_SUGGESTIONS_IS_FETCHING, false);
        },
        setGSCFilters({ commit }, payload) {
            let result = null;
            if (payload) {
                Object.entries(payload).forEach(([key, fValue]) => {
                    if (fValue.value || (fValue.filter === 'between' && fValue.max)) {
                        if (!result) {
                            result = {};
                        }
                        result[key] = fValue;
                    }
                });
            }
            commit(SET_GSC_FILTERS, result);
        },
        setCompetitorKWFilters({ commit }, payload) {
            let result = null;
            if (payload) {
                Object.entries(payload).forEach(([key, fValue]) => {
                    if (fValue.value || (fValue.filter === 'between' && fValue.max)) {
                        if (!result) {
                            result = {};
                        }
                        result[key] = fValue;
                    }
                });
            }
            commit(SET_COMPETITOR_KW_FILTERS, result);
        },
        setRelatedKWFilters({ commit }, payload) {
            let result = null;
            if (payload) {
                Object.entries(payload).forEach(([key, fValue]) => {
                    if (fValue.value || (fValue.filter === 'between' && fValue.max)) {
                        if (!result) {
                            result = {};
                        }
                        result[key] = fValue;
                    }
                });
            }
            commit(SET_RELATED_KW_FILTERS, result);
        },
        async fetchRelatedKeywords({ commit }, filters) {
            commit(SET_GSC_RELATED_KEYWORD_DATA, []);

            const queryData = {
                params: { ...filters },
            };
            let jobId = null;
            try {
                const resp = await gscApi.fetchRelatedKeywords(queryData);
                if (resp.data?.jobId) {
                    jobId = resp.data.jobId;
                } else {
                    commit(SET_GSC_RELATED_KEYWORD_DATA, resp.data?.data || []);
                    return;
                }
            } catch (e) {
                return e;
            }
            const maxRetries = 10;
            let retryCount = 0;
            if (jobId) {
                while (retryCount < maxRetries) {
                    try {
                        const resp = await gscApi.fetchRelatedKeywordsByJobId(jobId);
                        if (resp.status === 200) {
                            commit(SET_GSC_RELATED_KEYWORD_DATA, resp.data?.data || []);
                            return resp.data;
                        }
                    } catch (e) {
                        if (e.response.status !== 409) {
                            return e;
                        }
                    }

                    // Wait 1 second before retrying
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    retryCount++;
                }
            }

            return null;
        },
        async fetchCompetitorKeywords({ commit }, filters) {
            commit(SET_GSC_COMPETITORS_DATA, []);
            
            const queryData = {
                params: { ...filters },
            };
            let jobId = null;
            try {
                const resp = await gscApi.fetchCompetitorKeywords(queryData);
                if (resp.data?.jobId) {
                    jobId = resp.data.jobId;
                } else {
                    commit(SET_GSC_COMPETITORS_DATA, resp.data?.data || []);
                    return;
                }
            } catch (e) {
                return e;
            }
            const maxRetries = 10;
            let retryCount = 0;
            if (jobId) {
                while (retryCount < maxRetries) {
                    try {
                        const resp = await gscApi.fetchRelatedKeywordsByJobId(jobId);
                        if (resp.status === 200) {
                            commit(SET_GSC_COMPETITORS_DATA, resp.data?.data || []);
                            return resp.data;
                        }
                    } catch (e) {
                        if (e.response.status !== 409) {
                            return e;
                        }
                    }

                    // Wait 1 second before retrying
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    retryCount++;
                }
            }
            
            return null;
        },
        setRelatedKeywords({ commit }, value) {
            commit(SET_GSC_RELATED_KEYWORD_DATA, value);
        },
        setCompetitorsKeywords({ commit }, value) {
            commit(SET_GSC_COMPETITORS_DATA, value);
        },
        deleteGSCFilterItem({ commit, getters }, key) {
            let result = null;
            const filters = getters.getGSCFilters;

            if (filters && Object.keys(filters).length > 1) {
                result = _.cloneDeep(filters);
                delete result[key];
            }
            commit(SET_GSC_FILTERS, result);
        },
        deleteRelatedKWFilterItem({ commit, getters }, key) {
            let result = null;
            const filters = getters.getRelatedKWfilters;

            if (filters && Object.keys(filters).length > 1) {
                result = _.cloneDeep(filters);
                delete result[key];
            }
            commit(SET_RELATED_KW_FILTERS, result);
        },
        deleteCompetitorKWFilterItem({ commit, getters }, key) {
            let result = null;
            const filters = getters.getCompetitorKWfilters;

            if (filters && Object.keys(filters).length > 1) {
                result = _.cloneDeep(filters);
                delete result[key];
            }
            commit(SET_COMPETITOR_KW_FILTERS, result);
        },
        relatedKeywordsSorterChanged({ getters, commit }, payload) {
            const { getRelatedKeywordsSort } = getters;

            let newSort = {
                field: payload,
                direction:
                    getRelatedKeywordsSort.field === payload
                        ? getRelatedKeywordsSort.direction === 'asc' ? 'desc' : 'asc'
                        : 'desc',
            };

            commit(SET_GSC_RELATED_KEYWORD_SORT, newSort);
        },
        competitorsSorterChanged({ getters, commit }, payload) {
            const { getCompetitorsSort } = getters;

            let newSort = {
                field: payload,
                direction:
                    getCompetitorsSort.field === payload
                        ? getCompetitorsSort.direction === 'asc' ? 'desc' : 'asc'
                        : 'desc',
            };

            commit(SET_GSC_COMPETITORS_SORT, newSort);
        },
        async saveGSCFilter({ commit }, payload) {
            const { projectId, filter_name, tab_name, filters } = payload;
            try {
                const parsedFilters = parseGSCFilters(filters);

                const resp = await gscApi.createFilter(projectId, { data: { filter_name, tab_name, filters: parsedFilters } });
                return Promise.resolve(resp);
            } catch (e) {
                return Promise.reject(e);;
            }
        },
        async fetchGSCFilters({ commit, getters }, payload) {
            try {
                const { project_id } = getters.getCurrentProject;
                const resp = await gscApi.fetchFilters(project_id, payload.tab_name);
                commit(SET_SAVED_FILTER_LIST, resp.data.data || []);
            } catch (e) {
                return e;
            }
        },
        async deleteGSCFilter({ commit }, payload) {
            try {
                const resp = await gscApi.deleteFilter(payload);
            } catch (e) {
                return e;
            }
        },
        setCurrentSavedFilter({ commit, getters }, payload) {
            commit(SET_CURRENT_SAVED_FILTER, {
                ...getters.getCurrentSavedFilter,
                [payload.key]: payload.value,
            });
        },
        resetAllGSCFilters({ commit }, payload) {
            commit(SET_CURRENT_SAVED_FILTER, {
                suggestions: null,
                related: null,
                competitors: null,
            });
            commit(SET_RELATED_KW_FILTERS, null);
            commit(SET_COMPETITOR_KW_FILTERS, null);
            commit(SET_GSC_FILTERS, null);
        },
    },
};

export default googleSearchConsole;
