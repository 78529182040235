<template>
    <span class="custom-your-field" :class="{ passive: params.value === null }">
        {{ getValue }}
        <span class="icon kw kw-chevron-down" />
    </span>
</template>

<script>
export default {
    name: 'YourFieldEditor',
    computed: {
        getValue() {
            return this.params.value ?? this.$t('select-field');
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-your-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90%;
    margin-top: 3px;
    padding: 5px;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px;
    background: #ffffff;
    border: 0.2rem solid #dee5ec;
    border-radius: 4px;
    text-overflow: ellipsis;
    overflow: hidden;

    &.passive {
        color: #8b91a2;
    }

    &:hover {
        background-color: #efeff1;
    }

    .icon {
        font-size: 8px;
    }
}
</style>
