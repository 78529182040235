<template>
  <div class="display-settings__table" data-cy="table-other-settings">
    <!-- <span class="kw kw-more-horizontal table_setting_icon" /> -->
    <h2 class="settings__subtitle" v-text="$t('other-settings')" />
    <!-- table width setting -->
    <label
      for="table-width"
      class="settings__label"
      v-text="$t('display-mode')"
    />
    <button-group
      id="table-width"
      :value="getOtherSetting('widetable')"
      @input="wideTablehandler($event)"
    >
      <radio selected-value="hide" data-value="hide">
        {{ $t("standard-table-width") }}
      </radio>
      <radio selected-value="show" data-value="show">
        {{ $t("extra-wide-table-width") }}
      </radio>
    </button-group>
    <label
        for="list-projects"
        class="settings__label"
    >
      {{ $t('projects-list-sorting') }}
      <span
          class="kw kw-info"
          @mouseover="tooltip({ text: $t('projects-list-sorting-tooltip'), event: $event })"
      />
    </label>
    <v-select
        id="list-projects"
        options-label="label"
        options-value="value"
        :options="projectsSort"
        :value="getDisplaySetting('category_sort')"
        @change="e => save({ setting: 'category_sort', value: e })"
    />
    <!-- CPC currency setting -->
    <label for="reporting-currency" class="settings__label">
        <span v-text="$t('reporting-currency')" />
        <span
            class="kw kw-info"
            @mouseover="
                tooltip({
                    text: $t('reporting-currency-tooltip'),
                    event: $event,
                })
            "
        />
    </label>
    <custom-v-select
        id="reporting-currency"
        data-cy="user-reporting-currency"
        options-label="label"
        options-value="value"
        :options="getCurrencies"
        search
        search-key="label"
        :value="initialCurrency"
        @change="e => save({ setting: 'currency_code', value: e })"
    />
    <!-- project metrics setting -->
    <label
        for="project-metrics"
        class="settings__label"
    >
      {{ $t('kw-pos-tracking-comparison-metrics') }}
      <span
          class="kw kw-info"
          @mouseover="
              tooltip({
                  text: $t('kw-pos-tracking-comparison-metrics-tooltip'),
                  event: $event,
              })
          "
      />
    </label>
    <v-select
        id="project-metrics"
        options-label="label"
        options-value="value"
        :options="periodConfig"
        :value="getDisplaySetting('metrics')"
        @change="e => save({ setting: 'metrics', value: e })"
    />
    <!-- metric ranges setting -->
    <label for="metric-ranges" class="settings__label">
        <span v-text="$t('metrics-buckets')" />
        <span
            class="kw kw-info"
            @mouseover="
                tooltip({
                    text: $t('metrics-buckets-tooltip'),
                    event: $event,
                })
            "
        />
    </label>
    <div id="metric-ranges" class="display-settings__ranges">
        <kw-input-field
            v-for="(range, i) in metrics_range"
            :key="i"
            v-model="metrics_range[i]"
            class="text-input"
            maxlength="3"
            :placeholder="$t('metrics-range-placeholder')"
            type="text"
            name="metricsRanges"
            :error-data="rangeInputError(i)"
            @blur="debouncedHandler(setRange)"
            @keyup.enter.stop.prevent="debouncedHandler(setRange)"
        />
    </div>
  </div>
</template>
<script>
import "vue-slider-component/theme/antd.css";
import { mapGetters, mapActions } from "vuex";
import ToolTipRenderMixin from "@/mixins/ToolTipRenderMixin";
import ValidationMixin from "@/mixins/ValidationMixin";
import { oldOverviewUsers } from "@/constants";
import buttonGroup from "vue-strap/src/buttonGroup";
import Radio from "vue-strap/src/Radio";
import vSelect from "vue-strap/src/Select";
import { TYPING_DEBOUNCE } from '@/constants';
import {
  googleChartsOptions,
  rankingAreaChartOptions,
  perPageConfig,
  periodConfig,
  projectsSort,
} from "@/components/settings/configs";

export default {
  name: "TableOtherSettings",
  components: {
    "button-group": buttonGroup,
    radio: Radio,
    "v-select": vSelect
  },
  mixins: [ValidationMixin, ToolTipRenderMixin],
  data() {
    return {
      googleChartsOptions,
      rankingAreaChartOptions,
      perPageConfig,
      inited: false,
      err: { metrics_range: {} },
      metrics_range: ["", "", "", "", ""],
      debouncedHandler: _.debounce(fn => fn(), TYPING_DEBOUNCE),
      periodConfig,
      projectsSort
    };
  },
  computed: {
    ...mapGetters([
      "getDisplaySetting",
      "getOtherSetting",
      "getDisplaySettings",
      "getUserDataProp",
      "getCurrencies",
    ]),
    getDisplayChartInKeywordTabText() {
      const email = this.getUserDataProp("email");
      return oldOverviewUsers.includes(email)
        ? this.$t("ranking-area-chart")
        : this.$t("display-visibility-and-est-traffic");
    },
    initialCurrency() {
      return this.getCurrencies.length
        ? this.getDisplaySetting('currency_code')
        : null;
    },
  },
  mounted() {
    this.metrics_range = this.getDisplaySetting("metrics_range").map(el =>
      el.toString()
    );
    this.$nextTick(() => (this.inited = true));
  },
  methods: {
    ...mapActions(["updateUserTableSettings", "updateUserDisplaySettings"]),
    checkboxCondition(name) {
      return this.getOtherSetting(name) === "show";
    },
    toggleCheckbox(name) {
      this.updateUserTableSettings({
        setting: name,
        value: this.getOtherSetting(name) === "show" ? "hide" : "show"
      });
    },
    save(setting) {
      if (!this.inited) return;
      this.updateUserDisplaySettings(setting);
    },
    settingsItemClasses(item) {
      return {
        "display-settings__item": true,
        "display-settings__item_parent": item.children && item.children.length
      };
    },
    wideTablehandler(value) {
      this.updateUserTableSettings({ setting: "widetable", value });
    },
    setRange() {
      this.err.metrics_range = this.metricsRangeValidator(this.metrics_range);

      if (!_.isEmpty(this.err.metrics_range)) {
        this.$toastr.e(this.err.metrics_range.text);
        if (this.err.metrics_range.type !== "duplicates-error") {
          this.err.metrics_range.indexes.forEach(
            i => (this.metrics_range[i] = "")
          );
        }

        return;
      }

      this.updateUserDisplaySettings({ setting: "metrics_range", value: this.metrics_range });
    },
    rangeInputError(i) {
        const { indexes } = this.err.metrics_range;
        return {
          error: indexes && indexes.includes(i),
        };
    },
  }
};
</script>

<style lang="scss">
@import "~sass/partials/dropdowns";

.settings__label_with_tooltip {
  flex: none !important;
  margin-right: 3px;
}

.display-settings__table {
  display: flex;
  flex-flow: column nowrap;
  padding: 24px;

  .button-closer {
    align-self: flex-end;
  }

  .form-control.dropdown-toggle {
    @extend .custom-dropdown;
    color: kw-color(kw-black, 1);
  }

  .btn-group {
    margin-bottom: 16px;

    .btn {
      border-color: #dee5ec;
      box-shadow: none;
      color: kw-color(kw-black, 1);
      font-size: 13px;
      line-height: 15px;
      outline: transparent !important;
      width: 50%;
      padding: 7px;
      height: 32px;

      &:hover {
        background-color: transparent;
      }

      &.active {
        background-color: kw-color(kw-blue, 1);
        border-color: kw-color(kw-blue, 1);
        color: kw-color(kw-white, 1);
      }
    }
  }

  .open .form-control.dropdown-toggle {
    @extend .custom-dropdown_active;
  }

  .dropdown-menu {
    @extend .custom-scroll;
    @extend .custom-dropdown-menu;
    width: 100%;

    .glyphicon.glyphicon-ok.check-mark:before {
      content: "";
    }
  }

  .vue-slider {
    padding: 10px 0 8px 0 !important;

    .vue-slider-rail {
      background-color: #dee5ec;
      box-shadow: none;

      .vue-slider-process {
        box-shadow: none;
        background-color: kw-color(kw-blue, 1);
      }

      .vue-slider-dot-handle {
        background-color: kw-color(kw-blue, 1);
        border: none;
      }
    }
  }

  .radio-buttons {
    display: flex;

    .radio-button {
      &:first-child {
        margin-right: 12px;
      }
    }
  }

  .display-settings__item {
    min-width: max-content;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    position: relative;

    .checkbox {
      position: relative !important;
      top: unset;
    }

    &_parent {
      position: relative;

      &:before {
        content: "";
        width: 1px;
        height: calc(100% - 42px);
        background-color: #c4c4c4;
        position: absolute;
        top: 32px;
        left: 8px;
      }
    }

    &:last-child {
      margin-bottom: 6px;
    }

    &__block {
      border-top: 1px solid #e1e8ef;
      padding-top: 10px;
      max-width: 350px;
    }
  }

  .display-settings__subitem {
    align-items: center;
    display: flex;
    flex-grow: 1;
    padding: 5px 0 5px 24px;
    position: relative;

    &:before {
      border-bottom: 1px solid #c4c4c4;
      content: "";
      left: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 11px;
    }

    &:last-child {
      &:after {
        background-color: kw-color(kw-white, 1);
        content: "";
        height: 28px;
        left: 6px;
        position: absolute;
        top: calc(50% + 1px);
        width: 4px;
      }
    }

    @media screen and (max-width: 800px) {
      flex-basis: 100%;
    }
  }

  .settings__label_other {
    cursor: pointer;
    flex-grow: 1;
    font-weight: normal !important;
    margin-bottom: 0;
    padding-left: 8px;
  }

  .settings__label_other {
    justify-content: flex-start;
    text-transform: unset;

    span {
      color: kw-color(kw-green, 1);
    }

    @media screen and (max-width: 480px) {
      max-width: calc(100% - 16px);
    }
  }
}

#table-width {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid kw-color(kw-white, 5);
  margin-top: 4px;
}

.display-settings__limits {
  color: kw-color(kw-grey, 1);
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  line-height: 15px;
  padding-bottom: 20px;
}

.display-settings__ranges {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  input {
    text-align: center;
    width: 54px;
    &.kw__input {
      height: 32px;
      border-color: kw-color(kw-white, 5);
    }

    @media screen and (max-width: 480px) {
      padding: 10px 10px;
      width: 44px;
    }
  }
}

.display-settings__table-sort {
  display: flex;
  flex-flow: row nowrap;

  .btn-group {
    width: 50%;

    &:first-child {
      margin-right: 8px;
    }
  }
}

.settings__label {
  justify-content: initial !important;

  &.text-wrap {
    white-space: break-spaces;
  }
}

.settings__label_chart-height {
  justify-content: space-between !important;
}

.kw-information {
  color: #828f9c;
  font-size: 12px;
  font-weight: 700;
  margin: 0 4px;
}
</style>
