import {switchDateFormat} from '@/helpers/format-date-service';
import i18n from '@/i18n';
import { MAX_KEYWORDS_COUNT_TO_FETCH_CHARTS } from '@/constants';

const displayCheckboxesConfig = [
    {name: 'companyInformationPdfSettings', label: i18n.t('company-information-pdf-setting'), tooltip: i18n.t('pdf-settings-company-information')},
    {name: 'hideVerifiedPdfSettings', label: i18n.t('hide-verified')},
    {name: 'hideReportPdfDate', label: i18n.t('hide-report-date')},
    {name: 'generateHistoricalGraphPdf', label: i18n.t('generate-historical-graphs-in-pdf'), tooltip: i18n.t('limit-info-about-historical-graphs-in-pdf', {count: MAX_KEYWORDS_COUNT_TO_FETCH_CHARTS}),},
];

const dateFormatConfig = [
    {label: switchDateFormat(new Date(), 'M dS, Y'), value: 'M dS, Y'},
    {label: switchDateFormat(new Date(), 'F jS, Y'), value: 'F jS, Y'},
    {label: switchDateFormat(new Date(), 'l F jS, Y'), value: 'l F jS, Y'},
    {label: switchDateFormat(new Date(), 'd/m/Y'), value: 'd/m/Y'},
    {label: switchDateFormat(new Date(), 'Y-m-d'), value: 'Y-m-d'},
];

const globalMSSettings = [
    {name: i18n.t('show-global'), value: 'show'},
    {name: i18n.t('show-usa'), value: 'hide'},
];

const googleChartsOptions = [
    {name: i18n.t('show'), value: 'show'},
    {name: i18n.t('hide'), value: 'hide'},
];

const rankingAreaChartOptions = [
    {name: i18n.t('show'), value: 'show'},
    {name: i18n.t('hide'), value: 'hide'},
];

const periodConfig = [
    {label: i18n.t('do-not-show-metrics'), value: 'hide'},
    {label: i18n.t('daily-values'), value: 'daily'},
    {label: i18n.t('weekly-values'), value: 'weekly'},
    {label: i18n.t('monthly-values'), value: 'monthly'},
    {label: i18n.t('start-values'), value: 'life'},
];

const perPageConfig = [
    {label: 10, value: 10},
    {label: 50, value: 50},
    {label: 100, value: 100},
    {label: 250, value: 250},
    {label: 'Unlimited', value: 99999},
];

// const projectCheckboxesConfig = [
//     {name: 'subcat', label: i18n.t('groups-setting')},
//     {
//         name: 'kwtotals',
//         label: i18n.t('display-keywords-totals'),
//         children: [
//             {name: 'kwtotalsmaster', label: i18n.t('kwtotals-master')},
//             {name: 'kwtotalsadmin', label: i18n.t('kwtotals-admin')},
//             {name: 'kwtotalsregular', label: i18n.t('kwtotals-regular')},
//             {name: 'kwtotalsview', label: i18n.t('kwtotals-view')},
//         ],
//     },
//     {name: 'gaps', label: i18n.t('rank-drop-setting')},
//     // {name: 'newKwTable', label: i18n.t('new-keywords-table')},
// ];

const projectsSort = [
    {label: i18n.t('project-name-asc'), value: 'asc'},
    {label: i18n.t('project-name-desc'), value: 'desc'},
];

const sortByConfig = [
    {label: 'Default', value: 'default'},
    {label: 'Keyword', value: 'kw'},
    {label: 'Start', value: 'start'},
    {label: 'Page', value: 'gpage'},
    {label: 'Best', value: 'best'},
    {label: 'Google Rank', value: 'g'},
    {label: '1 day change', value: 'day'},
    {label: '7 days change', value: 'week'},
    {label: '30 days change', value: 'month'},
    {label: 'Life change', value: 'life'},
    {label: 'Clicks', value: 'clicks'},
    {label: 'Impressions', value: 'impressions'},
    {label: 'CTR', value: 'ctr'},
    {label: 'Monthly Search Value', value: 'ms'},
    {label: 'CPC', value: 'cpc'},
    {label: 'Visibility', value: 'visibility'},
    {label: 'Estimated Traffic', value: 'estimated_traffic'},
    {label: 'Competition', value: 'competition'},
    {label: 'Search Results', value: 'comp'},
    {label: 'Tags', value: 'tags'},
    {label: 'Date Added', value: 'timestamp'},
    {label: 'Search Location', value: 'near'},
    {label: 'Source', value: 'source'},
    {label: 'GMB', value: 'gmb'},
    {label: 'Tracked URL', value: 'url'},
    {label: 'Ranking URL', value: 'domain'},
];

const sortByOrderConfig = [
    {label: 'Ascending', value: 'asc'},
    {label: 'Descending', value: 'desc'},
];

const projectSettingsRadios = [
    {name: i18n.t('yes'), value: 'yes'},
    {name: i18n.t('no'), value: 'no'},
];

export {
    dateFormatConfig,
    displayCheckboxesConfig,
    globalMSSettings,
    googleChartsOptions,
    rankingAreaChartOptions,
    periodConfig,
    perPageConfig,
    projectsSort,
    sortByConfig,
    sortByOrderConfig,
    projectSettingsRadios,
};