<template>
    <div class="keyword-suggestions-settings-modal">
        <div>
            <p class="title">{{ $t('keyword-suggestion-settings') }}</p>
            <p class="info">{{ $t('active-gsc-profile') }}</p>
            <div class="profile-select">
                <v-select
                    id="search-console-profile"
                    :class="profilesSelectClasses"
                    :options="getGSCProfiles"
                    :placeholder="$t('select-gsc-profile')"
                    search
                    :value.sync="selectValue"
                    @change="setSelected"
                />
                <kw-button
                    v-if="getConnectionProfile"
                    button-type="text"
                    class="remove-profile"
                    prepend-icon-class="kw kw-trash-2"
                    @click="confirmDelete"
                />
            </div>
            <!-- <kw-button
                class="connect-button"
                :button-text="$t('connect-gsc-account')"
                @click="goToGSC"
                :disabled="loading"
            /> -->
        </div>
        <div>
            <p class="gsc-settings-info">{{ $t('gsc-settings-info') }}</p>
            <div class="form-row full-width kw-suggestions-datepicker">
                <date-range-picker
                    hideButton
                    @onApply="onDateChange"
                    :value="dateValue"
                    :defaultKey="dateInterval"
                    :ignoreDates="['today', 'all_time']"
                >
                    <v-select
                        :options="[]"
                        :placeholder="getDateLabel(dateValue, dateInterval)"
                    />
                </date-range-picker>
            </div>
        </div>
        <div>
            <div class="auto-suggest">
                <toggler
                    :toggler="autoAdd"
                    @toggler_click="toggleAutoAdd"
                />
                <span class="toggle-text">
                    {{ $t('gsc-auto-add-hint') }}
                    <span
                        class="kw kw-info"
                        @mouseover="tooltip({ text: $t('gsc-auto-add-hint-tooltip'), event: $event })"
                    />
                </span>
            </div>
            <div v-if="autoAdd" class="auto-add-params">
                <p>{{ $t('gsc-settings-parameters-info') }}</p>
                <div class="filters-body">
                    <div v-for="(filter, key) in rules" :key="filter.label" class="form-row">
                    <p class="filter-label">
                        {{ filter.label }}
                        <span
                            v-if="filter.tooltip"
                            @mouseover="tooltip({ text: filter.tooltip, event: $event })"
                            class="kw kw-info"
                        />
                    </p>

                    <div v-if="filter.type==='numeric'" class="select-row">
                        <v-select
                            :options="filterBy"
                            placeholder="Greater than"
                            :value.sync="rules[key].filter"
                            @change="v=>setFilter(key, v)"
                        />
                        <kw-input-field
                            v-model.trim="rules[key].value"
                            :name="key"
                            isNumeric
                            allowDecimals
                        />
                        <kw-input-field
                            v-if="rules[key].filter==='between'"
                            v-model.trim="rules[key].max"
                            :name="`${key}Max`"
                            isNumeric
                            allowDecimals
                        />
                    </div>
                </div>
            </div>
            </div>
            <kw-button
                @click="openDeletedSuggestionsModal"
                button-type="text"
                :button-text="$t('view-removed-keywords-list')"
                class="view-removed-list"
            />
        </div>
        <div class="footer">
            <kw-button
                button-type="secondary"
                :button-text="$t('close')"
                @click="close"
            />
            <kw-button
                :button-text="$t('apply-settings')"
                @click="onApply"
                :disabled="loading"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SocialSignInMixin from '@/mixins/SocialSignInMixin';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import SimpleDeleteWithCustomHandler from 'components/modals/SimpleDeleteWithCustomHandler';
import GSCDeletedSuggestionsModal from '@/components/keyword-suggestions/components/modals/GSCDeletedSuggestionsModal';
import DateRangePicker from '@/components/date-range-picker/DateRangePicker.vue';
import { datePickerList } from '@/components/date-range-picker/datePickerList'
import { switchDateFormat } from '@/helpers/format-date-service';
import { parseGSCFilters } from '@/helpers';
import { suggestionsAutoAddFilters, filterBy } from '@/components/keyword-intelligence/filterFields'
const vSelect = () => import('vue-strap/src/Select');

export default {
    name: 'KeywordSuggestionsSettingsModal',
    components: {
        'v-select': vSelect,
        'date-range-picker': DateRangePicker
    },
    mixins: [ToolTipRenderMixin, SocialSignInMixin],
    data(){
        return {
            loading: false,
            selectValue: null,
            autoAdd: false,
            dateValue: null,
            dateInterval: 'last_month',
            rules: {},
            datePickerList,
            filterBy
        }
    },
    computed: {
        ...mapGetters([
            'getGSCProfiles',
            'getCurrentProject',
            'getGSCDeletedSuggestions',
            'getGSCProfilesIsFetching',
            'getGSCProfileCurrentConnection',
            'getGSCDeletedSuggestionsIsFetching',
        ]),
        profilesSelectClasses() {
            return {
                'custom-dropdown_filled': this.selectValue,
            };
        },
        getConnectionProfile() {
            return this.getGSCProfileCurrentConnection
                ? this.getGSCProfileCurrentConnection.originalDomain
                : null;
        },
    },
    mounted(){
        this.selectValue = this.getConnectionProfile;
        this.rules = _.cloneDeep(suggestionsAutoAddFilters);

        if (this.getGSCProfileCurrentConnection) {
            this.autoAdd = this.getGSCProfileCurrentConnection.auto_add || false;
            const connectionRules = this.getGSCProfileCurrentConnection.rules || {};
            const { period, date_from, date_to } = connectionRules;
            if (period) {
                Object.keys(this.datePickerList).forEach((key) => {
                    if(this.datePickerList[key].gscCount === period) {
                        this.dateInterval = key;
                    }
                })
            } else if (date_from && date_to) {
                this.dateValue = [date_from, date_to];
                this.dateInterval = null;
            }

            Object.keys(this.rules).forEach((key) => {
                if (connectionRules[key]) {
                    this.rules[key].value = connectionRules[key];
                }
                if (connectionRules[`${key}_symbol`]) {
                    this.rules[key].filter = connectionRules[`${key}_symbol`];
                }
                if (connectionRules[`${key}_min`]) {
                    this.rules[key].value = connectionRules[`${key}_min`].toString();
                }
                if (connectionRules[`${key}_max`]) {
                    this.rules[key].max = connectionRules[`${key}_max`].toString();
                }
            })
        }
    },
    methods: {
        ...mapActions([
            'setCurrentProject',
            'updateGSCProfileConnection',
            'deleteGSCProfileConnection',
            'createGSCProfileConnections',
        ]),
        setSelected(value){
           this.selectValue = value;
        },
        async setConnectionProfile() {
            this.loading = true;
            if (this.getGSCProfileCurrentConnection) {
                if (
                    this.getGSCProfileCurrentConnection?.originalDomain !==
                    this.selectValue
                ) {
                    let data = {
                        connection: this.getGSCProfileCurrentConnection?.id,
                        profile: this.selectValue,
                        rules: this.getGSCProfileCurrentConnection?.rules ?? [],
                        fetchAll: false,
                    };
                    await this.updateGSCProfileConnection(data);
                    this.loading = false;
                }
            } else {
                if (this.selectValue && this.getCurrentProject) {
                    try {
                        const res = await this.createGSCProfileConnections({
                            profile: this.selectValue,
                            selectedProjects: [this.getCurrentProject],
                            fetchAll: false,
                            rules: { period: 30 },
                            auto_add: this.autoAdd
                        });

                        this.$toastr.s(
                            this.$t('item-create-success', {
                                item: this.$t('profile-project-connection-s'),
                            }),
                        );

                        return res;
                    } catch (e) {
                        throw e;
                    } finally {
                        this.loading = false;
                    }
                }
            }
        },
        close() {
            this.$emit('close');
        },
        toggleAutoAdd(){
            this.autoAdd = !this.autoAdd;
        },
        openDeletedSuggestionsModal() {
            this.$modal.show(
                GSCDeletedSuggestionsModal,
                {
                    deletedSuggestions: this.getGSCDeletedSuggestions,
                    loading: this.getGSCDeletedSuggestionsIsFetching
                },
                {
                    classes: 'v--modal',
                    draggable: '.custom-modal_draggable',
                    height: 'auto',
                    name: 'GSCDeletedSuggestionsModal',
                    width: 400,
                },
            );
        },
        confirmDelete() {
            if (this.getGSCProfileCurrentConnection) {
                this.$modal.show(
                    SimpleDeleteWithCustomHandler,
                    {
                        item: this.getGSCProfileCurrentConnection.id,
                        deleteHandler: this.deleteConnection,
                        type: `${this.$t('profile-project-connection')}?`,
                    },
                    {
                        classes: 'v--modal',
                        height: 'auto',
                        name: 'SimpleDeleteWithCustomHandler',
                        width: 310,
                    },
                );
            }
        },
        async deleteConnection(connectionId) {
            try {
                let data = {
                    connectionId,
                    update: false,
                    fetchAll: false,
                };
                await this.deleteGSCProfileConnection(data);
                this.$modal.hide('SimpleDeleteWithCustomHandler');
                this.close();
            } catch (error) {
                this.$toastr.e(error);
            }
        },
        goToGSC(){
            this.$router.push({ name: 'googleSearchConsole' });
            this.close();
        },
        onApply(){
            this.setConnectionProfile().then((res)=>{
                let data = {
                    connection: this.getGSCProfileCurrentConnection ? this.getGSCProfileCurrentConnection.id : res?.[0]?.id,
                    rules: {},
                    fetchAll: false,
                    auto_add: this.autoAdd,
                };
                
                if (this.dateInterval) {
                    data.rules.period = this.datePickerList[this.dateInterval]?.gscCount;
                } else if (this.dateValue?.[1]) {
                    data.rules.date_from = this.dateValue[0];
                    data.rules.date_to = this.dateValue[1];
                }

                data.rules = {...data.rules, ...parseGSCFilters(this.rules)};

                this.updateGSCProfileConnection(data).then(()=>{
                    this.close();
                }).catch(() => {
                    this.loading = false;
                });
            });
        },
        getDateLabel(value, dateKey) {
            if (dateKey) return this.datePickerList[dateKey].label;
            if (value) return `${switchDateFormat(new Date(value[0]), 'note')} - ${switchDateFormat(new Date(value[1]), 'note')}`;
            return 'Last Month';
        },
        onDateChange(value, dateKey, isCustom) {
            if (isCustom) {
                this.dateValue = value;
                this.dateInterval = null;
            } else {
                this.dateInterval = dateKey;
                this.dateValue = null;
            }
        },
        setFilter(type, value) {
            if (this.rules[type]) {
                this.rules[type].filter = value;
                if (this.rules[type].max && value !== 'between') {
                    this.rules[type].max = '';
                }
            }
        },
   },
};
</script>

<style lang="scss" scoped>
    @import '~sass/modal';

    .keyword-suggestions-settings-modal {
        font-family: Roboto;

        & > div {
            padding: 24px 20px 20px;
            border-bottom: 1px solid kw-color(kw-light-grey, 1);
            &:nth-child(3) {
                border-bottom: 1px solid kw-color(kw-light-grey, 1);
                padding-bottom: 4px;
            }
        }

        .footer {
            display: flex;
            justify-content: flex-end;
            gap: 8px;
            padding: 20px 24px;
            .kw-button {
                height: 40px;
            }
        }

        .title {
            font-size: 24px;
            font-weight: 500;
            line-height: 20px;
            margin: 0;
        }

        .info {
            font-size: 13px;
            line-height: 14px;
            color: #CCCCCC;
            margin-top: 16px;
        }

        .profile-select {
            width: 100%;
            display: flex;
            .btn-group, .btn-group-vertical {
                width: 100%;
            }
            .remove-profile {
                font-weight: 900;
                font-size: 24px;
            }
            /deep/ .form-control.dropdown-toggle {
                .btn-content {
                    color: kw-color(kw-grey, 3);
                }
            }
            /deep/ .custom-dropdown_filled {
                .form-control.dropdown-toggle {
                    .btn-content {
                        color: kw-color(kw-black, 1);
                    }
                }
            }
        }

        .connect-button {
            height: 40px;
            margin-top: 10px;
        }

        .auto-suggest {
            .toggle-text {
                margin-left: 42px;
                font-size: 14px;
            }
            .kw-info {
                color: kw-color(kw-light-grey, 5);
                margin-left: 4px;
                font-weight: 900;
                font-size: 12px;
            }
            
        }
        button.kw-button.kw-btn-text.view-removed-list {
            margin-left: 0;
            font-weight: 500;
            font-size: 14px;
            margin-top: 10px;
        }

        .gsc-settings-info {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 16px;
        }

        .auto-add-params {
            & > p {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin: 16px 0;
            }
        }
        .filters-body {
            .form-row {
                width: 100%;
                margin-bottom: 11px;

                &.full-width {
                    /deep/ .btn-group {
                        width: 100%;
                    }
                }
            }

            .filter-label {
                font-size: 13px;
                font-weight: 500;
                line-height: 14px;
                margin-bottom: 8px;

                .kw-info {
                    margin-left: 4px;
                    font-weight: 700;
                    font-size: 12px;
                    color: kw-color(kw-light-grey, 5);
                }
            }

            .select-row {
                display: flex;
                gap: 8px;

                &>* {
                    width: 100%;
                    max-width: 147px;
                }
            }
        }
        
    }
    .kw-suggestions-datepicker {
        .date-ranges-dropdown {
            max-width: 100%;
            width: 100%;

            /deep/ .button-wrapper {
                width: 100%;
                .btn-group {
                    width: 100%;
                }
            }
        }

        /deep/ .custom_dropdown_body {
            &.align-right {
                transform: translate(50%, -30%);
            }
            &.bottom-right {
                right: 50%;
            }
        }
        
    }
</style>
