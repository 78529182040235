import client from '../client';
import interceptors from '../interceptors';
const projectsInterceptor = interceptors.get('projects');

client.interceptors.response.use(
    projectsInterceptor.s,
    projectsInterceptor.e,
);

const resource = '/groups';

const archiveData = {
    data: {
        type: 'group',
        attributes: { status: 2 },
    }
};

const projectId = id => encodeURIComponent(id);

export default {
    add: async payload => await client.post(resource, payload),
    archive: async id => await client.put(`${resource}/${projectId(id)}`, archiveData),
    unarchive: async id => await client.get(`${resource}/${projectId(id)}/undo/archive`),
    delete: async id => await client.delete(`${resource}/${projectId(id)}`),
    undelete: async id => await client.get(`${resource}/${projectId(id)}/undo/delete`),
    fetchActive: async () => await client.get(`${resource}/active`),
    fetchTopPages: async (id, payload) =>
        await client.get(`${resource}/${projectId(id)}/keywords/ranking-urls`, payload),
    fetchTopPagesForShareViewKeys: async (viewKey, payload) =>
        await client.get(`shared/${viewKey}/keywords/ranking-urls`, payload),
    fetchKeywords: async (id, payload) =>
        await client.get(`${resource}/${projectId(id)}/keywords`, payload),
    fetchKeywordsForShareViewKeys: async (viewKey, payload) =>
        await client.get(`shared/${viewKey}/keywords`, payload),
    fetchKeywordsForPdf: async (viewKey, payload) =>
        await client.get(`optimized-shared/${viewKey}/keywords`, payload),
    fetchQueueStats: async ({ id }, payload) =>
        await client.get(`${resource}/${projectId(id)}/queue`, payload),
    fetchQueueStatsForUser: async () => await client.get('all/queues'),
    fetchSuggestedRegions: async id => await client.get(`${resource}/${projectId(id)}/regions`),
    fetchSuggestedUrls: async id => await client.get(`${resource}/${projectId(id)}/urls`),
    fetchViewkey: async (auth, shortkey, payload) =>
        await client.get(`shared/${shortkey}?auth=${auth}`, payload),
    update: async (id, payload) => await client.patch(`${resource}/${projectId(id)}`, payload),

    fetchQueueStatsForShareViewkeys: async ({ shortkey, auth, password }, payload) =>
        await client.get(
            `/shared/${shortkey}/queue?auth=${auth}&password=${password ? password : ''}`,
            payload,
        ),

    addKeywords: async (id, formData, queryParameters) =>
        await client.post(`/groups/${projectId(id)}/keywords`, formData, queryParameters),
    addKeywordsToMultipleProjects: async (formData) => await client.post(`keywords/add-multi`, formData),
    
    fetchDeletedProjects: async payload => await client.get(`${resource}/deleted`, payload),
    fetchArchivedProjects: async payload => await client.get(`${resource}/archived`, payload),

    // viewKey APIs
    getHashForShare: async (projectId) => await client.get(`/projects/${projectId}/shared/links`),
    createHashForShare: async (projectId, payload) => await client.post(`/projects/${projectId}/shared/links`, payload),
    fetchTagDataForShare: async (hash) => await client.get(`/projects/shared/link/${hash}`),

    fetchViewkeyWithHash: async (hash, payload) => await client.get(`/projects/shared/link/${hash}/project`, payload),
    fetchKeywordsForShareViewKeysWithHash: async (hash, payload) => await client.get(`/projects/shared/link/${hash}/keywords`, payload),
    fetchTopPagesForShareViewKeysWithHash: async (hash, payload) => await client.get(`/projects/shared/link/${hash}/keywords/ranking-urls`, payload),
    fetchQueueStatsForShareViewkeysWithHash: async (hash) => await client.get(`/projects/shared/link/${hash}/queue`),
};
