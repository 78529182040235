<template>
    <div class="create-keywords-from-suggestions-modal">
        <div class="project-select">
            <h2 class="title">{{ $t('add-keywords') }} ({{ keywords.length }})</h2>

            <p class="select-label">{{ $t('select-project') }}</p>
            <div class="search-console-table__projects_select" >
                <projects-filter
                    :button-text="selectText"
                    :selected-projects="selected || []"
                    :check-callback="addProject"
                    :projects="projects"
                    :show-check-all="false"
                    :show-select-only="false"
                    :button-type="'select'"
                    @onClose="closeProjectsList"
                />
            </div>
        </div>
        
        <div class="url-container">
            <p class="url-label">{{ $t('enter-url') }} <span class="req">*</span></p>
            <div class="url-input-wrapper">
                <url-input
                    ref="urlInput"
                    :urlList="getSuggestedUrls(apiKeywordTypes)"
                    :domain="newKeyword.url_tracking_method"
                    :value="newKeyword.url"
                    @handleInput="handleInput"
                    @urlInputHandler="urlInputHandler"
                    @setDropItem="setDropItem"
                    :err="err"
                />
                <span
                    @mouseover="
                            tooltip({
                                text: 'Enter URL',
                                event: $event,
                            })
                    "
                    class="kw kw-info"
                />
            </div>  
            <p v-if="showUrlErrorMessage" class="url-error-msg">{{ this.err.url.text }}</p>
        </div>
        <div class="tags-container">
            <div class="tags-wrap">
                <label for="tags-input" class="tags-label">
                    <span>Tags</span>
                    <span
                        class="kw kw-info"
                        @mouseover="
                            tooltip({
                                text: $t('tags-tooltip'),
                                event: $event,
                            })
                        "
                    />
                    <span class="required-hint">{{ $t('optional') }}</span>
                </label>
                <div class="tags-input-wrap">
                    <span class="kw kw-delete"></span>
                    <vue-tags-input
                        id="tags-input"
                        v-model="tag"
                        data-cy="tags-input"
                        :autocomplete-items="filteredItems"
                        :tags="tags"
                        @tags-changed="updateTags"
                    />
                </div>
            </div>
        </div>

        <div class="kw-details-wrapper">
            <language-and-region-selector
                :region="newKeyword.region.value"
                :language="newKeyword.language.value"
                @selectLanguageValue="selectLanguageValue"
                @selectRegionValue="selectRegionValue"
                @selectRegionLabelValue="selectRegionLabelValue"
            />
            <div class="location">
                <add-locations
                    :add-location="addLocation"
                    autocomplete-type="web-domain-location"
                    :locations="newKeyword.geolocation.location"
                    ref-name="newKeywordLocation"
                    :remove-location="removeLocation"
                    :set-location="setLocation"
                />
            </div>
            <device-picker :value="newKeyword.platform" @setPlatform="setPlatform($event)" />
        </div>
        <div class="footer">
            <kw-button
                button-type="secondary"
                :button-text="$t('close')"
                class="view-removed-list"
                @click="close"
            />
            <kw-button
                :button-text="$t('add-keywords')"
                class="view-removed-list"
                @click="submit"
                :disabled="buttonDisabled"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { KEYWORD_SOURCES, MIXPANEL_EVENT_CONSTANTS } from "@/constants";
import ClickOutside from "vue-click-outside";
import VueTagsInput from '@johmun/vue-tags-input';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import LanguageAndRegionSelector from '@/views/add-keywords/components/LanguageAndRegionSelector.vue';
import {
    DEFAULT_REGION,
    DEFAULT_LANGUAGE
} from '@/constants';
import AddLocations from '@/components/add-keywords/AddLocations';
import AddKeywordsMixin from '@/mixins/AddKeywordsMixin';
import DevicePicker from '@/components/common/DevicePicker';
import URLInput from "../common/URLInput.vue";
import ValidationMixin from '@/mixins/ValidationMixin';
import ProjectsFilter from '@/components/filters/ProjectsFilter';
import { regionsForSelect, languagesForSelect } from '@/helpers/regions-service';

export default {
    name: "AddKeywordsByGSCModal",
    components: {
        'projects-filter': ProjectsFilter,
        'vue-tags-input': VueTagsInput,
        'language-and-region-selector': LanguageAndRegionSelector,
        'add-locations': AddLocations,
        'device-picker': DevicePicker,
        'url-input': URLInput
    },
    directives: {
        ClickOutside,
    },
    mixins: [ToolTipRenderMixin, AddKeywordsMixin, ValidationMixin],
    props: {
        activeTag: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        connection: {
            type: Object,
            required: true,
        },
        keywords: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selected: [],
            apiKeywordTypes: ['se', 'sem'],
            isProjectsListVisible: false,
            projects: [],
            tag: '',
            tags: [],
            newKeyword: {
                url: '',
                domain: false,
                region: {
                    name: null,
                    value: DEFAULT_REGION,
                },
                language: {
                    name: null,
                    value: DEFAULT_LANGUAGE,
                },
                geolocation: {
                    changeLocation: false,
                    location: [''],
                    ignoreLocal: true,
                    GMB: '',
                },
                url_tracking_method: 'broad',
                platform: window.localStorage.getItem('lastDevice') || 'desktop-mobile',
                keywords: []
            },
            err: {
                url: {},
                keywords: {},
            },
            urlChanged: false
        };
    },
    computed: {
        ...mapGetters([
            "getActiveProjects",
            "getCurrentProject",
            "getGSCProfileCurrentConnection",
            "getSuggestedUrls",
            "getSuggestedRegions"
        ]),
        selectText(){
            return this.selected?.length
                    ? this.selected.join(', ')
                    : this.$t('select-projects');
        },
        selectTriggerClasses() {
            return {
                "custom-dropdown custom-dropdown_projects": true,
                "custom-dropdown_active": this.isProjectsListVisible,
            };
        },
        filteredItems() {
            return this.presetTags.filter(el =>
                el.text.toLowerCase().includes(this.tag.toLowerCase()),
            );
        },
        presetTags() {
            const tags = [];
            this.getCurrentProject &&
                this.getCurrentProject.tags.forEach(tag => {
                    if (tag && !_.find(tags, ['text', tag]))
                        tags.push({ text: tag.name });
                });

            return tags;
        },
        buttonDisabled() {
            const isError = Object.keys(this.err).reduce(
                (acc, key) => (this.err[key].text ? true : acc),
                false,
            );
            return isError; 
        },
        showUrlErrorMessage() {
            return this.err.url.text && this.urlChanged;
        }
    },
    mounted(){
        this.setProjects();
    },
    methods: {
        ...mapActions(["addKeyword", "setKeywordsFromSuggestionsCreating"]),
        async submit(){
            const projectName = this.selected?.[0] || this.getCurrentProject.id;

            const projectId = this.selected.length > 0 ?
                this.getActiveProjects.reduce((acc,item) => {
                    if(this.selected.includes(item.name)){
                        acc.push(item.project_id);
                    }
                    return acc;
                }, []) : 
                [this.getCurrentProject.project_id];

            this.setKeywordsFromSuggestionsCreating(true);
            this.addKeyword({
                keyword: {
                    domain: this.newKeyword.domain,
                    geolocation: this.newKeyword.geolocation,
                    isBulkImport: false,
                    includedInSov: true,
                    isManualGmb: false,
                    language: this.newKeyword.language,
                    tags: this.tags,
                    platform: this.newKeyword.platform,
                    region: this.newKeyword.region,
                    keywords: this.keywords,
                    source: KEYWORD_SOURCES.GSC.value,
                    url: this.newKeyword.url,
                    url_tracking_method: this.newKeyword.url_tracking_method
                },
                type: "web",
                tagsAsPartOfKeyword: true,
                exact: this.newKeyword.url_tracking_method === 'exact',
                leading_source: MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.PROJECT_DASHBOARD,
                projectId
            })
            .then(() => {
                this.close();
                this.$router.push({ name: 'keywordList', params: { id: projectName }, query: {resetTab: true} });
            })
            .catch(error => {
                const errorData = error.response?.data;
                if (errorData?.errors) {
                    errorData.errors.forEach(err => {
                        if (err.detail?.includes('.attributes.url')) {
                            this.err.url.text = err.title;
                        } else if (err.message) {
                            this.$toastr.e(err.message);
                        }
                    })
                }
            })
            .finally(() => {
                this.setKeywordsFromSuggestionsCreating(false);
            });
        },
        closeProjectsList() {
            this.isProjectsListVisible = false;
        },
        addProject(project){
            if (Array.isArray(project)) {
                this.selected = this.selected === project.length
                    ? []
                    : project.map(el => el.fullName);
            }

            if (project.deleted) {
                return;
            }

            if (!Array.isArray(project)) {
            this.selected = this.selected.includes(project.fullName)
                    ? this.selected.filter(el => el !== project.fullName)
                    : [...this.selected, project.fullName];
            }
        },
        toggleProjectsList(){
            this.isProjectsListVisible = !this.isProjectsListVisible;
        },
        setProjects () {
            this.projects.length = 0;
            this.projects = this.getActiveProjects
        },
        updateTags(newTags) {
            this.tags = newTags;
        },
        selectLanguageValue(value) {
            this.newKeyword.language.value = value;
        },
        selectRegionValue(value) {
            if (value) {
                this.newKeyword.region.value = value;
            }
            const selectedRegion = this.googleRegions(
                this.getSuggestedRegions,
                regionsForSelect,
            ).find(region => region.value === value);
            this.newKeyword.region.value = value;

            if (
                selectedRegion &&
                selectedRegion.details &&
                selectedRegion.details.hl.length
            ) {
                this.newKeyword.language.value =
                    languagesForSelect.filter(
                        el =>
                            el.value.indexOf(
                                selectedRegion.details.hl.toLowerCase(),
                            ) === 0,
                    )[0].value || DEFAULT_LANGUAGE;
            } else {
                this.newKeyword.language.value = DEFAULT_LANGUAGE;
            }
        },
        selectRegionLabelValue(label) {
            this.newKeyword.region.name = label[0];
        },
        setPlatform(platform) {
            this.newKeyword.platform = platform;
            window.localStorage.setItem('lastDevice', platform);
        },
        close() {
            this.$emit("close");
        },
        urlInputHandler() {
            this.err.url = this.urlValidator(this.newKeyword?.url ?? '');
            const str = this.getRegionDomain(this.newKeyword.url);

            if (str) {
                const regions = regionsForSelect.filter(
                    el => str === el.value.replace('google.', ''),
                );
                const region = !regions.length
                    ? regionsForSelect.find(el => el.value === 'google.com')
                    : regions.length > 1
                    ? regions[1]
                    : regions[0];

                this.selectRegionValue(region.value);
                this.selectRegionLabelValue(region.name);
            }
        },
        setDropItem(value) {
            this.newKeyword.url_tracking_method = value;
        },
        handleInput(value) {
            this.urlChanged = true;
            this.newKeyword.url = value;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~sass/modal";

.create-keywords-from-suggestions-modal {
    font-family: Roboto;
    padding-top: 4px;

    /deep/ .domain-select-wrap {
        .btn-group {
            width: 100%;
        }
    }

    .url-error-msg {
        color: kw-color(kw-red, 1);
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 4px;
        margin: 0;
    }

    .kw-info {
        color: kw-color(kw-light-grey, 5);
        font-weight: 900;
        font-size: 12px;
        margin-left: 4px;
    }

    .project-select {
        width: 100%;
        position: relative;
        padding: 20px;
        margin-top: 0!important;

        h2.title {
            font-size: 24px;
            font-weight: 500;
            line-height: 20px;
            margin: 0 0 16px;
        }

        .select-label {
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 4px;
            span {
                font-weight: 400;
                color: kw-color(kw-light-grey, 9);
            }
        }

        .custom-dropdown {
            width: 100%;
            font-weight: 400;
            font-size: 13px;
            line-height: 15px;
            border-color: kw-color(kw-white, 5) !important;
        }
       
        .search-console-table__projects_select {
            position: relative;
            min-width: 240px;
            width: 100%;

            .custom-dropdown_projects {
                min-width: 240px;
                text-align: left;
                width: 100%;
                color: black;
            }
            .projects-list {
                margin: 0;
                z-index: 99;
            }
        }
    }
    .url-container {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 20px;
        .url-label {
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            margin-bottom: 3px;
            .req {
                color: kw-color(kw-red, 1);
                margin-left: -2px;
            }
        }
        .url-input-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 9px;
            & > div {
                flex: 1;
            }
        }
    }
    .tags-container {
        padding: 0 20px;

        /deep/ .vue-tags-input{
            max-width: 100%;
            width: 100%;
            .ti-input{
                border-color: kw-color(kw-white, 5);
                border-radius: 4px;
                min-height: 36px;
                .ti-tag {
                    background: #f5f6f7;
                    border: 1px solid #cbd6e9;
                    box-sizing: border-box;
                    border-radius: 4px;
                    color: #265596;
                }
            }
            
        }
        .tags-label {
            font-weight: 500;
            font-size: 13px;
            .required-hint {
                color: kw-color(kw-light-grey, 9);
                text-transform: capitalize;
                margin-left: 4px;
            }
        }  
    }
    .kw-details-wrapper {
        padding: 20px;
        margin-top: 20px;
        border-top: 1px solid kw-color(kw-light-grey, 1);
        border-bottom: 1px solid kw-color(kw-light-grey, 1);
        /deep/ .region-language-wrap {
            gap: 10px;
            .screen__field {
                max-width: unset;
                flex: 1;
            }
            .divider {
                display: none;
            }
            .screen__field_keyword .dropdown-region-lang {
                max-width: 232px !important;;
            }
            .form-control.dropdown-toggle {
                border-color: kw-color(kw-white, 5)!important;
            }
        }
        .location {
            margin-bottom: 14px;
            .location-wrap {
                padding: 0;
                border-top: none;
            }
            /deep/ .input-wrapper {
                input.text-input {
                    border-color: #DEE5EC;
                }
            }
            /deep/ button.kw-button.kw-btn-text {
                margin-left: 0;
                margin-top: -8px;
            }
        }
    }
    .footer {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        padding: 20px 24px;
        .kw-button {
            height: 40px;
        }
    }
    /deep/ .radio > label > input:focus ~ .icon {
        box-shadow: none;
        border-color: kw-color(kw-blue, 1);
    }

    /deep/ .open > .btn-primary.dropdown-toggle {
        background-color: kw-color(kw-blue, 1);
    }

    /deep/ .radio {
        & > label > input:focus ~ .icon {
            box-shadow: none;
            border-color: kw-color(kw-blue, 1);
        }
        &:not(.active) > label > .icon {
            background-color: kw-color(kw-white, 1);
            border: 2px solid #AFBAC7;
        }
    }
    .tags-input-wrap {
        position: relative;
        .kw-delete{
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 8px;
            transform: translateY(-50%);
            font-size: 16px;
            color: kw-color(kw-grey, 3);
        }
        /deep/ .ti-input {
            padding-left: 26px;
        }
    }
    .project-select {
      width: 100%;
      position: relative;
      margin-top: 32px;
      z-index: 99;

      label {
        font-family: Roboto;
        font-size: 13px;
        font-weight: 500;
        span {
          font-weight: 400;
          color: kw-color(kw-light-grey, 9);
        }
      }
      /deep/ .projects-filter {
        .checkbox {
          margin-left: 8px;
          position: relative;
          top: 0;
        }
        .projects-filter__popup {
          margin-top: 6px;
          width: 100%;
          box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
        }
      }
    }
}
</style>
