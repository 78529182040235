<template>
  <div class="notifications-wrapper">
    <h2>{{ $t('email-notifications') }}</h2>

    <div class="settings">
        <div v-if="loading" class="loading-screen">
          <loader
            :size="40"
          />
        </div>
        <config-toggle
          :title="$t('weekly-project-portfolio-snapshot.title')"
          :checked="settings.portfolio_snapshot_email_enabled"
          @onToggle="value => onToggleSettings(value, 'portfolio_snapshot_email_enabled')"
        >
          {{$t('weekly-project-portfolio-snapshot.desc')}}
        </config-toggle>

        <config-toggle
          :title="$t('weekly-project-performance-pulse.title')"
          :checked="settings.performance_pulse_email_enabled"
          @onToggle="value => onToggleSettings(value, 'performance_pulse_email_enabled')"
        >
          {{$t('weekly-project-performance-pulse.desc')}}

          <div class="project-select" v-if="settings.performance_pulse_email_enabled">
            <label>
              {{ $t('project-s') }}
              <span v-text="$t('multiple-can-be-selected')" />
            </label>
            <projects-filter
              :button-text="selectText"
              :selected-projects="selected || []"
              :check-callback="addProject"
              :projects="getActiveProjects"
              :show-check-all="false"
              :show-select-only="false"
              :button-type="'select'"
              @onClose="onSubmit"
            />
          </div>
        </config-toggle>
    </div>
   </div>
</template>

<script>
  import  {mapGetters, mapActions } from 'vuex';
  import ConfigToggle from './components/ConfigToggle.vue';
  import ProjectsFilter from '@/components/filters/ProjectsFilter';

  export default {
    name: 'SettingsNotifications',
    components: {
      'config-toggle': ConfigToggle,
      'projects-filter': ProjectsFilter,
    },
    data(){
      return {
        selected: [],
        settings: {
          portfolio_snapshot_email_enabled: true,
          performance_pulse_email_enabled: false,
        },
        loading: false,
      }
    },
    computed: {
      ...mapGetters([
          'getActiveProjects',
          'getEmailNotificationSettings'
      ]),
      selectText(){
        return this.selected?.length
                ? this.selected.join(', ')
                : this.$t('select-projects');
      }
    },
    mounted(){
      if(!this.getEmailNotificationSettings?.isFetched) {
        this.loading = true;
        this.fetchEmailNotificationSettings().finally(()=>{
          this.loading = false;
          
          this.initData();
        });
      } else {
        this.initData();
      }
    },
    methods: {
      ...mapActions([
        'updateEmailNotificationSettings',
        'fetchEmailNotificationSettings'
      ]),
      addProject(project){
        if (Array.isArray(project)) {
            this.selected = this.selected === project.length
                ? []
                : project.map(el => el.fullName);
        }

        if (project.deleted) {
            return;
        }

        if (!Array.isArray(project)) {
          this.selected = this.selected.includes(project.fullName)
                ? this.selected.filter(el => el !== project.fullName)
                : [...this.selected, project.fullName];
        }
      },
      onToggleSettings(value, settingKey){
        this.settings[settingKey] = value;
        if(settingKey === 'performance_pulse_email_enabled' && !value) {
          this.selected = [];
        }
        this.updateEmailNotificationSettings({[settingKey]: value});
      },
      onSubmit(){
        this.updateEmailNotificationSettings({
          performance_pulse_email_enabled: true,
          project_list: this.getActiveProjects.reduce((acc, project) => {
            if(this.selected.includes(project.name)){
              acc.push(project.project_id);
            }
            return acc;
          }, [])
        })
      },
      initData(){
        const {portfolio_snapshot_email_enabled, performance_pulse_email_enabled, project_list} = this.getEmailNotificationSettings;
        this.settings = { portfolio_snapshot_email_enabled, performance_pulse_email_enabled };

        this.selected = this.getActiveProjects.reduce((acc, project) => {
          if(project_list.includes(project.project_id)){
            acc.push(project.name);
          }
          return acc;
        }, [])
      }
    }
  }
</script>

<style lang="scss" scoped>
  .notifications-wrapper {
    width: 100%;
    background-color: kw-color(kw-white, 1);
    padding: 24px;
    border-radius: 4px;

    h2 {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin: 0 0 24px;
    }
    .settings {
      position: relative;
      width: 100%;
      max-width: 600px;
      min-height: 300px;
      /deep/ .config-wrapper {
        margin-bottom: 32px;
      }

      @media screen and (max-width: 800px) {
        flex-direction: column;
        gap: 0;
      }

      .loading-screen {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(4px);
        background-color: rgba(224, 224, 224, 0.4);
        z-index: 5;
      }
    }

    .project-select {
      width: 100%;
      position: relative;
      margin-top: 32px;
      max-width: 430px;

      label {
        font-family: Roboto;
        font-size: 13px;
        font-weight: 500;
        span {
          font-weight: 400;
          color: kw-color(kw-light-grey, 9);
        }
      }
      /deep/ .projects-filter {
        .checkbox {
          margin-left: 8px;
          position: relative;
          top: 0;
        }
        .projects-filter__popup {
          margin-top: 6px;
          width: 100%;
          box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
</style>