import i18n from '@/i18n';

const domainOptions = [
    {
        description: i18n.t('all-subdomains-and-all-pages'),
        name: '*.domain.com/*',
        question: 'https://support.keyword.com/en/articles/3347467-adding-editing-keywords',
        value: false,
    },
    {
        description: i18n.t('exact-url'),
        name: 'URL',
        question: 'https://support.keyword.com/en/articles/3347467-adding-editing-keywords',
        value: true,
    },
];

const newDomainOptions = [
    {
        name: i18n.t('broad-domain'),
        value: 'broad',
    },
    {
        name: i18n.t('exact-url'),
        value: 'exact',
    },
];

const platformOptions = [
    {icon: true, class: 'kw kw-airplay', name: i18n.t('desktop'), value: 'desktop', customOrder: 2},
    {icon: true, class: 'kw kw-smartphone', name: i18n.t('mobile'), value: 'mobile', customOrder: 3},
    {icon: true, class: 'kw kw-desktop-mobile', name: `${i18n.t('both')}`, value: 'desktop-mobile', customOrder: 1},
];

export {domainOptions, platformOptions, newDomainOptions};
