<template>
    <div class="kw-column">
        <span v-if="params.data.isfav" class="kw kw-favorite"></span>
        <div class="flag-wrapper">
            <flag
                :country="params.data.flag.image"
                :tooltip="params.data.flag.tooltip"
                :tooltip-callback="tooltip"
            />
        </div>
        <div
            :class="iconClasses"
            :data-important-tooltip="$t(`${params.data.type}-ranking`)"
        />
        <div
            v-if="params.data.near"
            class="kw kw-map-pin"
            data-cy="keyword-location-tag"
            :data-important-tooltip="$t('location', { near: params.data.near })"
        />
        <div
            v-if="!params.data.ignore_local"
            class="kw-icons kw kw-home"
            data-cy="keyword-not-ignore-local"
            :data-important-tooltip="$t('ignore-local-tooltip', { gmb: params.data.gmb ? params.data.gmb : 'not specified' })"
        />
        <div
            class="keyword-value"
            :class="{ 'with-location': params.data.near }"
            :data-tooltip="params.data.fullKw"
            :data-tooltip-allow-html="'false'"
        >{{ params.value }}</div>

        <div class="table-cell__actions">
            <div class="actions-class__action-container">
                <kw-button
                    v-if="isViewKey != false"
                    :button-disabled="isSearch"
                    :append-icon-class="
                        params.data.isfav ? 'kw kw-favorite' : 'kw kw-iconsstar'
                    "
                    button-type="text"
                    :data-important-tooltip="
                        params.data.isfav ? $t('unfav-kw') : $t('fav-kw')
                    "
                    @click.stop="
                        toggleKeywordFavorite(
                            {
                                keywords: [params.data.id],
                                action: !params.data.isfav,
                            },
                            params.data.isfav,
                        )
                    "
                />
            </div>
            <div v-if="showSerpHistoryButton" class="actions-class__action-container" @mouseover="tooltip({ text: $t('serp-history'), event: $event  })">
                <kw-button
                    append-icon-class="kw kw-clock"
                    data-cy="show-serp-history"
                    button-type="text"
                    @click.stop="showSerpHistory(params.data)"
                />
            </div>
            <div class="actions-class__action-container">
                <kw-button
                    append-icon-class="kw kw-charts"
                    :button-active="getShownChart.keyword === params.data.auth"
                    data-cy="show-historical-chart"
                    button-type="text"
                    @mouseover="tooltip({ text: $t('view-charts'), event: $event  })"
                    @click.stop="toggleShowSingleChart(params.data)"
                />
            </div>
            <div v-if="showSpyglassLink" class="actions-class__action-container">
                <a
                    :data-important-tooltip="$t('see-in-search-results')"
                    :href="spyglassLink"
                    target="_blank"
                    :test="params.data.grank"
                    @click.stop
                >
                    <span class="kw kw-search" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import KwHistoricalChartModal from '@/components/modals/KwHistoricalChart';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import SerpHistoryModal from '@/components/main-table/components/SerpHistoryModal';
import { beforeModalOpen, beforeModalClose } from '@/helpers/modalHelpers';
import { detectIsSurferSEO } from '@/helpers';

export default {
    name: 'KwRenderer',
    mixins: [ToolTipRenderMixin],
    computed: {
        ...mapGetters([
            'getViewkeyView',
            'isViewOnlyUser',
            'getShownChart',
            'getKeywordFilters',
            'getCommonKeywordsChartIsShow',
            'getCurrentProject'
        ]),
        iconClasses() {
            const iconNames = {
                se: 'kw-airplay',
                sem: 'kw-smartphone',
                yt: 'kw-youtube',
                map: 'kw-image',
                location: 'kw-map-pin',
            };
            return {
                [`kw-icons kw ${iconNames[this.params.data.type]}`]: true,
            };
        },
        isViewKey() {
            return !this.getViewkeyView && !this.isViewOnlyUser;
        },
        isSearch() {
            return this.$route.name === 'searchResults';
        },
        showSpyglassLink(){
            return !this.getCurrentProject?.user_is_restricted && !detectIsSurferSEO();
        },
        spyglassLink() {
            const keyword = this.params.data;
            let dateFilter = this.getKeywordFilters.keywordsDate;
            const rank = parseInt(keyword.grank, 10);
            const page = rank && rank < 100 ? parseInt(keyword.gpage, 10) : 1;

            let params = `?project=${keyword.project_id}&keyword=${keyword.id}&auth=${keyword.auth}`;
            if (!dateFilter) {
                let now = new Date();
                let day = now.getDate();

                if (day < 10) {
                    day = `0${day}`;
                }

                let month = now.getMonth() + 1;

                if (month < 10) {
                    month = `0${month}`;
                }

                let year = now.getFullYear();

                dateFilter = `${year}-${month}-${day}`;
            }

            params += `&date=${dateFilter}`;
            params +=
                !rank || isNaN(rank) ? '&unranked=true' : `&ranking=${rank}`;
            params += (keyword.is_paginated == 1 || keyword.is_paginated == 3) ? `&page=${page}` : '';

            return `${window.location.origin}/spyglass.html${params}`;
        },
        showSerpHistoryButton(){
            return this.params.data.type !== 'map';
        }
    },
    methods: {
        ...mapActions([
            'toggleKeywordsFavorite',
            'toggleCommonKeywordsChartIsShow',
            'setShownChart',
        ]),
        async toggleKeywordFavorite(options, isFavorite) {
            const message = isFavorite
                ? 'success-keywords-were-removed-from-favorites'
                : 'success-keywords-were-added-to-favorites';
            try {
                await this.toggleKeywordsFavorite(options);
                this.$toastr.s(this.$t(message));
            } catch (error) {
                this.$toastr.e(error);
            }
        },
        toggleShowSingleChart(kw) {
            if (this.getCommonKeywordsChartIsShow) {
                this.toggleCommonKeywordsChartIsShow();
            }

            if (this.getShownChart.keyword !== kw.id) {
                this.setShownChart({ keyword: kw.id, project: kw.category });
                this.showChart();
            } else {
                this.setShownChart({ keyword: null, project: null });
                this.$modal.hide('KwHistoricalChartModal');
            }
        },
        showChart() {
            this.$modal.show(
                KwHistoricalChartModal,
                {
                    keyword: this.params.data,
                    toggleShowSingleChart: this.toggleShowSingleChart,
                },
                {
                    clickToClose: true,
                    height: 'auto',
                    name: 'KwHistoricalChartModal',
                    width: '90%',
                },
            );
        },
        showSerpHistory(){
            const keywords = [this.params.data];
            this.$modal.show(
                SerpHistoryModal,
                { keywords },
                {
                    draggable: '.custom-modal_draggable',
                    height: '90%',
                    name: 'AddNoteModal',
                    width: '90%',
                },
                {
                    'before-open': beforeModalOpen,
                    'before-close': beforeModalClose,
                },
            );
        }
    },
};
</script>

<style lang="scss" scoped>
.kw-column {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 15px;

    .kw-favorite {
        color: #f4a732;
        font-size: 8px;
        left: 3px;
        line-height: 8px;
        position: absolute;
        width: 8px;
        // top: 13px;
        z-index: 1;
        @media screen and (max-width: 959px) {
            font-size: 7px;
            top: 16px;
        }
    }

    .flag-wrapper {
        display: flex;
        margin-right: 4px;

        i {
            transform: scale(0.9) translateX(-0.5px) translateY(0.8px);
        }
    }

    .kw-icons,
    .kw-map-pin {
        font-size: 12px;
        margin-right: 3px;
    }

    .keyword-value {
        width: calc(100% - 44px);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .table-cell__actions {
        position: absolute;
        right: 0;
        margin-left: 5px;
        display: none;

        .actions-class__action-container {
            position: relative;

            &:not(:last-child) {
                margin-right: 5px;
            }

            /deep/.kw-clock {
                font-weight: 700;
            }
            /deep/ .kw-iconsstar{
                font-weight: 700;
            }

            /deep/ kw-btn-text,
            a,
            button {
                border: none;
                box-shadow: none;
                display: flex;
                height: 15px;
                padding: 0;
                margin-left: 0;

                span {
                    color: kw-color(kw-blue, 1) !important;
                    font-size: 13px;
                    line-height: 13px;
                    pointer-events: none;

                    &:hover {
                        color: #00a3ff !important;
                    }

                    &:focus {
                        outline: transparent;
                    }
                }
            }

            a:hover {
                text-decoration: none;
            }
        }

        .kw-search {
            margin-top: 1px;
            font-weight: 700;
        }
    }
}
</style>
