import client from '../client';
import interceptors from '../interceptors';
const accountInterceptor = interceptors.get('account');

client.interceptors.response.use(
    accountInterceptor.s,
    accountInterceptor.e,
);

const prepareData = payload => ({
    data: {attributes: payload}
});

const prepareDataIncludeSettings = payload => ({
    data: {attributes: payload},
    include: 'settings,subscriptions',
});

export default {
    login: async payload => await client.post('/login', prepareDataIncludeSettings(payload)),
    register: async (data, params) => client.post('users/register', data, { params }),
    refresh: async payload => await client.get('/token', payload),
    check: async payload => await client.get('/check-email', payload),
    sendToken: async payload => await client.post('/token', prepareData(payload)),
    checkCoupon: async (package_identity, payload) => await client.get(`/packages/${package_identity}`, payload),

    socialSignIn: async (provider, data, trial_period) => await client.post(`/social-login/${provider}`, data, {params: {trial_period}}),

    resetApiKey: async payload => await client.patch('users/reset-api-key', payload),
};
