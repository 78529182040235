<template>
    <div class="url-input-wrap" data-cy="url-input-web-wrap">
        <div class="protocol-type">
            <span>{{ $t('https') }}</span>
        </div>
        <vue-simple-suggest
            ref="suggestComponent"
            :value="value"
            :filter-by-query="true"
            :list="urlList"
            :min-length="0"
            :styles="autocompleteStyles"
            @input="urlInputHandler"
        >
            <input
                id="keyword-domain"
                ref="keywordDomain"
                :value="value"
                @input="handleInput($event)"
                autocomplete="off"
                :class="keywordDomainClasses"
                data-cy="add-keyword-web-url-input"
                :disabled="disabled"
                :placeholder="$t('domain-url')"
                type="text"
            />
            <div slot="suggestion-item" slot-scope="scope">
                <span v-html="boldenSuggestion(scope, $refs)" />
            </div>
        </vue-simple-suggest>
        <div class="domain-select-wrap">
            <custom-v-select
                id="domainSelect"
                options-class="class"
                options-label="name"
                options-value="value"
                select-type="domain"
                data-cy="domain-menu-add-keyword-web"
                :placeholder="$t('broad-domain')"
                :options="newDomainOptions"
                :value.sync="domain"
                dropdown-menu-data-cy="domain-menu-add-keyword-web-list"
                dropdown-search-data-cy="domain-menu-add-keyword-web-search"
                @change="setDropItem($event)"
            />
        </div>
    </div>
</template>

<script>
import VueSimpleSuggest from "vue-simple-suggest";
import {
  autocompleteStyles,
  boldenSuggestion
} from "../../views/add-keywords/utils";
import { newDomainOptions } from "../../views/add-keywords/configs";

export default {
  name: "URLInput",
  components: {
    "vue-simple-suggest": VueSimpleSuggest
  },
  data() {
    return {
      boldenSuggestion,
      autocompleteStyles,
      newDomainOptions
    };
  },
  props: {
    urlList: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    domain: {
      type: String,
      required: true
    },
    err: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String
    }
  },
  methods: {
    handleInput(event) {
      this.$emit('handleInput', event.target?.value);
    },
    setDropItem(value) {
      this.$emit('setDropItem', value);
    },
    urlInputHandler(value) {
      if (value !== this.value) {
        this.$emit('handleInput', value);
      }
      this.$emit('urlInputHandler');
    },
    onSelect(value){
      this.$refs.suggestComponent.select(value);
    }
  },
  computed: {
    keywordDomainClasses() {
      return {
        'text-input': true,
        'text-input_invalid': this.err.url?.text,
        'domain-filed': true
      };
    }
  }
};
</script>

<style scoped lang="scss">
.url-input-wrap {
  display: flex;
  flex-wrap: row wrap;
  border-radius: 4px;
  border: 1px solid #cecece;
  .text-input {
    width: 100%;
    border-radius: 0;
    &:not(.text-input_invalid) {
      border: unset;
      font-size: 13px;
      color: kw-color(kw-black, 1);

      &:disabled {
        border-radius: unset;
        border: unset !important;
      }
    }
  }
  
  .protocol-type {
    display: flex;
    align-items: center;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    justify-content: center;
    height: 36px;
    color: #909399;
    font-size: 13px;
    border-right: 1px solid #dee5ec;
    max-width: 75px;
    width: 100%;
  }
}
.domain-select-wrap {
  max-width: 140px;
  min-width: 130px !important;
  width: 100%;
  position: relative;
  border-left: 1px solid #dee5ec !important;
  background-color: kw-color(kw-white, 1);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  .new-custom-dropdown-button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    width: 100%;
    height: 100%;
    padding-left: 10px !important;
    margin-left: 0 !important;
    justify-content: flex-start !important;
    color: kw-color(kw-black, 1) !important;

    li.active {
      color: kw-color(kw-blue, 1) !important;
    }
  }

  @media screen and (max-width: 959px) {
    min-width: 136px;
    z-index: 3;
  }
  .btn-group {
    height: 100% !important;
    /deep/.dropdown-toggle {
      height: 100% !important;
      border: none !important;
      .btn-content {
        color: kw-color(kw-black, 1);
      }
      &::after {
        top: 45% !important;
      }
    }
  }
}
</style>
