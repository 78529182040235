<template>
    <div
        ref="select"
        v-click-outside="close"
        :class="classes"
    >
        <div
            ref="btn"
            class="form-control dropdown-toggle"
            tabindex="1"
            :disabled="disabled || !hasParent"
            :readonly="readonly"
            :data-cy="dataCy"
            @blur="canSearch ? null : close()"
            @click="toggle()"
            @keydown.esc.stop.prevent="close"
            @keydown.space.stop.prevent="toggle"
            @keydown.enter.stop.prevent="toggle"
        >
            <slot
                name="toggler"
                :selected="selected"
            >
                <span
                    :class="{'btn-content': true, 'is-placeholder': showPlaceholder}"
                    v-html="loading ? text.loading : showPlaceholder || (multiple && showCount ? selectedText : selected)"
                />
                <span
                    v-if="clearButton&&values.length"
                    class="close"
                    @click="clear()"
                >
                    &times;
                </span>
            </slot>
        </div>
        <select
            ref="sel"
            v-model="val"
            :name="name"
            class="secret"
            :multiple="multiple"
            :required="required"
            :readonly="readonly"
            :disabled="disabled"
        >
            <option
                v-if="required"
                value=""
            />
            <option
                v-for="(option, key) in list"
                :key="key"
                :value="option[optionsValue]"
            >
                {{ option[optionsLabel] }}
            </option>
        </select>
        <div class="dropdown-menu">
            <template v-if="list.length">
                <div
                    v-if="canSearch"
                    class="bs-searchbox"
                >
                    <span class="kw kw-search" />
                    <input
                        :id="`search-${selectType}`"
                        ref="search"
                        v-model="searchValue"
                        type="text"
                        :placeholder="searchText||text.search"
                        class="form-control bs-searchbox-input"
                        autocomplete="off"
                        :data-cy="dropdownSearchDataCy"
                        @keyup.esc="close"
                    >
                    <span
                        v-show="searchValue"
                        class="close"
                        data-cy="add-clear-search"
                        @click="clearSearch"
                    >
                        &times;
                    </span>
                </div>
                <ul
                    class="dropdown-list"
                    :data-cy="dropdownMenuDataCy"
                >
                    <li v-if="required&&!clearButton"><a @mousedown.prevent="clear() && close()">{{ placeholder || text.notSelected }}</a></li>
                    <li
                        v-for="(option, key) in filteredOptions"
                        :id="option[optionsValue]"
                        :key="key"
                        :ref="isSelected(option[optionsValue]) ? 'selectedOption' : ''"
                        :class="{ selectedOption: isSelected(option[optionsValue]), disabledOption: option.disabled }"
                    >

                        <a @mousedown.prevent="select(option[optionsValue], option.disabled)">
                            <slot
                                name="option"
                                :option="option.label"
                            >
                                <span
                                    :class="`${isSelected(option[optionsValue]) ? 'checked-item' : ''}`"
                                    v-html="option[optionsLabel]"
                                />
                                <span
                                    v-if="isSelected(option[optionsValue])"
                                    class="glyphicon glyphicon-ok check-mark"
                                />
                            </slot>
                        </a>
                    </li>
                </ul>
            </template>
            <slot />
            <transition
                v-if="notify && !closeOnSelect"
                name="fadein"
            >
                <div class="notify in">{{ limitText }}</div>
            </transition>
        </div>
        <transition
            v-if="notify && closeOnSelect"
            name="fadein"
        >
            <div class="notify out"><div>{{ limitText }}</div></div>
        </transition>
        <!-- <pre>Options: {{list}}</pre> -->
    </div>
</template>

<script>
    import vSelect from 'vue-strap/src/Select';
    export default  {
        extends: vSelect,
        props: {
            searchKey: {
                type: String,
                default: 'value'
            },
            selectType: {
                type: String,
                required: false,
                default: '',
            },
            dataCy: {
                type: String,
                required: false,
                default: '',
            },
            dropdownSearchDataCy: {
                type: String,
                required: false,
                default: '',
            },
            dropdownMenuDataCy: {
                type: String,
                required: false,
                default: '',
            },
            disableDeSelect: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        computed: {
            filteredOptions () {
                const specialChars = /[.+*?^$|()[\]{}\\]/g;
                const search = this.searchValue;
                return !search ? this.list : this.list.filter(el => {
                    return el[this.searchKey].toLowerCase().search(search.replace(specialChars, "\\$&").toLowerCase()) > -1;
                })
            },
        },
        watch: {
            show (val) {
                if (val && this.canSearch) {
                    this.clearSearch();
                    const searchInput = document.getElementById(`search-${this.selectType}`);

                    this.$nextTick(() => {
                        if (searchInput && this.$refs.search) {
                            this.$refs.search.focus();
                        }
                    });
                    // onBlur(this.$refs.select, e => { this.show = false })
                } else {
                    // offBlur(this.$refs.select)
                }

                if (this.$refs.selectedOption && this.$refs.selectedOption.length) {
                    setTimeout(() => {
                        if (!this.$refs.selectedOption[0]) {
                            return;
                        }

                        const parent = this.$refs.selectedOption[0].parentElement;
                        const scroll = this.$refs.selectedOption[0].offsetTop - (parent.parentElement.clientHeight / 2);
                        parent.scrollTop = scroll;
                    });
                }
            },
        },
        methods: {
            select (v, disabled) {
                if(disabled) return;
                if (this.val instanceof Array) {
                    const newVal = this.val.slice(0);

                    if (~newVal.indexOf(v)) {
                        newVal.splice(newVal.indexOf(v), 1);
                    } else {
                        newVal.push(v);
                    }

                    this.val = newVal;

                    if (this.closeOnSelect) {
                        this.toggle();
                    }
                } else {
                    if(this.disableDeSelect && this.val === v) {
                        this.toggle();
                        return;
                    }
                    this.val = this.val === v ? null : v;
                    this.toggle();
                }
            },
        },

    }
</script>

<style lang="scss" scoped>
    select.secret {
        display: none;
    }
    .dropdown-menu {
        overflow: hidden !important;
        padding: 0 0 8px 0 !important;
        .bs-searchbox {
            margin: 0;
            padding: 12px 8px 4px;
            position: relative;
            .kw-search {
                position: absolute;
                left: 18px;
                top: 22px;
                font-size: 16px;
                color: #828F9C;
                font-weight: bold;
            }
            .bs-searchbox-input[type=text]{
              text-indent:20px;
            }
        }
        .close {
            position: absolute;
            right: 18px;
            top: 20px;
        }
    }
    .dropdown-list {
        @extend .custom-scroll;
        max-height: 280px;
        list-style-type: none;
        overflow-y: auto;
        margin: 0;
        padding: 0;
        .disabledOption {
            pointer-events: none;
            opacity: 0.5;
        }
    }
    
</style>
