<template>
    <div class="table-wrapper">
        <slot />
        <ag-grid-vue
            :style="componentStyle"
            class="ag-theme-alpine"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            suppressRowClickSelection
            :gridOptions="gridOptions"
            :rowData="rowData"
            :columnTypes="columnTypes"
            @cell-value-changed="$event => $emit('onCellValueChanged', $event)"
        >
        </ag-grid-vue>
    </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';

export default {
    name: 'NewTable',
    components: {
        AgGridVue,
    },
    props: {
        columnDefs: {
            type: Array,
            required: true,
        },
        defaultColDef: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
        gridOptions: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
        rowData: {
            type: Array,
            required: true,
        },
        componentStyle: {
            type: String,
            default: 'width: 100%; height: 100%;',
        },
        columnTypes: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style scoped lang="scss">
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';
.table-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.5%;
    padding: 1%;
    width: 88%;
    height: 446px;
    background: #f8f8f9;
    border-radius: 4px;
}
</style>
