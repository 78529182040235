<template>
    <div class="region-language-wrap">
        <div class="screen__field screen__field_keyword screen__field_select-region">
            <label class="text-label" for="region">
                <span>{{ $t('region') }}</span>
                <span class="kw kw-info"
                    @mouseover="tooltip({
                    text: $t('region-tooltip'),
                    event: $event,
                    })"
                />
            </label>
            <custom-v-select
                id="region"
                :class="{'dropdown-region-lang': true, 'custom-dropdown_filled': region}"
                data-cy="add-keyword-web-region"
                dropdown-search-data-cy="region-search-field"
                dropdown-menu-data-cy="parent-dropdown-menu"
                :options="googleRegions(getSuggestedRegions,regionsForSelect)"
                options-label="name"
                select-type="region"
                options-value="value"
                :placeholder="$t('region-tooltip')"
                :value.sync="region"
                search
                @change="selectRegion"
                @selected="selectRegionLabel"
            />
        </div>
        <span class="divider" />
        <div class="screen__field screen__field_keyword screen__field_select-language">
            <label class="text-label" for="language">
                <span>{{ $t('google-language') }}</span>
                <span class="kw kw-info"
                    @mouseover="tooltip({
                    text: $t('language-tooltip'),
                    event: $event
                    })"
                />
            </label>
            <custom-v-select
                id="language"
                class="dropdown-region-lang"
                data-cy="add-keyword-web-language"
                dropdown-search-data-cy="language-search-field"
                dropdown-menu-data-cy="language-dropdown-menu"
                options-label="name"
                options-value="value"
                search-key="name"
                select-type="language"
                :placeholder="$t('language-tooltip')"
                search
                :class="{'custom-dropdown_filled': language}"
                :options="googleLanguages(getSuggestedRegions,languagesForSelect)"
                :value.sync="language"
                @change="selectLanguage"
            />
        </div>
    </div>
</template>

<script>
import {
    regionsForSelect,
    languagesForSelect,
} from '@/helpers/regions-service';
import { mapGetters } from 'vuex';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import AddLocations from '@/components/add-keywords/AddLocations';
import AddKeywordsMixin from '@/mixins/AddKeywordsMixin';

export default {
    name: 'LanguageAndRegionSelector',
    mixins: [AddKeywordsMixin, ToolTipRenderMixin],
    props: {
        region: {
            type: String,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    components: {
        'add-locations': AddLocations,
    },
    data() {
        return {
            regionsForSelect,
            languagesForSelect
        };
    },
    computed: {
        ...mapGetters(['getSuggestedRegions']),
    },
    methods: {
        selectLanguage(value) {
            this.$emit('selectLanguageValue', value);
        },
        selectRegion(value) {
            this.$emit('selectRegionValue', value);
        },
        selectRegionLabel(label) {
            this.$emit('selectRegionLabelValue', label[0]);
        },
    },
};
</script>

<style lang="scss">
.region-language-wrap {
    height: 56px;
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .dropdown-region-lang{
        width: 100%;
    }

    .screen__field {
        max-width: 224px;
        padding: unset;
        height: 100%;
        width: 100%;

        @media screen and (max-width: 600px) {
            max-width: 49%;
        }
    }

    .divider {
        display: block;
        width: 12px;
        height: 1px;
        background: #dee5ec;
        position: relative;
        top: 30%;
    }

    .kw-info {
        font-weight: 900;
        font-size: 12px;
        color: kw-color(kw-light-grey, 5);
        margin-left: 5px;
    }
}
</style>
