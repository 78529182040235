<template>
    <div v-if="isAdminOrMasterUser" class="import-data-wrapper">
        <!-- <div class="import-data-header">
            <h3 class="title">{{ $t('import-data') }}</h3>
        </div> -->
        <div v-if="importInProgress" class="import-progress">
            <h4>
                {{
                    $t('import-progress', {
                        total: getTotal,
                        imported: getImported,
                    })
                }}
            </h4>
            <progress-bar
                dataCy="import-progress-bar"
                :all="getTotal"
                :fill="getImported"
            />
        </div>
        <template v-else>
            <div class="import-data-secondary-header">
                <p>{{ $t('import-csv-msg') }} <a href="http://support.keyword.com/en/articles/8030769-import-your-keywords-from-csv" target="_blank">Learn More</a></p>
                <div class="btn-group">
                    <kw-button
                        v-for="i in IMPORT_DATA_STEPS"
                        :key="`button-${i}`"
                        :button-type="buttonClasses(i)"
                        :buttonDisabled="currentStep !== i"
                        :button-text="$tc('import-data-step', i - 1)"
                        :data-cy="`import-data-step-${i}-button`"
                        @click="changeCurrentStep(i)"
                    />
                </div>
            </div>
            <file-upload
                v-show="currentStep === IMPORT_DATA_STEPS.STEP_1"
                @step-up="setHeaders"
                @step-down="stepDown"
            />
            <map-headings
                v-if="renderComponent(IMPORT_DATA_STEPS.STEP_2)"
                v-show="currentStep === IMPORT_DATA_STEPS.STEP_2"
                :headings="headers"
                :fileName="fileName"
                :totalRowsCount="totalRowsCount"
                @step-up="stepUp"
                @step-down="stepDown"
            />
            <validate-rows
                v-if="renderComponent(IMPORT_DATA_STEPS.STEP_3)"
                v-show="currentStep === IMPORT_DATA_STEPS.STEP_3"
                :activityLogId="activityLogId"
                :fileName="fileName"
                :totalRowsCount="totalRowsCount"
                @step-down="stepDown"
            />
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Events, EventBus } from '@/events';
import { IMPORT_DATA_STEPS, MIXPANEL_EVENT_CONSTANTS } from "@/constants";
import FileUpload from './components/FileUpload';
import MapHeadings from './components/MapHeadings';
import ValidateRows from './components/ValidateRows';
import ProgressBar from 'components/left-sidebar/components/ProgressBar';

export default {
    name: 'ImportData',
    components: { FileUpload, MapHeadings, ValidateRows, ProgressBar },
    computed: {
        ...mapGetters(['isAdminOrMasterUser', 'getImportActivityLogData']),
        importInProgress() {
            return (
                this.getImportActivityLogData &&
                this.getImportActivityLogData.total !=
                    this.getImportActivityLogData.imported
            );
        },
        getTotal() {
            return this.getImportActivityLogData?.total ?? 0;
        },
        getImported() {
            return this.getImportActivityLogData?.imported ?? 0;
        },
    },
    data() {
        return {
            IMPORT_DATA_STEPS,
            currentStep: IMPORT_DATA_STEPS.STEP_1,
            achievedStep: IMPORT_DATA_STEPS.STEP_1,
            headers: {},
            fileName: '',
            activityLogId: null,
            totalRowsCount: null,
        };
    },
    mounted() {
        EventBus.emit(Events.IMPORT_CSV_UPLOAD_VIEW_EVENT, {
            leading_source: MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.ACCOUNT,
        });
    },
    methods: {
        renderComponent(step) {
            return this.achievedStep >= step;
        },
        changeCurrentStep(step) {
            if (step <= this.achievedStep) {
                this.currentStep = step;
            }
        },
        stepUp(step) {
            this.achievedStep = step;
            this.changeCurrentStep(step);
        },
        stepDown({ step, cancellation }) {
            if (cancellation) {
                this.achievedStep = step;

                if (step === IMPORT_DATA_STEPS.STEP_1) {
                    this.headers = {};
                    this.fileName = '';
                }
            }

            this.changeCurrentStep(step);
        },
        setHeaders({ data, fileName }) {
            this.headers = data;
            this.fileName = fileName;
            this.activityLogId = data.import_activity_log_id;
            this.totalRowsCount = data.total;
            this.stepUp(IMPORT_DATA_STEPS.STEP_2);
        },
        buttonClasses(step) {
            return step > this.achievedStep
                ? 'secondary'
                : step === this.currentStep
                ? 'primary'
                : 'primary-disabled';
        },
    },
};
</script>

<style lang="scss" scoped>
.import-data-wrapper {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;

    .import-data-header {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .title {
            display: flex;
            align-items: center;
            padding: 25px;
            background: #fcfcfc;
            box-shadow: 0px 2px 4px rgba(42, 70, 116, 0.05);
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 21px;
        }
    }
    .import-progress {
        max-width: 500px;

        /deep/ .progress-bar__used span {
            background: kw-color(kw-blue, 1) !important;
            border-radius: 12px;
            transition: width 2s linear;
        }
    }

    .import-data-secondary-header {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-top: 24px;
        & > p {
            font-family: Roboto;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            margin: 0;
        }
    }

    .btn-group {
        display: flex;
        gap: 9px;
        .kw-button {
            min-width: 180px;
            justify-content: flex-start;
            height: 40px;
        }
        @media (max-width: 500px) {
            flex-direction: column;
        }
    }
    /deep/ .table-wrapper {
        padding: 0;
    }
}
</style>
