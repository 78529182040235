<template>
    <div class="choose-wrapper">
        <p>{{ $t('choose-csv') }}</p>
        <div
            class="choose-file"
            @dragover="dragoverHandler"
            @dragleave="dragleaveHandler"
            @drop="dropHandler"
        >
            <img class="upload" src="@/assets/images/Upload.svg" />
            <span class="dnd-text">
                <span v-text="$t('drag-or-drop')" />
                <span v-text="$t('or')" />
            </span>
            <div v-if="uploading"><loader /></div>
            <div v-else class="upload-file">
                <label for="file_input">
                    <div v-if="dragging">{{ $t('drop-file-here') }}</div>
                    <div v-else>{{ $t('choose-file') }}</div>
                </label>
                <input
                    ref="file_input"
                    type="file"
                    name="file_input"
                    id="file_input"
                    class="hidden"
                    @change="uploadFile"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
            </div>
        </div>
        <div class="help-paragraph">
            <span class="help-csv" v-text="$t('help-csv')" />

            <span>
                <span v-text="$t('download-our')" />
                <span
                    class="pre-formatted"
                    v-text="$t('pre-formatted')"
                    @click="downloadCSVTemplate"
                />

                <span v-text="$t('here-and-follow')" />
                <span v-text="$t('column-headers-are-the-same')" />
            </span>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { IMPORT_DATA_STEPS } from '@/constants';
import { downloadCSVTemplate } from '@/helpers/downloads-service';

export default {
    name: 'FileUpload',
    data() {
        return {
            uploading: false,
            dragging: false,
        };
    },
    methods: {
        ...mapActions(['uploadImportDataFile']),
        downloadCSVTemplate,
        dragoverHandler(e) {
            e.preventDefault();

            if (!this.uploading) {
                this.dragging = true;
            }
        },
        dragleaveHandler() {
            if (!this.uploading) {
                this.dragging = false;
            }
        },
        dropHandler(e) {
            e.preventDefault();

            if (!this.uploading) {
                this.dragging = false;
                this.$refs.file_input.files = e.dataTransfer.files;
                this.uploadFile();
            }
        },
        async uploadFile(e) {
            if (!this.$refs.file_input.files[0]) {
                return;
            }

            try {
                this.$emit('step-down', {
                    step: IMPORT_DATA_STEPS.STEP_1,
                    cancellation: true,
                });
                this.uploading = true;

                const res = await this.uploadImportDataFile(
                    this.$refs.file_input.files[0],
                );

                if (res) {
                    this.$emit('step-up', res);
                }
            } catch (e) {
                return false;
            } finally {
                this.uploading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.choose-wrapper {
    display: flex;
    gap: 24px;
    margin-top: 32px;
    flex-direction: column;
    & > p {
        font-weight: 500;
    }

    .choose-file {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        gap: 13px;
        width: 100%;
        max-width: 350px;
        height: 220px;
        border: 1px dashed #c1c4cd;
        background-color: kw-color(kw-white, 1);

        .upload {
            width: 91.69px;
            height: 66.67px;
        }

        .dnd-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 13px;
        }
        .upload-file > label {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 106px;
            height: 32px;
            background: #0074d9;
            color: #ffffff;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            box-shadow: 0px 2px 5px rgba(50, 73, 98, 0.15),
                0px 0px 1px rgba(50, 73, 98, 0.2);
            border-radius: 4px;
        }
        @media (max-width: 500px) {
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        .upload-file > label:hover {
            background-color: kw-color(kw-blue, 6);
        }
    }

    .help-paragraph {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5px;
        font-family: 'Roboto';
        font-size: 13px;
        max-width: 653px;

        .help-csv {
            font-weight: 500;
        }

        .pre-formatted {
            color: #0074d9;
            cursor: pointer;

            @media (max-width: 500px) {
                display: flex;
                flex-direction: column;
            }
        }

        .pre-formatted:hover {
            opacity: 0.8;
        }
    }
}
</style>
