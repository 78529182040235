const scopes = {
    'google' : {
        'oauth2': 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        'analytics': 'https://www.googleapis.com/auth/analytics.readonly',
        'search-console': 'https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/userinfo.email',
    },
    'linkedin': 'openid email profile',
    'microsoft': 'user.read',
    'facebook': 'public_profile,email',
};

const INTEGRATED_SOCIAL_MEDIA_FOR_SIGN_IN = {
    'google': (service = 'oauth2') => {
        return process.env.MIX_GOOGLE_CLIENT_ID ? (
            'https://accounts.google.com/o/oauth2/auth?response_type=code&access_type=offline&approval_prompt=force' +
            `&redirect_uri=${encodeURIComponent(window.location.origin + '/social_auth.html')}` +
            `&client_id=${process.env.MIX_GOOGLE_CLIENT_ID}` +
            `&scope=${encodeURIComponent(scopes['google'][service])}` +
            `&state=google`
        ) : null;
    },
    // 'facebook': () => {
    //     return process.env.MIX_FACEBOOK_CLIENT_ID ? (
    //         'https://www.facebook.com/v18.0/dialog/oauth?response_type=code' +
    //         `&redirect_uri=${encodeURIComponent(window.location.origin + '/social_auth.html')}` +
    //         `&client_id=${process.env.MIX_FACEBOOK_CLIENT_ID}` +
    //         `&scope=${encodeURIComponent(scopes['facebook'])}` +
    //         `&state=facebook`
    //     ) : null;
    // },
    // 'linkedin': () => {
    //     return process.env.MIX_LINKEDIN_CLIENT_ID ? (
    //         'https://linkedin.com/oauth/v2/authorization?response_type=code' +
    //         `&redirect_uri=${encodeURIComponent(window.location.origin + '/social_auth.html')}` +
    //         `&client_id=${process.env.MIX_LINKEDIN_CLIENT_ID}` +
    //         `&scope=${encodeURIComponent(scopes['linkedin'])}` +
    //         `&state=linkedin`
    //     ) : null;
    // },
    'microsoft': () => {
        return process.env.MIX_MICROSOFT_CLIENT_ID ? (
            'https://login.microsoftonline.com/consumers/oauth2/v2.0/authorize?response_type=code&response_mode=query' +
            `&redirect_uri=${encodeURIComponent(window.location.origin + '/social_auth.html')}` +
            `&client_id=${process.env.MIX_MICROSOFT_CLIENT_ID}` +
            `&scope=${encodeURIComponent(scopes['microsoft'])}` +
            `&state=microsoft` +
            `&code_challenge=${process.env.MIX_MICROSOFT_CHALLENGE_CODE_SHA256}` +
            `&code_challenge_method=S256`
        ) : null;
    },
};

export const INTEGRATED_SOCIAL_MEDIA_NAMES = Object.keys(INTEGRATED_SOCIAL_MEDIA_FOR_SIGN_IN);

export const getSocialAuthLink = social => INTEGRATED_SOCIAL_MEDIA_FOR_SIGN_IN[social]();

export const getGoogleAuthLink = INTEGRATED_SOCIAL_MEDIA_FOR_SIGN_IN['google'];
