<template>
    <div class="select-platform-row" data-cy="select-platform-wrap">
        <button-group
          class="devices-row"
          :value="platform"
          type="primary"
          :buttons="false"
          @input="setPlatform($event)"
        >
            <radio
              v-for="option in getPlatformOptions"
              :key="option.value"
              :value="option.value"
              :selected-value="option.value"
              :disabled="option.disabled"
            >
              {{option.name}}
            </radio>
        </button-group>
    </div>
</template>

<script>
import { platformOptions } from '../../views/add-keywords/configs';
import Radio from 'vue-strap/src/Radio';
import buttonGroup from 'vue-strap/src/buttonGroup.vue';

export default {
  name: "SelectPlatform",
  components: {
    'radio': Radio,
    'button-group': buttonGroup,
  },
  data() {
    return {
      platformOptions
    };
  },
  props: {
    platform: {
      type: String,
      required: true
    },
    disableMobile: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setPlatform(value) {
      this.$emit('setPlatform', value);
    }
  },
  computed: {
    getPlatformOptions() {
      if (this.disableMobile) {
        return this.platformOptions.map(item => ({...item, disabled: item.value.includes('mobile')}));
      }
      return [...this.platformOptions].sort((a, b) => a.customOrder - b.customOrder);
    }
  }
};
</script>

<style scoped lang="scss">
.devices-row {
  display: flex;
  align-items: center;
  gap: 12px;

  /deep/.radio {
    margin: 0;
    &:not(.active) > label > .icon {
      background-color: kw-color(kw-white, 1);
      border-width: 2px;
      border-color: #AFBAC7;
    }
    label .icon {
      box-shadow: none !important;
      
    }
    & > label > input:focus ~ .icon {
      border-color: kw-color(kw-blue, 1);
    }
    .btn-primary.dropdown-toggle {
      background-color: kw-color(kw-blue, 1);
    }
  }
  .disabled {
    opacity: .7;
  }
}
</style>
