<template>
    <div
        class="ag-header-cell ag-header-cell-sortable ag-focus-managed"
        role="columnheader"
        tabindex="-1"
        :col-id="params.column.colId"
        :aria-colindex="params.column.instanceId"
        style="width: 100%"
        @click="onMenuClicked"
    >
        <div
            ref="eResize"
            class="ag-header-cell-resize"
            role="presentation"
        ></div>
        <div
            ref="eHeaderCompWrapper"
            class="ag-header-cell-comp-wrapper"
            role="presentation"
        >
            <div class="ag-cell-label-container" role="presentation">
                <div
                    ref="eLabel"
                    class="ag-header-cell-label"
                    role="presentation"
                >
                    <span
                        ref="eText"
                        class="ag-header-cell-text"
                        >{{ getHeaderName }}</span
                    >
                    <span
                        v-if="isGSCColumns(params.column.colDef.field)"
                        class="kw kw-information"
                        :data-important-tooltip="
                            $t(`i-${params.column.colDef.field}`)
                        "
                        @click.stop="
                            openNewTab(params.column.colDef.field.helperUrl)
                        "
                    />
                    <span
                        v-else-if="
                            params.column.colDef.field &&
                            !withoutIFields(params.column.colDef.field)
                        "
                        class="kw kw-information"
                        :data-important-tooltip="
                            $t(`i-${params.column.colDef.field}`)
                        "
                    />
                    <span
                        v-if="params.enableSorting"
                        class="ag-sort-indicator-container"
                        ref="eSortIndicator"
                    >
                        <span
                            v-if="params.column.isSortAscending()"
                            ref="eSortAsc"
                            class="ag-sort-indicator-icon ag-sort-ascending-icon"
                            ><span
                                class="ag-icon ag-icon-asc"
                                unselectable="on"
                                :data-important-tooltip="$t('sort-by-asc')"
                                role="presentation"
                            ></span
                        ></span>
                        <span
                            v-if="params.column.isSortDescending()"
                            ref="eSortDesc"
                            class="ag-sort-indicator-icon ag-sort-descending-icon"
                            ><span
                                class="ag-icon ag-icon-desc"
                                unselectable="on"
                                :data-important-tooltip="$t('sort-by-desc')"
                                role="presentation"
                            ></span
                        ></span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { KW_TABLE_COLUMNS } from '@/constants';
export default {
    computed: {
        ...mapGetters([
            'getViewkeyHasChildren',
            'getGlobalSearchQuery',
            'getCurrentCurrency',
            'getCurrentProject',
            'getShareViewHashData',
            'getProjectByName',
            'getOtherSetting',
            'getViewkeyView',
        ]),
        getHeaderName() {
            return (
                this.params.displayName +
                (this.params.column.colId === 'cpc' && this.codeVisible
                    ? '/' + this.getCurrentCurrency.code
                    : '')
            );
        },
        codeVisible() {
            return (
                !this.getGlobalSearchQuery &&
                (this.getShareViewHashData !== null ||
                    !this.getViewkeyView ||
                    !this.getViewkeyHasChildren) &&
                (this.getOtherSetting('subcat') !== 'show' ||
                    !this.getCurrentProject ||
                    !this.getProjectByName(this.getCurrentProject)?.project
                        ?.children)
            );
        },
    },
    methods: {
        withoutIFields(field) {
            return KW_TABLE_COLUMNS.WITHOUT_I.includes(field);
        },
        isGSCColumns(field) {
            return KW_TABLE_COLUMNS.GSC.includes(field);
        },
        openNewTab(url) {
            window.open(url, '_blank');
        },
        onMenuClicked() {
            if (this.params.enableSorting) {
                if (this.params.column.isSortAscending()) {
                    this.params.setSort('desc');
                } else {
                    this.params.setSort('asc');
                }
            }
        },
    },
};
</script>

<style scoped>
.custom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
}
</style>
