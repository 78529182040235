<template>
    <div
        class="settings_table"
    >
        <div class="settings_table_container">
            <div class="settings_table_block">
                <project-table-settings />
            </div>
            <div class="settings_table_block">
                <table-colums />
            </div>
            <div class="settings_table_block">
                <table-other-settings />
            </div>
        </div>
    </div>
</template>

<script>
    import TableColums from './components/TableColums';
    import ProjectTableSettings from './components/ProjectTableSettings';
    import TableOtherSettings from './components/TableOtherSettings';
    
    export default {
        name: 'AccountTable',
        components: {
            'table-colums': TableColums,
            'project-table-settings': ProjectTableSettings,
            'table-other-settings': TableOtherSettings,
        }
    }
</script>

<style lang="scss">
  .settings_table {
    .settings_table_container {
      display: flex;
      flex-wrap: wrap;
      border-left: 1px solid kw-color(kw-white, 5);
      border-top: 1px solid kw-color(kw-white, 5);
      border-radius: 4px;
      & > :first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      & > :last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .settings_table_block {
        flex: 1;
        border: 1px solid kw-color(kw-white, 5);
        border-left: none;
        border-top: none;
        position: relative;
        background-color: kw-color(kw-white, 1);
      }
    }

    .table_setting_icon {
      position: absolute;
      left: 20px;
      top: -23px;
      height: 46px;
      width: 46px;
      background: url('~img/view-key-settings.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      display: block;

      &.table_colums_icon {
        background: url('~img/column-settings.svg');
      }

      &.kw-more-horizontal {
        background-image: none;
        background-color: #C0D6FA;
        border-radius: 50%;
        color: #0074D9;
        font-size: 20px;
        font-weight: 700;
        height: 46px;
        left: 22px;
        line-height: 47px;
        padding-left: 13px;
        position: absolute;
        top: -23px;
        width: 47px;
      }
    }
  }
</style>