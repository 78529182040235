<template>
    <div
        class="display-settings-project-modal"
    >
        <closer @click="close" />
        <div class="display-settings__header display-settings-header-project custom-modal_draggable">
            <h2
                class="settings__subtitle"
                v-text="getSubTitle"
            />
            <span
                class="settings__optional"
                v-text="$t('project-wide-settings')"
            />
        </div>
        <div class="display-settings display-settings-company">
            <form
                class="settings__company"
            >
                <div
                    class="password-container"
                >
                    <div class="container_title">
                        {{ $t('require-password-vk') }}
                    </div>
                    <radio-buttons
                        :options="projectSettingsRadios"
                        :value="isPasswordEnabled?'yes':'no'"
                        @radio_click="value => togglePasswordEnabled(value)"
                    />
                    <div 
                        v-if="isPasswordEnabled"
                        class="password-row"
                    >
                        <kw-input-field
                            id="project-password"
                            v-model="projectSettings.password"
                            :label="$t('set-password')"
                            class="text-input"
                            name="password"
                            :placeholder="$t('password-placeholder')"
                            type="text"
                            :error-data="inputCompanyError('password')"
                            @input="companyInputHandler('password')"
                        />
                    </div>
                </div>
                <div class="sov-container">
                    <div class="container_title">
                        {{ $t('show-sov-in-view-key') }}
                    </div>
                    <radio-buttons
                        :options="projectSettingsRadios"
                        :value="projectSettings.sov_view_key ? 'yes' : 'no'"
                        @radio_click="value => toggleSovInViewKeyEnabled(value)"
                    />
                </div>
                 
                <div class="data-container">
                    <div class="container_title">
                        {{ $t('override-account-vk') }}
                    </div>
                    <radio-buttons
                        :options="projectSettingsRadios"
                        :value="projectSettings.override_branding ? 'yes' : 'no'"
                        @radio_click="value => toggleDataOverride(value)"
                    />
                    <div
                        v-if="projectSettings.override_branding"
                        class="data-row"
                    >
                        <kw-input-field
                            id="company-name"
                            ref="company_name"
                            v-model.trim="projectSettings.company_name"
                            :label="$t('company-name')"
                            class="text-input"
                            name="CompanyName"
                            :placeholder="$t('your-company-name')"
                            type="text"
                            :error-data="inputCompanyError('company_name')"
                            @input="companyInputHandler('company_name')"
                        />
                        <kw-input-field
                            id="company-url"
                            ref="company_link"
                            v-model.trim="projectSettings.company_link"
                            :label="$t('company-url-link')"
                            class="text-input"
                            name="CompanyUrl"
                            :placeholder="$t('url-link-field-placeholder')"
                            type="text"
                            :error-data="inputCompanyError('company_link')"
                            @input="companyInputHandler('company_link')"
                        />
                        <kw-input-field
                            id="company-logo"
                            ref="company_logo"
                            v-model.trim="projectSettings.company_logo"
                            :label="$t('company-logo')"
                            class="text-input"
                            name="CompanyLogo"
                            :placeholder="$t('settings-view-key-logo-placeholder')"
                            type="text"
                            :error-data="inputCompanyError('company_logo')"
                            @input="companyInputHandler('company_logo')"
                        />
                        <label
                            for="company-description"
                            class="settings__label"
                            v-text="$t('company-description')"
                        />
                        <textarea
                            id="company-description"
                            ref="company_desc"
                            v-model.trim="projectSettings.company_desc"
                            :class="inputCompanyClasses('company_desc')"
                            name="CompanyDescription"
                            :placeholder="$t('company-description-placeholder')"
                            type="text"
                            @input="companyInputHandler('company_desc')"
                        />
                    </div>
                </div>
                <kw-button
                    :button-text="$t('save')"
                    button-type="primary"
                    :loading="projectSettingsLoading"
                    data-cy="branding-save-button"
                    @click="saveSettings()"
                />
            </form>
        </div>
        <div class="display-settings__viewkey-view">
            <viewkey-preview
                :company-desc="projectSettings.company_desc"
                :company-logo="projectSettings.company_logo"
                :company-name="projectSettings.company_name"
                :company-url="projectSettings.company_link"
                :hide-verified="getOtherSetting('hideverified')"
            />
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import ValidationMixin from '@/mixins/ValidationMixin';
    import ViewkeyPreview from './components/ViewkeyPreview';
    import {
        projectSettingsRadios
    } from './configs';

    export default {
        name: 'ProjectSettings',
        components: {
            'viewkey-preview': ViewkeyPreview,
        },
        mixins: [ValidationMixin],
        props: {
            close: {
                type: Function,
                required: true,
            },
        },
        data () {
            return {
                projectSettingsRadios,
                projectSettingsLoading: false,
                projectSettings: {
                    company_name: '',
                    company_link: '',
                    company_logo: '',
                    company_desc: '',
                    password: '',
                    sov_view_key: false,
                    override_branding: false,
                },
                companyErr: {},
                isPasswordEnabled: false,
            };
        },
        computed: {
            ...mapGetters([
                'getOtherSetting',
                'getCurrentProject',
            ]),
            getSubTitle () {
                return this.getCurrentProject.isSubproject ? this.$t('viewKey-group-settings'): this.$t('vk-setting');
            }
        },
        mounted () {
            const data = this.getFormNeededData();
            this.projectSettings = data;
            this.isPasswordEnabled = !!data.password;
        },
        methods: {
            ...mapActions([
                'updateProjectSettings',
                'updateViewKeySetting',
            ]),

            companyInputHandler (type) {
                switch (type) {
                    case 'company_name':
                        this.companyErr.company_name = this.reportsSettingsInputValidator(
                            this.projectSettings.company_name,
                            this.$t('company-name'),
                            50,
                        );
                        break;
                    case 'company_link':
                        this.companyErr.company_link = this.companyUrlValidator(
                            this.projectSettings.company_link,
                            this.$t('company-url')
                        );
                        break;
                    case 'company_logo':
                        this.companyErr.company_logo = this.companyLogoUrlValidator(
                            this.projectSettings.company_logo,
                            this.$t('logo-url')
                        );
                        break;
                    case 'company_desc':
                        this.companyErr.company_desc = this.reportsSettingsInputValidator(
                            this.projectSettings.company_desc,
                            this.$t('company-description'),
                            1000,
                        );
                        break;
                    case 'password':
                        this.companyErr.password = this.isPasswordEnabled
                            ? this.projectPasswordValidator(this.projectSettings.password)
                            : null;
                        break;
                    default:
                        this.companyErr[type] = {};
                        break;
                }
            },
            async saveSettings () {
                if (this.settingsValidator()) {
                    const {companyErr} = this;
                    Object.keys(companyErr).forEach(el => {
                        if (companyErr[el] && companyErr[el].text) {
                            this.$toastr.e(companyErr[el].text);
                            // touch input
                            this.$refs[el].focus();
                            this.$refs[el].blur();
                        }
                    });

                    return;
                }

                this.projectSettingsLoading = true;
                const requestData = {
                    data: {
                        type:'group',
                        attributes: {
                            ...this.projectSettings
                        }
                    }
                };
                try {
                    await this.updateProjectSettings({payload: requestData, projectId: this.getCurrentProject.id});

                    this.projectSettingsLoading = false;
                    this.close();
                } catch (error) {
                    this.projectSettingsLoading = false;
                }
                
            },
            settingsValidator () {
                const {projectSettings, companyErr} = this;
                Object.keys(projectSettings).forEach(el => this.companyInputHandler(el));
                return Object.keys(companyErr).some(el => companyErr[el] && companyErr[el].text);
            },
            inputCompanyClasses (type) {
                return {
                    'text-input': true,
                    'text-input_invalid': this.companyErr[type] && this.companyErr[type].text,
                };
            },
            inputCompanyError (type) {
                return {
                    error: this.companyErr[type] && this.companyErr[type].text,
                };
            },
            getFormNeededData() {
                const currentProject = this.getCurrentProject;

                const data = {
                    company_name: currentProject.company_name || '',
                    company_link: currentProject.company_link || '',
                    company_logo: currentProject.company_logo || '',
                    company_desc: currentProject.company_desc || '',
                    password: currentProject.password || '',
                    sov_view_key: currentProject.sov_view_key || false,
                    override_branding: currentProject.override_branding || false,
                };
                return data;
            },
            togglePasswordEnabled (value) {
                this.isPasswordEnabled = value === 'yes' ? true: false;

                if (!this.isPasswordEnabled) {
                    this.projectSettings.password = '';
                }
            },
            toggleSovInViewKeyEnabled (value) {
                this.projectSettings.sov_view_key = value === 'yes';
            },
            toggleDataOverride (value) {
                this.projectSettings.override_branding = value === 'yes' ? true: false;
                if (!value) {
                    this.projectSettings.company_name = '';
                    this.projectSettings.company_link = '';
                    this.projectSettings.company_logo = '';
                    this.projectSettings.company_desc = '';
                }
            },
        },
    }
</script>

<style lang="scss">
  .display-settings-project-modal {
    display: flex;
    flex-flow: row wrap;
    position: relative;

    .display-settings-header-project {
      display: flex;
      flex: 1 1 100%;
    }

    .display-settings-company {
      flex: 1 1 auto;
      max-width: 370px;
      border-top: 1px solid kw-color(kw-light-grey, 1);
      padding: 20px 0;
    }

    .display-settings__viewkey-view {
      flex: 1;
      padding: 0 15px 0 20px;
    }

    .button-closer {
      position: absolute;
      right: 24px;
      top: 15px;
    }

    .settings__subtitle {
      margin-bottom: 0;
    }

    .settings__optional {
      color: #B9BABD;
      font-size: 16px;
      margin-left: 8px;
    }

    .text-label {
      font-size: 13px;
      padding-bottom: 17px;

      &:nth-child(4) {
        padding-bottom: 4px;
      }

      span {
        padding-left: 44px;
        white-space: nowrap;
      }
    }

    .settings__company {
      display: flex;
      flex-flow: column nowrap;
    }

    .settings__label {
      margin-top: 17px;
    }

    textarea {
      @extend .custom-scroll;
      min-height: 114px;
      min-width: 100%;
      max-width: 100%;
      @media screen and (max-width: 960px) {
        width: 100%;
      }
    }

    .kw-btn-primary {
      margin-top: 20px;
      align-self: flex-end;
      width: 160px;

      .button-loader {
        position: relative !important;
        background-color: transparent !important;
        border-color: transparent transparent transparent kw-color(kw-white, 1) !important;
        left: 0 !important;
        height: 18px !important;
        min-width: 18px !important;
        width: 18px !important;
      }
    }

    .password-container {
      padding: 0 15px 30px;
      border-bottom: 1px solid #CCD2DB;

      .password-row {
        .kw__inputfield {
          margin-top: 16px;
        }
      }

      input {
        width: 100%;
      }
    }

    .sov-container {
      padding: 20px 15px 30px;
      border-bottom: 1px solid #CCD2DB;

      .password-row {
        .kw__inputfield {
          margin-top: 16px;
        }
      }

      input {
        width: 100%;
      }
    }

    .data-container {
      padding: 20px 15px 0;

      .data-row {
        .kw__inputfield {
          margin-top: 16px;
        }
      }

      input {
        width: 100%;
      }
    }

    .radio-buttons {
      display: flex;
      padding: 0;

      .radio-button {
        padding: 0;

        &:first-child {
          margin-right: 36px;
        }
      }
    }

    .kw-button {
      margin-right: 15px;
    }

    .container_title {
      font-size: 13px;
      color: kw-color(kw-black, 1);
      padding-bottom: 5px;
    }

    .button-closer {
      right: 10px;
    }
  }
</style>