var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"serp-history-modal"},[_c('div',{staticClass:"header"},[_c('closer',{on:{"click":_vm.close}}),_vm._v(" "),_c('h4',{domProps:{"textContent":_vm._s(_vm.$t('serp-history'))}}),_vm._v(" "),_c('p',{staticClass:"keyword-name"},[_vm._v("\n            "+_vm._s(_vm.$t('keyword'))+": "),_c('span',{domProps:{"textContent":_vm._s(_vm.keywords[0].kw)}})])],1),_vm._v(" "),_c('div',{staticClass:"body"},[_c('div',{staticClass:"controls"},[_c('date-range',{attrs:{"end-label":_vm.$t('end-date'),"end-value":_vm.endValue,"set-date":_vm.setRange,"start-label":_vm.$t('start-date'),"start-value":_vm.startValue},on:{"update:startValue":function($event){_vm.startValue=$event},"update:start-value":function($event){_vm.startValue=$event}}}),_vm._v(" "),_c('kw-button',{staticClass:"show-serp-history",attrs:{"button-type":"primary","button-text":_vm.$t('apply-date-range')},on:{"click":_vm.fetchSerpHistory}}),_vm._v(" "),_c('kw-button',{attrs:{"button-type":"primary","button-text":_vm.$t('export'),"button-disabled":!_vm.getSerpHistoryResults.length,"loading":_vm.loading},on:{"click":_vm.exportSerpHistory}})],1),_vm._v(" "),(!_vm.loading)?_c('div',{staticClass:"history-tables-wrapper"},[(_vm.getSerpHistoryResults.length)?_c('div',_vm._l((_vm.getSerpHistoryResults),function(result,i){return _c('div',{key:result.date,staticClass:"single-table"},[_c('div',{staticClass:"single-table__date"},[_vm._v(_vm._s(result.date))]),_vm._v(" "),_c('div',{staticClass:"single-table__results"},_vm._l((result.results),function(item,index){return _c('div',{key:index,class:_vm.tableResultClasses(
                                    item,
                                    index + 1 === result.rank
                                ),on:{"click":function($event){return _vm.highlightSERP(
                                    item,
                                    index + 1 === result.rank
                                )}}},[_vm._v("\n                            "+_vm._s(index + 1)+"\n                            "),(
                                    !item.url &&
                                    item.resultType === 'local'
                                )?[_c('span',{staticClass:"kw kw-map-pin"}),_vm._v("\n                                "+_vm._s(item.title)+"\n                            ")]:[_c('img',{attrs:{"src":("https://www.google.com/s2/favicons?sz=64&domain_url=" + (item.url))}}),_vm._v("\n                                "+_vm._s(item.url)+"\n                            ")]],2)}),0),_vm._v(" "),(_vm.showLink(i))?_c('list-link',{attrs:{"list-class":"single-table__results","list-item-highlighted-class":"table-result_highlighted","highlighted":_vm.highlighted,"selected-local":_vm.selectedLocal,"is-local":_vm.isLocal,"next-list-index":i + 1,"prev-list-index":i,"trend":_vm.trend(i, _vm.highlighted, _vm.isLocal)}}):_vm._e()],1)}),0):_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('no-result')))])])]):_c('div',{staticClass:"loading"},[_c('loader')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }