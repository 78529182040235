import client from '../client';
import interceptors from '../interceptors';
const usersInterceptor = interceptors.get('users');

client.interceptors.response.use(
    usersInterceptor.s,
    usersInterceptor.e,
);

const resource = '/users';

const settingsAndSubscriptionQuery = {
    params: { include: 'settings,subscriptions' },
};

const prepareData = payload => ({
    data: {
        type: 'user',
        attributes: payload,
    }
});

export default {
    fetchSettings: async () => await client.get(`${resource}/self`, settingsAndSubscriptionQuery),
    fetchUsed: async () => await client.get(`${resource}/self/used`),
    fetchUsedRefreshes: async () => await client.get(`${resource}/self/refreshed`),
    updatePassword: async payload => await client.put(`${resource}/self/password`, prepareData(payload)),
    updateAccount: async payload => await client.put(`${resource}/self`, prepareData(payload)),
    updateUserCompanySettings: async (payload, params) => await client.patch(`${resource}/myself`, prepareData(payload), params),

    validateEmail: async payload => await client.get(`${resource}/validate-email`, payload),
    verifyEmail: async (hash, payload) => await client.get(`${resource}/${hash}/verify`, { params: { ...payload.params, ...settingsAndSubscriptionQuery.params } }),
    resendVerificationEmail: async payload => await client.post(`${resource}/verify/resend`, payload),

    onboardUser: async payload => await client.patch(`${resource}/onboard`, payload),
    sendEmailToSupport: async () => await client.get(`${resource}/email-us`),
    sendRequestToUpgradePlan: async payload => await client.post(`${resource}/self/request-to-upgrade-plan`, payload),
    resetOnDemandRefreshLimit: async payload => await client.post(`${resource}/self/reset-on-demand-refresh-limit`, payload),
};
