import {
    SET_PROJECT_OVERVIEW_OPTION,
    SET_PROJECT_OVERVIEW_OPTION_IS_VISIBLE,
    SET_PROJECT_OVERVIEW_OPTIONS,
    SET_PROJECT_VISIBILITY,
    SET_PROJECT_ESTIMATED_TRAFFIC,
    SET_PROJECT_OVERVIEW_DATA,
    SET_PROJECT_KEYWORDS_BY_INTENT,
    SET_PROJECT_SERP_FEATURE_PERFOMANCE,
    SET_PROJECT_AVERAGE_RANK,
    SET_PROJECT_TOP_PAGES,
    SET_PROJECT_KEYWORDS_IN_TOP_POSITIONS,
} from '../mutations';
import apiFactory from '@/api';
import { projectOverviewOptionsDefaultState } from '@/constants';
import i18n from '@/i18n';
import { getPercentage } from '@/helpers';
import { 
    createDifferenceColHTMLValue,
    createSERPFeaturesNameColHTMLValue,
    createSERPFeaturesChartColValue,
    createFeaturedColHTMLValue
} from '@/helpers/projectOverviewHelpers';
import { switchDateFormat } from '@/helpers/format-date-service'

const chartColors = ['#69D4DB', '#8A8AD6', '#F29177', '#64B35F'];
const metricsApi = apiFactory.get('metrics');

export default {
    state: {
        projectOverviewOptions: _.cloneDeep(projectOverviewOptionsDefaultState),
        projectOverviewData: {
            visibility: {
                data: null,
                loading: false,
                lastFetchedRequest: null
            },
            estimated_traffic: {
                data: null,
                loading: false,
                lastFetchedRequest: null
            },
            keywords_by_intent: {
                data: null,
                loading: false,
                lastFetchedRequest: null
            },
            serp_feature_performance: {
                data: null,
                loading: false,
                lastFetchedRequest: null
            },
            top_pages: {
                data: null,
                loading: false,
                lastFetchedRequest: null
            },
            average_rank: {
                data: null,
                loading: false,
                lastFetchedRequest: null
            },
            keywords_in_top: {
                data: null,
                loading: false,
                lastFetchedRequest: null
            },
        },
    },
    mutations: {
        [SET_PROJECT_OVERVIEW_OPTION]: (state, { option, value }) =>
            (state.projectOverviewOptions[option] = value),
        [SET_PROJECT_OVERVIEW_OPTION_IS_VISIBLE]: (state, { option, value }) =>
            (state.projectOverviewOptions[option].visible = value),
        [SET_PROJECT_OVERVIEW_OPTIONS]: (state, payload) =>
            (state.projectOverviewOptions = payload),
        [SET_PROJECT_VISIBILITY]: (state, payload) =>
            (state.projectOverviewData.visibility = {
                ...state.projectOverviewData.visibility,
                ...payload,
            }),
        [SET_PROJECT_ESTIMATED_TRAFFIC]: (state, payload) =>
            (state.projectOverviewData.estimated_traffic = {
                ...state.projectOverviewData.estimated_traffic,
                ...payload,
            }),
        [SET_PROJECT_OVERVIEW_DATA]: (state, payload) =>
            (state.projectOverviewData = payload),
        [SET_PROJECT_KEYWORDS_BY_INTENT]: (state, payload) =>
        (state.projectOverviewData.keywords_by_intent = {
            ...state.projectOverviewData.keywords_by_intent,
            ...payload,
        }),
        [SET_PROJECT_SERP_FEATURE_PERFOMANCE]: (state, payload) =>
        (state.projectOverviewData.serp_feature_performance = {
            ...state.projectOverviewData.serp_feature_performance,
            ...payload,
        }),
        [SET_PROJECT_TOP_PAGES]: (state, payload) =>
        (state.projectOverviewData.top_pages = {
            ...state.projectOverviewData.top_pages,
            ...payload,
        }),
        [SET_PROJECT_AVERAGE_RANK]: (state, payload) =>
        (state.projectOverviewData.average_rank = {
            ...state.projectOverviewData.average_rank,
            ...payload,
        }),
        [SET_PROJECT_KEYWORDS_IN_TOP_POSITIONS]: (state, payload) =>
        (state.projectOverviewData.keywords_in_top = {
            ...state.projectOverviewData.keywords_in_top,
            ...payload,
        }),
    },
    getters: {
        getProjectOverviewOptions: state => state.projectOverviewOptions,
        getProjectOverviewOptionItem: state => prop => state.projectOverviewOptions[prop],
        getProjectVisibility: state => state.projectOverviewData.visibility,
        getProjectEstimatedTraffic: state => state.projectOverviewData.estimated_traffic,
        getProjectOverviewData: state => state.projectOverviewData,
        getProjectKeywordsByIntent: state => state.projectOverviewData.keywords_by_intent,
        getProjectSerpFeaturePerformance: state => state.projectOverviewData.serp_feature_performance,
        getProjectTopPages: state => state.projectOverviewData.top_pages,
        getProjectAverageRank: state => state.projectOverviewData.average_rank,
        getProjectKeywordsInTopPositions: state => state.projectOverviewData.keywords_in_top,
    },
    actions: {
        setProjectOverviewOption({ commit }, payload) {
            commit(SET_PROJECT_OVERVIEW_OPTION, payload);
        },
        setProjectOverviewOptions({ commit }, payload) {
            commit(SET_PROJECT_OVERVIEW_OPTIONS, payload);
        },
        resetProjectOverviewOptionsState({commit}) {
            commit(SET_PROJECT_OVERVIEW_OPTIONS, _.cloneDeep(projectOverviewOptionsDefaultState));
        },
        async setProjectOverviewOptionIsVisible({ getters, commit, dispatch }, payload) {
            const { projectId, option, value } = payload;

            const data = getters.getProjectOverviewOptions;
            
            const requestData = {
                data: {
                    attributes: {
                        project_overview_settings: {
                            ...data,
                            [option]: {
                                ...data[option],
                                visible: value
                            }
                        },
                    }
                }
            };
            try {
                if (!getters.getCurrentProject?.is_demo_project) {
                    await dispatch('updateProjectSettings', {projectId, payload: requestData, dontUpdate: true});
                }
                
                commit(SET_PROJECT_OVERVIEW_OPTION_IS_VISIBLE, { option, value });
    
                return requestData;
            } catch (error) {
                return;
            }
        },
        async setProjectOverviewOptionOrder({ getters, commit, dispatch }, payload) {
            const { order, name, projectId } = payload;
            const data = _.cloneDeep({ ...getters.getProjectOverviewOptions });

            if(data[name].order === order) return;
            
            Object.keys(data).forEach(key => {
                if (key !== name) {
                    if (
                        data[name].order > order &&
                        data[key].order >= order &&
                        data[key].order < data[name].order
                    ) {
                        data[key] = { ...data[key], order: data[key].order + 1 };
                    } else if (
                        data[name].order < order &&
                        data[key].order > data[name].order &&
                        data[key].order <= order
                    ) {
                        data[key] = { ...data[key], order: data[key].order - 1 };
                    }
                }
            });
            data[name].order = order;

            commit(SET_PROJECT_OVERVIEW_OPTIONS, data);

            const requestData = {
                data: {
                    attributes: {
                        project_overview_settings: data,
                    }
                }
            };
            try {
                if (!getters.getCurrentProject?.is_demo_project) {
                    await dispatch('updateProjectSettings', {projectId, payload: requestData, dontUpdate: true});
                }
            } catch (error) {
                return;
            } 
        },
        setProjectEstimatedTraffic({ commit }, payload) {
            commit(SET_PROJECT_ESTIMATED_TRAFFIC, payload);
        },
        setProjectVisibility({ commit }, payload) {
            commit(SET_PROJECT_VISIBILITY, payload);
        },
        async fetchProjectVisibility({ commit, getters }, payload) {
            let { projectId, tagId, startDate, endDate } = payload;
            const currentFetch = Date.now();

            if(!startDate && !endDate){
                let today = new Date();
                startDate = switchDateFormat(new Date(new Date().setDate(today.getDate() - 30)), 'Y-m-d');
                endDate = switchDateFormat(new Date(), 'Y-m-d');
            }

            try {
                commit(SET_PROJECT_VISIBILITY, { loading: true, data: [], lastFetchedRequest: currentFetch});

                const res = await metricsApi.fetchVisibilityChartData(projectId, {
                        tag_id: tagId || null, 
                        start_date: startDate,
                        end_date: endDate,
                    }
                );

                if (getters.getProjectVisibility.lastFetchedRequest && currentFetch !== getters.getProjectVisibility.lastFetchedRequest) {
                    return;
                }

                const data = { 
                    loading: false,
                    data: Object.values(res.data.data)
                    .map(item => [item.period_date, item.visibility]) 
                };

                commit(SET_PROJECT_VISIBILITY, data);
            } catch (error) {
                const data = { 
                    loading: false, 
                    data: [],
                    lastFetchedRequest: null
                };
                
                commit(SET_PROJECT_VISIBILITY, data);
            }
        },
        async fetchProjectEstimatedTraffic({ commit, getters }, payload) {
            let { projectId, tagId, startDate, endDate } = payload;
            const currentFetch = Date.now();

            if(!startDate && !endDate){
                let today = new Date();
                startDate = switchDateFormat(new Date(new Date().setDate(today.getDate() - 30)), 'Y-m-d');
                endDate = switchDateFormat(new Date(), 'Y-m-d');
            }

            try {
                commit(SET_PROJECT_ESTIMATED_TRAFFIC, { loading: true, data: [], lastFetchedRequest: currentFetch});

                const res = await metricsApi.fetchEstimatedTrafficChartData(projectId, {
                        tag_id: tagId || null,
                        start_date: startDate,
                        end_date: endDate,
                    }
                );

                if (getters.getProjectEstimatedTraffic.lastFetchedRequest && currentFetch !== getters.getProjectEstimatedTraffic.lastFetchedRequest) {
                    return;
                }

                const data = {
                    loading: false,
                    data: Object.values(res.data.data)                    
                    .map(item => [item.period_date, item.estimated_traffic]),
                    lastFetchedRequest: currentFetch
                };

                commit(SET_PROJECT_ESTIMATED_TRAFFIC, data);
            } catch (error) {
                const data = { 
                    loading: false,
                    data: [],
                    lastFetchedRequest: null
                };

                commit(SET_PROJECT_ESTIMATED_TRAFFIC, data);
            }
        },
        async fetchProjectKeywordsByIntent({ commit, getters }, payload) {
            const { projectId, tagId } = payload;
            const currentFetch = Date.now();

            try {
                commit(SET_PROJECT_KEYWORDS_BY_INTENT, { loading: true, data: [], lastFetchedRequest: currentFetch });

                const res = await metricsApi.fetchKeywordsByIntentChartData(projectId, { tag_id: tagId || null });
                
                if (getters.getProjectKeywordsByIntent.lastFetchedRequest && currentFetch !== getters.getProjectKeywordsByIntent.lastFetchedRequest) {
                    return;
                }

                const { total,  informational, navigational, commercial, transactional } = res.data.data?.data || {};

                const tableData = Object.entries({
                    informational,
                    navigational,
                    commercial,
                    transactional
                }).map(([key,value], index) => ({
                    intent: i18n.t(`search-intents.${key}`),
                    share: getPercentage(value.count, total),
                    keywords: value.count,
                    est_traffic: value.estimated_traffic.toFixed(2),
                    color: chartColors[index]
                }));
    
                const data = {
                    loading: false,
                    data: tableData,
                };
    
                commit(SET_PROJECT_KEYWORDS_BY_INTENT, data);
            } catch (error) {
                const data = { 
                    loading: false,
                    data: [],
                    lastFetchedRequest: null
                };

                commit(SET_PROJECT_KEYWORDS_BY_INTENT, data);
            }
        },
        async fetchProjectSerpFeaturePerformance({ commit, getters }, payload) {
            const { projectId, tagId, comparedDate } = payload;
            const currentFetch = Date.now();

            try {
                commit(SET_PROJECT_SERP_FEATURE_PERFOMANCE, { loading: true, data: [], lastFetchedRequest: currentFetch });

                const res = await metricsApi.fetchSerpFeaturePerformanceChartData(projectId, {
                        tag_id: tagId || null, 
                        compared_date: comparedDate,
                    }
                );

                if (getters.getProjectSerpFeaturePerformance.lastFetchedRequest && currentFetch !== getters.getProjectSerpFeaturePerformance.lastFetchedRequest) {
                    return;
                }
                const { total_serp_feature, total_current_featured, serp_features } = res.data.data|| {};

                let currentSum = 0;
                let previousSum = 0;
    
                const data = {
                    loading: false,
                    data: {
                        total: total_serp_feature,
                        current: total_current_featured,
                        serp_features: Object.keys(serp_features).reduce((acc, cur)=> {
                            if(serp_features[cur].serp_feature_count > 0){
                                currentSum+=serp_features[cur].current_featured_count;
                                previousSum+=serp_features[cur].previous_featured_count;
    
                                acc.push({
                                    serp_feature: createSERPFeaturesNameColHTMLValue(cur),
                                    chart: createSERPFeaturesChartColValue({
                                        name: cur,
                                        subtotal: total_serp_feature,
                                        total: serp_features[cur].serp_feature_count,
                                        featured: serp_features[cur].current_featured_count
                                    }),
                                    total: serp_features[cur].serp_feature_count,
                                    featured: createFeaturedColHTMLValue(serp_features[cur].current_featured_count,
                                        serp_features[cur].previous_featured_count)
                                })
                            }
                            return acc;
                        }, [])
                    }
                };  

                data.data.featuredChange = currentSum - previousSum !== 0
                    ? {
                        amount: currentSum > 0 && !previousSum
                            ? '100.0'
                            : ((Math.abs(currentSum - previousSum) / previousSum) * 100).toFixed(1),
                        arrow: currentSum - previousSum > 0 ? 'up' : 'down'
                    } : null;

                commit(SET_PROJECT_SERP_FEATURE_PERFOMANCE, data);
            } catch (error) {
                const data = { 
                    loading: false, 
                    data: null,
                    lastFetchedRequest: null
                };

                commit(SET_PROJECT_SERP_FEATURE_PERFOMANCE, data);
            }
        },
        async fetchProjectAverageRank({ commit, getters }, payload) {
            let { projectId, tagId, startDate, endDate } = payload;
            const currentFetch = Date.now();

            if(!startDate && !endDate){
                let today = new Date();
                startDate = switchDateFormat(new Date(new Date().setDate(today.getDate() - 30)), 'Y-m-d');
                endDate = switchDateFormat(new Date(), 'Y-m-d');
            }
            try {
                commit(SET_PROJECT_AVERAGE_RANK, { loading: true, data: {}, lastFetchedRequest: currentFetch });

                const res = await metricsApi.fetchAverageRankChartData(projectId, { 
                        tag_id: tagId || null,
                        start_date: startDate,
                        end_date: endDate,
                    }
                );

                if (getters.getProjectAverageRank.lastFetchedRequest && currentFetch !== getters.getProjectAverageRank.lastFetchedRequest) {
                    return;
                }

                const categories = [];
                const seriesData30DayAverage = [];
                const seriesDataDailyAverage = [];
                for (const [key, value] of Object.entries(res.data.data)) {
                    if (value.ranking_averages) {
                        categories.push(key !== 'latest' ? switchDateFormat(new Date(key), 'new-note') : 'latest');
                        seriesData30DayAverage.push(value.ranking_averages["30_day_average"]);
                        seriesDataDailyAverage.push(value.ranking_averages["daily_average"]);
                    }
                }

                const data = {
                    loading: false,
                    data: {
                        categories,
                        series: [
                            {
                                data: seriesData30DayAverage,
                                color: '#A2E0D8',
                                name: i18n.t('30_day_average')
                            },
                            {
                                data:seriesDataDailyAverage,
                                color: '#0074D9',
                                name: i18n.t('daily_average')
                            }
                        ]
                    }
                };

                commit(SET_PROJECT_AVERAGE_RANK, data);
            } catch (error) {
                const data = {
                    loading: false, 
                    data: {},
                    lastFetchedRequest: null
                };
                commit(SET_PROJECT_AVERAGE_RANK, data);
            }
        },
        async fetchProjectTopPagesChartData({ commit, getters }, payload) {
            const { projectId, tagId, comparedDate } = payload;
            const currentFetch = Date.now();

            try {
                commit(SET_PROJECT_TOP_PAGES, { loading: true, data: [], lastFetchedRequest: currentFetch });

                const res = await metricsApi.fetchTopPagesChartData(projectId, {
                        tag_id: tagId || null,
                        compared_date: comparedDate
                    }
                );
                
                if (getters.getProjectTopPages.lastFetchedRequest && currentFetch !== getters.getProjectTopPages.lastFetchedRequest) {
                    return;
                }
            
                const data = {
                    loading: false,
                    data: res.data.data.map(item => ({
                        url: item.current_ranking_url,
                        avg_rank: createDifferenceColHTMLValue(item.average_current_rank, item.average_previous_rank, true, true),
                        keywords: item.total_keyword_count,
                        visibility: createDifferenceColHTMLValue(item.visibility_current, item.visibility_previous, true, true),
                        est_traffic: createDifferenceColHTMLValue(item.estimated_traffic_current, item.estimated_traffic_previous, true, true)
                    })),
                };

                commit(SET_PROJECT_TOP_PAGES, data);
            } catch (error) {
                const data = { 
                    loading: false, 
                    data: [],
                    lastFetchedRequest: null
                };

                commit(SET_PROJECT_TOP_PAGES, data);
            }
        },
        async fetchProjectKeywordsInTopPositionsChartData({ commit, getters }, payload) {
            let { projectId, tagId, startDate, endDate } = payload;
            const currentFetch = Date.now();

            if(!startDate && !endDate){
                let today = new Date();
                startDate = switchDateFormat(new Date(new Date().setDate(today.getDate() - 30)), 'Y-m-d');
                endDate = switchDateFormat(new Date(), 'Y-m-d');
            }

            try {
                commit(SET_PROJECT_KEYWORDS_IN_TOP_POSITIONS, { loading: true, data: [], lastFetchedRequest: currentFetch });

                const res = await metricsApi.fetchTopPositionsHistoryChartData(projectId, {
                        tag_id: tagId || null,
                        start_date: startDate,
                        end_date: endDate
                    }
                );
                if (getters.getProjectKeywordsInTopPositions.lastFetchedRequest && currentFetch !== getters.getProjectKeywordsInTopPositions.lastFetchedRequest) {
                    return;
                }

                const result = {};
                const resData = res.data.data;
                const categories = [];
                for(let i in resData){
                    categories.push(i);
                    let topPosData = resData[i].top_positions;
                    if(topPosData){
                        for(let j in topPosData){
                            if(result[j]?.length){
                                result[j].push(topPosData[j]);
                            } else {
                                result[j] = [topPosData[j]];
                            }
                        }
                    }
                }
                const keywordsInTopPositions = Object.entries(result).map(([key, value]) => ({
                    title: key,
                    data: value
                }));

                const data = {
                    loading: false,
                    data: keywordsInTopPositions,
                    categories: categories,
                    projectId
                };

                commit(SET_PROJECT_KEYWORDS_IN_TOP_POSITIONS, data);
                return Promise.resolve(data);
            } catch (error) {
                const data = { 
                    loading: false, 
                    data: [],
                    lastFetchedRequest: null
                };

                commit(SET_PROJECT_KEYWORDS_IN_TOP_POSITIONS, data);
                return Promise.reject(data);
            }
        },
        setProjectOverviewData({ commit }, payload) {
            commit(SET_PROJECT_OVERVIEW_DATA, payload);
        },
        resetProjectOverviewData({ getters, commit }){
            const data = {};
            Object.keys(getters.getProjectOverviewData).forEach((key) => {
                data[key] = {
                    data: null,
                    loading: false,
                }
            });

            commit(SET_PROJECT_OVERVIEW_DATA, data);
        },
        async updateProjectTimeFrame({ getters, commit, dispatch }, payload){
            const { projectId, option, value, interval } = payload;

            const data = getters.getProjectOverviewOptions;

            const updated = {
                ...data,
                [option]: {
                    ...data[option],
                    ...value,
                    ...interval
                }
            }
            if(!updated.visibility_and_estimated_traffic){
                updated.visibility_and_estimated_traffic = {
                    visible: false,
                    order: 99
                }
            }

            commit(SET_PROJECT_OVERVIEW_OPTIONS, updated);

            if((interval.interval && interval.interval !== data[option].interval) 
                || (interval.compared_date && interval.compared_date !== data[option].compared_date)){
                const updatedInterval = {
                    ...data,
                    [option]: {
                        ...data[option],
                        date_range: undefined,
                        compare_date: undefined,
                        date: undefined,
                        ...interval
                    }
                }
                const requestData = {
                    data: {
                        attributes: {
                            project_overview_settings: updatedInterval,
                        }
                    }
                };
                if(!getters.getCurrentProject?.is_demo_project){
                    dispatch('updateProjectSettings', {projectId, payload: requestData, dontUpdate: true});
                }
            }

            return updated;
        },
    },
    
};
