<template>
    <span :class="getClasses">{{ getText }}</span>
</template>

<script>
export default {
    name: 'StatusRenderer',
    data() {
        return {
            requiredFields: [
                'Domain',
                'Keyword',
                'Language',
                'Project Name',
                'Region',
                'Type',
            ],
        };
    },
    computed: {
        getStatus() {
            return this.params.data.your_field !== null
                ? true
                : this.requiredFields.includes(this.params.data.kw_field)
                ? false
                : null;
        },
        getText() {
            return this.getStatus === false ? 'Mandatory Field' : '';
        },
        getClasses() {
            return {
                kw: this.getStatus !== null,
                'kw-check': this.getStatus === true,
                'kw-x': this.getStatus === false,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.kw-check {
    font-weight: 500;
    color: #41a63d;
}

.kw-x {
    font-size: 11px;
    font-weight: bold;
    color: #cb1a02;
}
</style>
