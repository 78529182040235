export default {
    'kw-int': {
        'avg-pos-tooltip': 'Filter by the average ranking position of your site in search results for the selected keywords',
        'word-count-tooltip': 'Filter keywords by the total number of words in the search query',
        'impressions-tooltip': 'Filter by the number of times your site appeared in search results for the selected keywords.',
        'clicks-tooltip': 'Filter by the number of clicks your site received from search results for the selected keywords.',
        'competition-tooltip': 'Filter keywords based on their competition level: Low, Medium, or High.',
        'ms-tooltip': 'Filter by the average number of searches per month for the selected keywords.',
        'cpc-tooltip': 'Filter by the average cost advertisers pay per click for the selected keywords.',
        'ctr-tooltip': 'Click-through rate: the click count divided by the impression count.',
        'filters': {
            'keyword_term': 'Term',
            'position': 'Avg. Position',
            'impression': 'Impressions',
            'word_count': 'Word Count',
            'clicks': 'CTR',
            'date': 'Date',
            'monthlySearches': 'Search Volume',
            'competition': 'Competition',
            'cpc': 'CPC',
            'ctr': 'CTR'
        }
    },
    'eq': 'Equal to',
    'gt': 'Greater than',
    'lt': 'Less than',
    'bw': 'Between',
    'save-filter': 'Save Filter',
    'add-filter-name': 'Add Filter Name',
    'related-empty-table': 'Start your keyword research by entering a seed keyword. This will unlock a list of related keywords with valuable data about them.',
    'competitors-empty-table': 'Unlock hidden keywords! Enter a website URL to see search terms related to its content, helping you understand its focus and how users might find it.'
};
