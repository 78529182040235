<template>
    <div
        v-if="showViewkey"
        :class="shareViewkeyClasses"
        @mouseover="mouseoverHandler"
    >
        <share-header v-if="getIsFreePlan || isHeaderVisible" />

        <div class="share-data__wrapper">
            <share-data v-if="showViewkeyData" :project="getCurrentProject" />
        </div>

        <div class="share-top-bar">
            <refresh :title="projectDashboardTitle" />
            <share-dropdown
                :project="getViewkeyProjectName"
                :subproject="getViewkeySubprojectName"
            />
        </div>

        <project />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoginForViewkey from 'components/modals/LoginForViewkey';
import Project from 'components/main-block/components/Project';
import Refresh from 'components/refresh/Refresh';
import ShareDropdown from 'components/common/ShareDropdown';
import ShareData from 'components/share-viewkey/ShareData';
import ShareHeader from 'components/share-viewkey/ShareHeader';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import { detectIsSurferSEO } from '@/helpers';

export default {
    name: 'ShareViewkey',
    components: {
        project: Project,
        refresh: Refresh,
        'share-dropdown': ShareDropdown,
        'share-data': ShareData,
        'share-header': ShareHeader,
    },
    mixins: [ToolTipRenderMixin],
    props: {
        viewkey: {
            type: String,
            required: false,
            default: '',
        },
        auth: {
            type: String,
            required: false,
            default: '',
        },
        client: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'getCurrentProject',
            'getIsFreePlan',
            'getListOfKeywordsDomains',
            'getProjectProperty',
            'getSelectedAuth',
            'getViewkeyAccessStatus',
            'getViewkeyProjectName',
            'getViewkeySubprojectName',
            'getKeywordsForRefreshing',
            'getDisplaySetting'
        ]),
        getWebsiteUrl() {
            return process.env.KEYWORD_WEBSITE_URL;
        },
        showViewkey() {
            return (
                this.getViewkeyAccessStatus &&
                this.getViewkeyAccessStatus !== 'denied'
            );
        },
        showViewkeyData() {
            return this.getCurrentProject && this.getCurrentProject.vkinfo;
        },
        projectDashboardTitle() {
            return this.getListOfKeywordsDomains?.length === 1
                ? this.getListOfKeywordsDomains[0]
                : this.getProjectProperty('name');
        },
        shareViewkeyClasses() {
            return {
                'share-viewkey': true,
                'share-viewkey_action-bar': this.getSelectedAuth.length,
                'surferseo': detectIsSurferSEO()
            };
        },
    },
    watch: {
        showViewkey(val) {
            this.openLoginModalForViewkey(val);
        },
        getKeywordsForRefreshing(to, from){
            if(from.length && this.getCurrentProject?.project_id){
                this.fetchMetricsData();
            }
        }
    },
    async created() {
        if(detectIsSurferSEO()){
            document.body.classList.add('surferseo_body')
        }
        this.setIsViewkeyView(true);
        await this.$store.dispatch('getViewkeyInfo', {
            shortkey: this.viewkey,
            id: this.auth,
            client: this.client,
        });

        const {
            name: routeName,
            params: routeParams,
        } = this.$router.currentRoute;
        if (routeName === 'shareViewkeyWithHash' || routeName === 'shareKeywordsTableWithHash') {
            await this.getTagDataForShareAction(routeParams.hash);
        }
        this.openLoginModalForViewkey(this.showViewkey);
    },
    mounted(){
        if(detectIsSurferSEO()){
            this.tryToFetchData();
        }
    },
    beforeCreate() {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'robots');
        meta.setAttribute('content', 'noindex');
        document.getElementsByTagName('head')[0].append(meta);
    },
    methods: {
        ...mapActions([
            'setIsViewkeyView',
            'getTagDataForShareAction',
            'fetchViewkeyMetricsAndChart',
            'fetchViewkeyMetrics',
        ]),
        openLoginModalForViewkey(val) {
            if (!val) {
                this.$modal.show(
                    LoginForViewkey,
                    {
                        viewkey: this.viewkey,
                        auth: this.auth,
                        client: this.client,
                    },
                    {
                        width: 282,
                        height: 'auto',
                        classes: 'v--modal v--modal_login-viewkey',
                        clickToClose: false,
                        name: 'loginForViewkeyModal',
                    },
                );
            } else {
                this.$modal.hide('loginForViewkeyModal');
            }
        },
        fetchMetricsData(){
            if (this.getDisplaySetting('metrics') !== 'hide') {
                if (
                    this.$route.name !== 'shareOfVoice' &&
                    this.$route.name !== 'shareOfVoiceWithHash' &&
                    this.$route.name !== 'shareKeywordsTable' &&
                    this.$route.name !== 'shareKeywordsTableWithHash'
                ) {
                    this.fetchViewkeyMetricsAndChart();
                }else{
                    this.fetchViewkeyMetrics();
                }
            }
        },
        tryToFetchData(){
            setTimeout(()=>{
                if(!this.getKeywordsForRefreshing.length){
                    this.fetchMetricsData();
                }
                this.tryToFetchData()
            }, 15000)
        }
    },
};
</script>

<style lang="scss">
.surferseo-font {
    font-family: "Inter", sans-serif !important;
}
.surferseo-button-animation {
    transition-property: color, background-color, box-shadow, opacity!important;
    transition-timing-function: cubic-bezier(.4,0,.2,1)!important;
    transition-duration: .15s!important;
}
.share-viewkey {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 44px;
    position: relative;
    width: 100%;

    .dashboard-table-wrap {
        margin: auto;
        max-width: 1080px;
    }

    &.share-viewkey_action-bar {
        .dashboard-table-wrap {
            .keywords-container {
                &.keywords-container_share-view {
                    .keywords-table__wrapper {
                        .keywords-table__header {
                            &.keywords-table__header_fixed {
                                top: 91px;
                            }
                        }
                    }
                }
            }
        }
    }

    .export-dropdown.dropdown_container {
        z-index: 16;
        .custom_dropdown_body {
           left: -70px;
        }
    }
}

.share-data__wrapper {
    margin-top: 32px;
    display: flex;
    flex-flow: column nowrap;
    max-width: 1080px;
    width: 100%;
    @media screen and (min-width: 960px) {
        .dashboard-table-wrap {
            flex-grow: 1;
            min-width: 650px;
        }
    }
}

.share-top-bar {
    align-items: center;
    background-color: kw-color(kw-white, 1);
    box-shadow: 3px 0 0 kw-color(kw-white, 1);
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1080px;
    min-width: 280px;
    padding: 16px 16px 0;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 16;

    .refresh {
        padding-bottom: 10px;
        width: 100%;
    }

    .share-export-dropdowns {
        padding-bottom: 10px;
        width: 100%;
    }
}

@media (min-width: 960px) {
    .share-viewkey_action-bar {
        padding-bottom: 84px;
    }
    .share-top-bar {
        flex-flow: row nowrap;
        padding: 16px 0 0 0;

        .share-export-dropdowns {
            width: unset;
        }
    }
}
.surferseo_body {
    button.kw-button.kw-btn-primary {
        box-shadow: none !important;;
    }
    button,
    button.kw-button.kw-btn-primary {
        @extend .surferseo-button-animation;
    }
    .custom-modal {
        color: #222A3A;
        p.title {
            @extend .surferseo-font;
        }
    }
    @keyframes growOutAndTranslate {
        0% {
            transform: scale(.8) translateY(100%);
            opacity: 0;
        }

        100% {
            transform: scale(1) translateY(100%);
            opacity: 1;
        }
    }
    @keyframes growOut {
        0% {
            transform: scale(.8);
            opacity: 0;
        }

        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    .keyword-chart {
        @extend .surferseo-font;
        .chart-controls {
            .compare-competitors-input-wrap {
                button.kw-button.kw-btn-primary {
                    @extend .surferseo-font;
                    height: 32px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    padding-left: 16px;
                    padding-right: 16px;
                    background: #222A3A;
                    color: #fff;
                    &:hover {
                        background: #7934CB;
                    }
                }

                .competitor-list {
                    padding: 6px;
                    border-radius: 8px;
                    animation: growOutAndTranslate .25s cubic-bezier(.16,1,.3,1) forwards;
                    ul {
                        @extend .surferseo-font;
                        li {
                            padding: 8px 12px;
                            border-radius: 6px;
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 500;
                            color: #2D3748;
                            @extend .surferseo-button-animation;

                            &:hover {
                                background-color: #F8FAFB;
                            }
                        }
                    }
                    box-shadow: 0 0 #0000, 0 0 #0000, 0px 8px 16px 0px rgba(24, 26, 34, .04), 0px 2px 8px 0px rgba(24, 26, 34, .02), 0px 1px 2px 0px rgba(24, 26, 34, .06);
                }
            }
            button.kw-button.kw-btn-secondary {
                @extend .surferseo-button-animation;
                box-shadow: 0 0 #0000, 0 0 #0000, inset 0 0 0 1px #E2E8F0;
                border: none;
                height: 32px;
                color: #4A5568;
                border-radius: .375rem;
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                padding: .375rem 1rem;
                
                &:hover,
                &:focus {
                    background-color: #EDF2F7;
                    border: none;
                    color: #4A5568;
                }
                &:disabled {
                    cursor: not-allowed;
                    opacity: .6;
                    background-color: #FFFFFF;
                }
            }
            .keyword-chart__export-charts {
                width: 32px;
                height: 32px;
                .kw-download {
                    font-size: 15px;
                }
            }
        }
    }
    .serp-history-modal{
        @extend .surferseo-font;
        color: #222A3A;
        .header h4 {
            color: #222A3A;
        }
        button.kw-button.kw-btn-primary.show-serp-history {
            background: none;
            border: none;
            box-shadow: none;
            color: #4A5568;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            padding: 8px;
            &:hover {
                color: #2D3748 !important;
                background: none;
            }
        }
        button.kw-button.kw-btn-primary {
            @extend .surferseo-font;
            height: 32px;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            padding-left: 16px;
            padding-right: 16px;
            background: #222A3A;
            color: #fff;
            &:hover {
                background: #7934CB;
            }
            &:disabled {
                cursor: not-allowed;
                &:hover {
                    background: #222A3A;
                }
            }
        }
        .history-tables-wrapper {
            .single-table {
                .table-result.table-result_highlighted {
                    background-color: #7934CB;
                }
            }
        }
    }
    .form-control.datepicker-input{
        height: 32px;
        border-radius: 8px;
        border: 1px solid #CBD5E0;
        outline: 2px solid transparent;
        outline-offset: 2px;
        padding: 1px 12px;
        font-size: 13px;
        &:focus:focus-visible {
            outline: 2px solid #A97DDE;
        }
    }
    .date-range__label {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal;
        display: inline-block;
        font-weight: 500;
        color: #4A5568;
        margin-bottom: .375rem;
    }
    .datepicker_active .form-control.datepicker-input{
        border-color: #CBD5E0 !important;
    }
    .datepicker-body {
        font-size: 14px;
        
        .datepicker-dateRange_custom {
            span {
                color: #222A3A;
                &.datepicker-dateRange-item-disabled{
                    color: #4A5568 !important;
                }
            }
        } 
     
        .datepicker-dateRange-item-active {
            background: #222A3A !important;
            color: #fff !important;
        }

        button.kw-btn-text {
            color: #4A5568 !important;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            &:disabled {
                cursor: not-allowed;
                opacity: .6;
            }
            &:hover {
                color: #2D3748 !important;
            }
        }
    }
    .export-serp-history-csv {
        width: 100% !important;
        max-width: 600px !important;
        left: 50% !important;;
        transform: translateX(-50%);
        border-radius: 8px;
        .confirm-handler-modal {
            padding: 0;
            @extend .surferseo-font;
            .top {
                display: flex;
                padding: 24px;
                justify-content: flex-end;
                .kw-x {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .header {
                color: #222A3A;
                font-size: 16px;
                font-weight: 400;
                max-width: 80%;
                width: 100%;
                padding: 0 24px 24px 24px;
            }
            .footer {
                padding: 24px;
                border-top: 1px solid #EDF2F7;
                justify-content: flex-end;
                gap: 16px;
                button.kw-btn-text {
                    color: #4A5568 !important;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 600;
                    &:disabled {
                        cursor: not-allowed;
                        opacity: .6;
                    }
                    &:hover {
                        color: #2D3748 !important;
                        &.show-serp-history {
                            background: none !important;
                        }
                    }
                }
                .kw-button.kw-btn-primary {
                    @extend .surferseo-font;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    padding-left: 24px;
                    padding-right: 24px;
                    background: #222A3A;
                    color: #fff;
                    &:hover {
                        background: #7934CB;
                    }
                }
            }
        }
    }
    .custom-modal__header_ranking-urls {
        color: #222A3A;
        &.custom-modal__header {
            @extend .surferseo-font;
            font-size: 14px!important;
            font-weight: 400!important;
            button.kw-button.kw-btn-secondary {
                @extend .surferseo-button-animation;
                display: none;
                box-shadow: 0 0 #0000, 0 0 #0000, inset 0 0 0 1px #E2E8F0;
                border: none;
                width: 32px;
                height: 32px;
                
                &:hover {
                    background-color: #EDF2F7;
                    .kw-external-link {
                        color: #4A5568;
                    } 
                }
                &.historical-ranking-download {
                    display: flex;
                    .kw-download {
                        font-size: 15px;
                        color: #4A5568;
                    }
                }
            }
        }
    }
    .urls-table {
        .header.header_ranking-urls {
            @extend .surferseo-font;
            font-weight: 400;
            div.table-header {
                background: #fff !important;
                font-weight: 400;
                letter-spacing: .2px;
                font-size: 13px !important;
                text-transform: capitalize;
                color: #4A5568 !important;
                border-color: #EDF2F7 !important;
            }
        }
        .body .row {
            font-size: 13px !important;
            &,
            .table-data{
                border-color: #EDF2F7 !important;
                background-color: #ffffff !important;
            }
            &:nth-child(even) .table-data {
                background-color: #ffffff !important;
            }
            &:hover {
                .table-data {
                    background-color: #F8FAFB !important;
                }
            }
        }
    }
    .button-loader {
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before{
            visibility: visible;
            content: '';
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            border: 2px solid #8549D0;
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
        }
    }

    button.kw-button.kw-btn-primary {
        display: flex;
        justify-content: center;
        align-items: center;
        .button-loader {
            margin: 0;
            &::before {
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                border: 2px solid #FFFFFF;
                border-bottom-color: transparent;
            }
        }
    }
    .export-progress-bar-modal {
        .close-export-progress-bar-modal.kw-button.kw-btn-primary {
            @extend .surferseo-font;
            height: 32px;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            padding-left: 24px;
            padding-right: 24px;
            background: #222A3A;
            color: #fff;
            &:hover {
                background: #7934CB;
            }
        }
    }
    .custom-modal__body_export {
        .kw-button.kw-btn-primary {
            @extend .surferseo-font;
            height: 32px;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            padding-left: 24px;
            padding-right: 24px;
            background: #222A3A;
            color: #fff;
            &:hover {
                background: #7934CB;
            }
        }
    }
    .table-pagination {
        button.kw-button.kw-btn-secondary.separate-btn {
            @extend .surferseo-font;
            @extend .surferseo-button-animation;
            color: #4A5568;
            border-radius: .3rem;
            box-shadow: 0 0 #0000, 0 0 #0000, inset 0 0 0 1px #E2E8F0;
            border: none;
            &:hover {
                background-color: #EDF2F7;
            }
        }
        .centered-buttons{
            .kw-button{
                @extend .surferseo-font;
                font-size: 14px;
                font-weight: 600;
                &.kw-btn-primary {
                    background: #222A3A;
                    color: #fff;
                    &:hover {
                        background: #7934CB;
                    }
                }
                &.kw-btn-secondary{
                    @extend .surferseo-button-animation;
                    color: #4A5568;
                    border-radius: .3rem;
                    box-shadow: 0 0 #0000, 0 0 #0000, inset 0 0 0 1px #E2E8F0;
                    border: none;
                    &:hover {
                        background-color: #EDF2F7;
                    }
                }
            }
        }
    }
    .keywords-table-by-ranking-url {
        @extend .surferseo-font; 
        .custom-modal__header {
            @extend .surferseo-font; 
        }
        
    }
    .dashboard-action-bar {
        button.kw-button.kw-btn-text {
            width: 20px;
            height: 20px;
            .kw {
                color: #4A5568 !important;
                font-size: 18px;
                line-height: 20px;
                font-weight: 600;
            }
            &:disabled {
                cursor: not-allowed;
                opacity: .6;
            }
            &:hover {
                .kw {
                    color: #2D3748 !important;
                }
            }
        }
        .datepicker-dateControl {
            margin-top: 8px;
            button.kw-button.kw-btn-text.button-transparent_datepicker {
                width: auto;
            }
        }
        
        .keyword_filters_section .keyword_filters_item.search-block{
            height: 32px!important;
        }
        .keyword-search-input-wrap {
            min-width: 32px;
            min-height: 32px;
            border-radius: .375rem;
            .search_input_icon {
                width: 32px;
                height: 32px;
                &:hover {
                    opacity: 1;
                }
            }
            .kw-x,
            .kw-search {
                top: 50%;
                left: 50%;
                width: 30px;
                height: 30px;
                transform: translate(-50%, -50%);
                font-size: 16px;
                color: #4A5568;
                border-radius: .3rem;
                @extend .surferseo-button-animation;
                &:hover {
                    background-color: #EDF2F7;
                }
                &::before {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .top-bar__input-search {
                height: 30px;
                margin: 1px;
            }

            box-shadow: 0 0 #0000, 0 0 #0000, inset 0 0 0 1px #E2E8F0;
            border: none;
        }
        .keyword_filters_item {
            button {
                @extend .surferseo-button-animation;
                &:disabled {
                    cursor: not-allowed;
                    opacity: .6;
                }
            }
            &>div>button.kw-button.kw-btn-secondary {
                width: 32px;
                height: 32px;
                box-shadow: 0 0 #0000, 0 0 #0000, inset 0 0 0 1px #E2E8F0;
                border: none;
                font-size: 16px;
                &:hover {
                    background-color: #EDF2F7;
                }
                .kw {
                    color: #4A5568;
                }
            }

            .custom_dropdown_body {
                @extend .surferseo-font;
                .filter {
                    .kw-btn-primary {
                        height: 32px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        background: #222A3A;
                        color: #ffffff;
                        &:hover {
                            background: #7934CB;
                        }
                    }
                }
            }
        }
    }
    .ag-header-cell,
    .top-pages-table-header .top-pages-table-header__name,
    .custom-table__section_head .custom-table__cell {
        @extend .surferseo-font;
        font-size: 13px !important;
        font-weight: 400 !important;
        color: #4A5568 !important;
        
    }
    .keywords-new-table .ag-theme-alpine .ag-header .ag-header-cell {
        border-right: 1px solid #EDF2F7;
    }

    .ag-cell-value {
        @extend .surferseo-font;
        font-size: 14px !important;
        font-weight: 400 !important;
        color: #222A3A !important;
    }

    .ag-header,
    .top-pages-table-header .top-pages-table-cell,
    .custom-table__section_head {
        background-color: #ffffff;
    }
    .keywords-new-table {
        .ag-row.ag-row-even {
            background-color: #ffffff;
        }
        .ag-row.ag-row-odd {
            background-color: #ffffff;
        }

        .ag-row-selected:before,
        .ag-row-hover:before {
            background-color: #F8FAFB;
        }
        .ag-row {
            .table-cell__actions {
                .actions-class__action-container {
                    &>a .kw,
                    button.kw-button.kw-btn-text {
                        font-size: 12px;
                        color: #4A5568 !important;
                        &:hover {
                            color: #2D3748 !important;
                        }
                    }
                    &>a .kw {
                        font-size: 11px;
                    }
                }
            }
            border-bottom: 1px solid #EDF2F7;
            &:last-child {
                border-bottom: none;
            }
        }
    }
    .top-pages-table {
        margin-top: 8px;
        .top-pages-table-cell {
            border-color: #EDF2F7;
        }
    }
    .top-competitors {
        .custom-table__cell {
            border-color: #EDF2F7;
        }
    }
    .refresh__header {
        .last-refreshed  {
            color: #4A5568;
        }
    }
    .dashboard-block .change-direction {
        color: #222A3A;
    }
    .project-overview__chart-container {
        .project-overview__chart-header {
            @extend .surferseo-font;
            color: #222A3A;
        }
    }
   
}
</style>

<style scoped lang="scss">
    .surferseo-button-animation {
        transition-property: color, background-color, box-shadow, opacity!important;
        transition-timing-function: cubic-bezier(.4,0,.2,1)!important;
        transition-duration: .15s!important;
    }
    .surferseo-font {
        font-family: "Inter", sans-serif !important;
    }
    .surferseo {
        @extend .surferseo-font;
        color: #222A3A;
        /deep/ .ag-header-cell,
        /deep/ .top-pages-table-header .top-pages-table-header__name,
        /deep/ .custom-table__section_head .custom-table__cell {
            @extend .surferseo-font;
            font-size: 13px !important;
            font-weight: 400 !important;
            color: #4A5568 !important;
        }

        /deep/ .ag-cell-value {
            @extend .surferseo-font;
            font-size: 14px !important;
            font-weight: 400 !important;
            color: #222A3A !important;
        }

        /deep/ .ag-header,
        /deep/ .top-pages-table-header .top-pages-table-cell,
        /deep/ .custom-table__section_head {
            background-color: #ffffff;
        }
        /deep/ .keywords-new-table {
            .ag-row.ag-row-even {
                background-color: #ffffff;
            }
            .ag-row.ag-row-odd {
                background-color: #ffffff;
            }

            .ag-row-selected:before,
            .ag-row-hover:before {
                background-color: #F8FAFB;
            }
            .ag-row {
                .table-cell__actions {
                    .actions-class__action-container {
                        &>a .kw,
                        button.kw-button.kw-btn-text {
                            font-size: 12px;
                            color: #4A5568 !important;
                            &:hover {
                                color: #2D3748 !important;
                            }
                        }
                        &>a .kw {
                            font-size: 11px;
                        }
                    }
                }
                border-bottom: 1px solid #EDF2F7;
            }
        } 

        /deep/ .top-pages-table-body {
            .top-pages-table-row .top-pages-table-cell {
                @extend .surferseo-font;
                font-size: 14px !important;
                font-weight: 400 !important;
                color: #222A3A !important;
            }
            .top-pages-table-row.top-pages-table-row-even {
                background-color: #ffffff;
            }
            .top-pages-table-row {
                background-color: #ffffff;
                &:hover {
                    background-color: #F8FAFB;
                }
            }
        } 
        /deep/ .custom-table__section_body {
            @extend .surferseo-font;
            font-size: 14px !important;
            font-weight: 400 !important;
            color: #222A3A !important;
            .chart-value {
                @extend .surferseo-font;
                font-size: 12px !important;
                font-weight: 400 !important;
                color: #222A3A !important;
            }

            .custom-table__row{
                background-color: #ffffff;
                &:nth-child(even) {
                    background-color: #ffffff;
                }
                &:hover {
                    background-color: #F8FAFB;
                }
            }
        }
        
        /deep/ .share-dropdown {
            .kw-btn-secondary {
                @extend .surferseo-button-animation;
                box-shadow: 0 0 #0000, 0 0 #0000, inset 0 0 0 1px #E2E8F0;
                border: none;
                height: 32px;
                color: #4A5568;
                border-radius: .375rem;
                padding: .375rem 1rem;
                &:hover,
                &:focus {
                    background-color: #EDF2F7;
                    border: none;
                    color: #4A5568;
                }
                .kw {
                   font-size: 15px;
                }
            }
            .viewkey-item__input {
                .kw-button.kw-btn-primary {
                    background: #222A3A;
                    color: #ffffff;
                    &:hover {
                        background: #7934CB;
                    }
                }
            }
        }
        /deep/ .custom-table__head-buttons {
            .kw-btn-text {
                @extend .surferseo-button-animation;
                box-shadow: 0 0 #0000, 0 0 #0000, inset 0 0 0 1px #E2E8F0 !important;
                border: none !important;
                color: #4A5568 !important;
                &:hover {
                    background: #EDF2F7 !important;
                }
                .button-loader {
                    margin: 0;
                    &::before {
                        width: 10px;
                        height: 10px;
                        min-width: 10px;
                        min-height: 10px;
                        border: 2px solid #222A3A;
                        border-bottom-color: transparent;
                    }
                }
            }
        }
        /deep/ .date-ranges-dropdown {
            .kw-button {
                @extend .surferseo-button-animation;
                padding: 4px 12px;
                font-weight: 600;
                height: 24px !important;
                font-size: 13px !important;
                box-shadow: 0 0 #0000, 0 0 #0000, inset 0 0 0 1px #E2E8F0 !important;
                border: none !important;
                color: #4A5568 !important;
                &:hover {
                    background: #EDF2F7 !important;
                }
            }
            .v-context {
                padding: 6px;
                border-radius: 8px;
                animation: growOut .25s cubic-bezier(.16,1,.3,1) forwards;
                @extend .surferseo-font;
                box-shadow: 0 0 #0000, 0 0 #0000, 0px 8px 16px 0px rgba(24, 26, 34, .04), 0px 2px 8px 0px rgba(24, 26, 34, .02), 0px 1px 2px 0px rgba(24, 26, 34, .06);

                &>li {
                    &>a {
                        padding: 8px 12px;
                        border-radius: 6px;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 500;
                        color: #2D3748!important;
                        @extend .surferseo-button-animation;
                        &:hover {
                            background-color: #F8FAFB;
                        }
                    }
                }
            }
        }
    }
</style>
