<template>
    <div class="mapping-data-wrapper">
        <div v-if="loading" :class="`placeholder placeholder_animate`">
            <div class="placeholder__item placeholder__item_suggestions">
                <div
                    v-for="i in 7"
                    :key="i"
                    :class="`placeholder__suggestions placeholder__suggestions-keywords`"
                >
                    <div v-for="j in 11" :key="j" />
                </div>
            </div>
        </div>
        <template v-else-if="!errored">
            <span v-text="$t('match-your-csv')" class="match-your-csv" />
            <div class="mapping-table">
                <new-table-with-header
                    :columnDefs="columns"
                    :defaultColDef="defaultColumns"
                    :rowData="rowsData"
                    :style="'width: 100%; height: 373px;font-size: 12px;'"
                    :fileName="fileName"
                    :totalRowsCount="totalRowsCount"
                    @onCellValueChanged="onCellValueChanged"
                />
            </div>
            <span
                class="duplicated-keywords"
                v-text="$t('what-do-duplicated-keywords')"
            />
            <div class="radio-btn-group">
                <div>
                    <input
                        id="existing-data"
                        type="radio"
                        v-model="overwriteDuplicates"
                        :value="true"
                        checked
                    />
                    <label for="existing-data">
                        {{ $t('existing-data') }}
                    </label>
                </div>
                <div>
                    <input
                        id="do-not-import"
                        type="radio"
                        v-model="overwriteDuplicates"
                        :value="false"
                    />
                    <label for="do-not-import">
                        {{ $t('do-not-import-the-data') }}
                    </label>
                </div>
            </div>
            <div class="btn-group">
                <kw-button
                    button-type="primary"
                    :disabled="updating"
                    :loading="updating"
                    :button-text="$t('next')"
                    :data-cy="'mapping-data-next-btn'"
                    @click="nextClickHandler"
                />
                <kw-button
                    button-type="secondary"
                    :disabled="updating"
                    :button-text="$t('cancel')"
                    :data-cy="'mapping-data-cancel-btn'"
                    @click="cancelClickHandler"
                />
            </div>
        </template>
        <span v-else v-text="$t('something-went-wrong')" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Events, EventBus } from '@/events';
import { IMPORT_DATA_STEPS } from '@/constants';
import NewTableWithHeader from './NewTableWithHeader';
import YourFieldEditor from './custom-editors/YourFieldEditor';
import StatusRenderer from './custom-renderers/StatusRenderer';
import YourFieldRenderer from './custom-renderers/YourFieldRenderer';

export default {
    name: 'MapHeadings',
    props: {
        headings: {
            type: Object,
            required: true,
        },
        fileName: {
            type: String,
            required: true,
        },
        totalRowsCount: {
            type: Number,
            required: true,
        },
    },
    components: {
        NewTableWithHeader,
        YourFieldEditor,
        YourFieldRenderer,
        StatusRenderer,
    },
    data() {
        return {
            loading: true,
            errored: false,
            updating: false,
            rowsData: [],
            overwriteDuplicates: true,
            matchedHeadings: {},
            sampleData: {},
            defaultColumns: {
                flex: 1,
            },
            columns: [
                {
                    field: 'kw_field',
                    headerName: this.$t('keyword-field'),
                    cellStyle: { 'font-weight': '500' },
                },
                {
                    field: 'sample_data',
                    headerName: this.$t('sample-data'),
                    cellStyle: { 'font-style': 'italic' },
                    cellRenderer: params =>
                        this.sampleData[params.data.your_field],
                },
                {
                    field: 'your_field',
                    headerName: this.$t('your-field'),
                    editable: true,
                    singleClickEdit: true,
                    cellEditor: 'YourFieldEditor',
                    cellRenderer: 'YourFieldRenderer',
                },
                {
                    field: 'status',
                    headerName: this.$t('status'),
                    cellRenderer: 'StatusRenderer',
                },
            ],
        };
    },
    mounted() {
        try {
            this.matchedHeadings = _.clone(this.headings.matched_headings);
            this.sampleData = _.clone(this.headings.sample_data);

            this.columns[2].cellEditorParams = {
                values: [null, ...Object.values(this.headings.file_headings)],
            };

            for (let key in this.matchedHeadings) {
                this.rowsData.push({
                    kw_field: key,
                    your_field: this.matchedHeadings[key],
                });
            }

            this.loading = false;
            EventBus.emit(Events.IMPORT_MAPPING_VIEW_EVENT, {
                num_keywords: this.totalRowsCount,
                fields_filled: Object.keys(
                    this.headings.matched_headings,
                ).filter(el => this.headings.matched_headings[el] !== null)
                    .length,
            });
        } catch (e) {
            this.errored = true;
        }
    },
    methods: {
        ...mapActions(['updateHeaders']),
        onCellValueChanged(event) {
            this.matchedHeadings[event.data.kw_field] = event.data.your_field;
            var row = event.api.getDisplayedRowAtIndex(event.rowIndex);
            event.api.redrawRows({ rowNodes: [row] });
        },
        async nextClickHandler() {
            this.updating = true;
            this.$emit('step-down', {
                step: IMPORT_DATA_STEPS.STEP_2,
                cancellation: true,
            });

            try {
                let data = {
                    id: this.headings.import_activity_log_id,
                    tableData: {
                        overwrite_duplicated_keywords: this.overwriteDuplicates,
                        matched_headings: this.matchedHeadings,
                    },
                };

                const res = await this.updateHeaders(data);

                if (res) {
                    this.$emit('step-up', IMPORT_DATA_STEPS.STEP_3);
                }
            } catch (error) {
                return false;
            } finally {
                this.updating = false;
            }
        },
        cancelClickHandler() {
            this.$emit('step-down', {
                step: IMPORT_DATA_STEPS.STEP_1,
                cancellation: true,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.mapping-data-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    gap: 16px;
    font-family: 'Roboto';

    .match-your-csv {
        font-weight: 500;
        font-size: 13px;
    }

    .mapping-table {
        ::v-deep .ag-theme-alpine {
            --ag-font-size: 12px;
            --ag-header-foreground-color: #0074d9;
            --ag-cell-horizontal-border: 2px solid #edeef0;
            --ag-background-color: #fcfcfc;
            --ag-header-background-color: #fcfcfc;

            .status-check {
                font-weight: 500;
                color: #41a63d;
            }

            .status-reject {
                font-size: 11px;
                font-weight: bold;
                color: #cb1a02;
            }
        }
    }

    .duplicated-keywords {
        font-weight: 500;
        font-size: 13px;
    }

    .radio-btn-group {
        display: flex;
        width: 95%;
        gap: 12px;

        div {
            display: flex;
            align-items: flex-start;
            gap: 8px;

            label {
                font-size: 13px;
                font-weight: 400;
            }
        }
    }

    .btn-group {
        display: flex;
        gap: 2%;
    }
}
</style>
