<template>
    <new-table
        v-bind="$props"
        v-on="$listeners"
    >
        <header-description
            :totalRows="$t('total-rows')"
            :totalRowsCount="totalRowsCount"
            :fileName="fileName"
        />
    </new-table>
</template>

<script>
import HeaderDescription from './HeaderDescription';
import NewTable from '@/components/new-table/NewTable';

export default {
    name: 'NewTableWithHeader',
    components: { NewTable, HeaderDescription },
    props: {
        columnDefs: {
            type: Array,
            required: true,
        },
        defaultColDef: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
        gridOptions: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
        rowData: {
            type: Array,
            required: true,
        },
        componentStyle: {
            type: String,
            default: 'width: 100%; height: 100%;',
        },
        columnTypes: {
            type: Object,
            default: null,
        },
        fileName: {
            type: String,
            required: true,
        },
        totalRowsCount: {
            type: Number,
            required: true,
        },
    },
};
</script>
