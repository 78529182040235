<template>
    <div
        class="screen__content screen__content_keyword add-keywords-web"
        data-cy="add-keywords-web-domain"
    >
        <div class="screen__column">
            <div class="screen__field screen__field_keyword keyword-url-wrap">
                <label class="text-label" for="keyword-domain">
                    {{ $t('enter-url') }}
                    <span class="req">*</span>
                </label>
                <div class="url-row">
                    <url-input
                        ref="urlInput"
                        :urlList="getSuggestedUrls(apiKeywordTypes)"
                        :disabled="newKeyword.isBulkImport"
                        :domain="newKeyword.url_tracking_method"
                        :value="newKeyword.url"
                        @handleInput="handleInput"
                        @urlInputHandler="urlInputHandler"
                        @setDropItem="setDropItem"
                        :err="err"
                    />
                    <span
                        class="kw kw-info"
                        @mouseover="
                            tooltip({
                                text: $t('broad-domain-tooltip'),
                                event: $event,
                                maxWidth: 248
                            })
                        "
                    />
                </div>
            </div>
            <div class="screen__field ignore-sub-domain">
                <toggler
                    :toggler="newKeyword.ignore_sub_domains"
                    @toggler_click="toggleIgnoreSubDomains"
                />
                <span>{{ $t('ignore-sub-domains') }}</span>
                <span
                    class="kw kw-info"
                    @mouseover="
                        tooltip({
                            text: $t('ignore-sub-domains-tooltip'),
                            event: $event,
                            maxWidth: 248
                        })
                    "
                />
            </div>
            <div class="keyword-level-tags screen__field_keyword">
                <div class="tags-wrap">
                    <label for="tags-input" class="tags-label">
                        <span>Tags</span>
                        <span
                            class="kw kw-info"
                            @mouseover="
                                tooltip({
                                    text: $t('tags-tooltip'),
                                    event: $event,
                                })
                            "
                        />
                        <span class="required-hint">{{ $t('optional') }}</span>
                    </label>
                    <div class="tags-input-wrap">
                        <span class="kw kw-delete" />
                        <vue-tags-input
                            id="tags-input"
                            v-model="tag"
                            data-cy="tags-input"
                            :autocomplete-items="filteredItems"
                            :tags="tags"
                            :disabled="isTagsInputDisabled"
                            placeholder=""
                            :class="getTagInputClasses"
                            @tags-changed="updateTags"
                        />
                    </div>
                </div>
            </div>

            <div
                class="screen__field screen__field_keyword screen__field_toggler keyword-type-wrap"
            >
                <span class="keyword-type-title">
                    {{ $t('keywords') }}
                    <span class="req">*</span>
                    <span
                        class="kw kw-info"
                        @mouseover="
                            tooltip({
                                text: $t('keywords-tooltip'),
                                event: $event,
                                maxWidth: 248
                            })
                        "
                    />
                </span>
                <label data-cy="bulk-label" class="text-label">
                    <toggler
                        :toggler="newKeyword.isBulkImport"
                        @toggler_click="toggleBulkImport"
                    />

                    <span
                        class="bulk-title"
                        @click="toggleBulkImport"
                        @mouseover="
                            tooltip({
                                text: $t('bulk-import-tooltip'),
                                event: $event,
                            })
                        "
                        v-text="$t('bulk-add')"
                    />
                </label>
            </div>
            <div
                class="screen__field screen__field_keyword keywords-seggestions-wrap"
            >
                <form autocomplete="off" class="keywords-wrap" @submit.prevent>
                    <div class="keywords-teaxtarey-wrap">
                        <div
                            :class="keywordsTextareaPlaceholderClasses"
                            data-cy="placeholder-keywords-area"
                        >
                            <template v-if="!newKeyword.isBulkImport">
                                {{ $t('input-one-keyword-per-line') }}
                            </template>
                            <template v-else>
                                <div
                                    v-text="`${this.$t('bulk-tags-example')}`"
                                />
                                <div
                                    v-text="`${this.$t('bulk-tags-example')}`"
                                />
                                <div
                                    v-text="`${this.$t('bulk-tags-example')}`"
                                />
                                <div
                                    v-text="`${this.$t('bulk-tags-example')}`"
                                />
                            </template>
                        </div>
                        <textarea
                            id="keywords"
                            v-model.trim="textarea"
                            :class="keywordsClasses"
                            data-cy="add-keyword-web-keyword-textarea"
                            @input="keywordsInputHandler"
                        />
                    </div>
                    <suggestions-component
                        :show="isMobile"
                        :new-keyword="newKeyword"
                        :show-suggested-keywords="showSuggestedKeywords"
                        :add-all-suggested-into-keywords="
                            addAllSuggestedIntoKeywords
                        "
                        :toggle-keyword-source="MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.PROJECT_DASHBOARD"
                        :toggle-keyword="toggleKeyword"
                    />
                    <div class="region-language-wrap">
                        <div
                            class="screen__field screen__field_keyword screen__field_select-region"
                        >
                            <label class="text-label" for="region">
                                <span>{{ $t('google-region') }}</span>
                                <span
                                    class="kw kw-info"
                                    @mouseover="
                                        tooltip({
                                            text: $t('region-tooltip'),
                                            event: $event,
                                        })
                                    "
                                />
                            </label>
                            <custom-v-select
                                id="region"
                                class="dropdown-region-lang"
                                :class="{
                                    'custom-dropdown_filled':
                                        newKeyword.region.value,
                                }"
                                data-cy="add-keyword-web-region"
                                dropdown-search-data-cy="region-search-field"
                                dropdown-menu-data-cy="parent-dropdown-menu"
                                :options="
                                    googleRegions(
                                        getSuggestedRegions,
                                        regionsForSelect,
                                    )
                                "
                                options-label="name"
                                select-type="region"
                                options-value="value"
                                :placeholder="$t('region-tooltip')"
                                :value.sync="newKeyword.region.value"
                                search
                                @change="selectRegionValue"
                                @selected="selectRegionLabel"
                            />
                        </div>
                        <span class="diveder" />
                        <div
                            class="screen__field screen__field_keyword screen__field_select-language"
                        >
                            <label class="text-label" for="language">
                                <span>{{ $t('google-language') }}</span>
                                <span
                                    class="kw kw-info"
                                    @mouseover="
                                        tooltip({
                                            text: $t('language-tooltip'),
                                            event: $event,
                                        })
                                    "
                                />
                            </label>
                            <custom-v-select
                                id="language"
                                class="dropdown-region-lang"
                                data-cy="add-keyword-web-language"
                                dropdown-search-data-cy="language-search-field"
                                dropdown-menu-data-cy="language-dropdown-menu"
                                options-label="name"
                                options-value="value"
                                search-key="name"
                                select-type="language"
                                :placeholder="$t('language-tooltip')"
                                search
                                :class="{
                                    'custom-dropdown_filled':
                                        newKeyword.language.value,
                                }"
                                :options="
                                    googleLanguages(
                                        getSuggestedRegions,
                                        languagesForSelect,
                                    )
                                "
                                :value.sync="newKeyword.language.value"
                                @change="selectLanguageValue"
                            />
                        </div>
                    </div>
                    <div class="device-sov-wrap">
                        <div
                            class="screen__field screen__field_keyword screen__field_select-device"
                        >
                            <label class="text-label" for="device">
                                <span>{{ $t('devices') }}</span>
                            </label>
                            <select-platform
                                :platform="newKeyword.platform"
                                :disableMobile="getIsFreePlan"
                                @setPlatform="setPlatform"
                            />
                        </div>
                    </div>
                    <add-locations
                        :add-location="addLocation"
                        autocomplete-type="web-domain-location"
                        :locations="newKeyword.geolocation.location"
                        ref-name="newWebKeywordLocation"
                        :remove-location="removeLocation"
                        :set-location="setLocation"
                    />
                    <div class="option-wrap">
                        <div class="ignore-location-wrap">
                            <label
                                data-cy="add-keyword-web-listings-label"
                                class="text-label location-toggler"
                            >
                                <toggler
                                    :toggler="
                                        newKeyword.geolocation.ignoreLocal
                                    "
                                    @toggler_click="toggleIgnoreLocalListings"
                                />
                                <span
                                    @click="toggleIgnoreLocalListings"
                                    @mouseover="
                                        tooltip({
                                            text: $t('ignore-google-places'),
                                            event: $event,
                                        })
                                    "
                                    v-text="$t('ignore-local-listings')"
                                />
                                <span
                                    class="kw kw-info"
                                    @mouseover="
                                        tooltip({
                                            text: `<p>${$t('ignore-tooltip')}
                                                        <a class='link' href='https://support.keyword.com/en/articles/4374805-tracking-by-location-google-maps'
                                                        target='_blank'>${$t('learn-more')}</a>
                                                   </p>`,
                                            event: $event,
                                            interactive: true,
                                        })
                                    "
                                />
                            </label>
                        </div>
                    </div>
                    <gmb-autocomplete-input
                        v-if="!newKeyword.geolocation.ignoreLocal"
                        ref="webGMBInputRef"
                        :geotype="geotype"
                        :place-changed-handler="getGMBDataForNewKeyword"
                        :input-change-handler="handleGmbChange"
                        :keyword-type="keywordType"
                        :keyword-draft="getKeywordDraft(keywordType)"
                        :on-blur-gmb="onBlurGMB"
                        wrap-class="screen__field screen__field_keyword ignore-location-field-wrap"
                    />

                    <exclude-from-sov
                        :value="!newKeyword.includedInSov"
                        @toggleExcludeSov="toggleExcludeSov"
                    />

                    <div class="button-wrap">
                        <kw-button
                            :button-text="
                                $t('plus-add-keywords-count', {
                                    count: keywordsCount,
                                })
                            "
                            button-type="primary"
                            prepend-icon-class="kw kw-plus"
                            class="screen__add-button gmt-add-keyword-btn"
                            data-cy="add-keyword-web-save"
                            :loading="getKeywordAdding"
                            :disabled="isAddButtonActive"
                            @click="handleAddKeywordsClick"
                        />
                    </div>
                </form>
                <suggestions-component
                    :show="!isMobile"
                    :new-keyword="newKeyword"
                    :show-suggested-keywords="showSuggestedKeywords"
                    :add-all-suggested-into-keywords="
                        addAllSuggestedIntoKeywords
                    "
                    :toggle-keyword-source="MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.PROJECT_DASHBOARD"
                    :toggle-keyword="toggleKeyword"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueSimpleSuggest from 'vue-simple-suggest';
import VueTagsInput from '@johmun/vue-tags-input';
import { SET_SUGGESTED_REGIONS, SET_SUGGESTED_URLS } from '@/store/mutations';

import { domainOptions, platformOptions, newDomainOptions } from '../configs';
import { autocompleteStyles, boldenSuggestion } from '../utils';

import DropdownsMixin from '@/mixins/DropdownsMixin';
import GoogleApiMixin from '@/mixins/GoogleApiMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import AddKeywordsMixin from '@/mixins/AddKeywordsMixin';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';

import { isMobileWidth } from '@/helpers/is-mobile-width';
import {
    regionsForSelect,
    languagesForSelect,
    languages,
} from '@/helpers/regions-service';

import {
    DEFAULT_REGION,
    TYPING_DEBOUNCE,
    DEFAULT_LANGUAGE,
    MIXPANEL_EVENT_CONSTANTS,
} from '@/constants';

import { Events, EventBus } from '@/events';
import AddLocations from '@/components/add-keywords/AddLocations';
import SuggestionsComponent from './SuggestionsComponent';
import BulkConfirmationModal from 'components/modals/BulkConfirmationModal';
import URLInput from '@/components/common/URLInput.vue';
import SelectPlatform from '@/components/common/SelectPlatform.vue';
import ExcludeFromSov from './ExcludeFromSov.vue';

const GMBAutocompleteInput = () => import('./GMBAutocompleteInput');
const AddKeywordSelector = () => import('./AddKeywordSelector');

export default {
    name: 'WebDomainTab',
    components: {
        'add-locations': AddLocations,
        'gmb-autocomplete-input': GMBAutocompleteInput,
        'suggestions-component': SuggestionsComponent,
        'add-keyword-selector': AddKeywordSelector,
        'vue-simple-suggest': VueSimpleSuggest,
        'vue-tags-input': VueTagsInput,
        'url-input': URLInput,
        'select-platform': SelectPlatform,
        'exclude-from-sov': ExcludeFromSov
    },
    mixins: [
        GoogleApiMixin,
        AddKeywordsMixin,
        ToolTipRenderMixin,
        ValidationMixin,
        DropdownsMixin,
    ],
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            keywordType: 'web',
            apiKeywordTypes: ['se', 'sem'],
            selectedSeggestins: '',
            numbers: 10,
            dropMenuStatuses: {
                domain: false,
                region: false,
                language: false,
                options: false,
                platform: false,
                tags: false,
            },
            regionsForSelect,
            languagesForSelect,
            domainOptions,
            newDomainOptions,
            newKeyword: {
                url: '',
                domain: false,
                isBulkImport: false,
                platform: 'desktop',
                region: {
                    name: null,
                    value: DEFAULT_REGION,
                },
                language: {
                    name: null,
                    value:
                        navigator.language.toLowerCase() ||
                        navigator.userLanguage.toLowerCase() ||
                        'en',
                },
                geolocation: {
                    changeLocation: false,
                    location: [''],
                    ignoreLocal: true,
                    isManualGmb: false,
                    GMB: '',
                },
                keywords: [],
                tags: [],
                includedInSov: true,
                url_tracking_method: 'broad',
                ignore_sub_domains: false
            },
            textarea: '',
            isKeywordAdded: false,
            tags: [],
            tag: '',
            draftSettedNoSuggestions: false,
            platformOptions,
            tagsFromBulkAsPartOfKeyword: false,
            err: {
                url: {},
                keywords: {},
            },
            boldenSuggestion,
            autocompleteStyles,
            debouncedHandler: _.debounce(fn => fn(), TYPING_DEBOUNCE),
            MIXPANEL_EVENT_CONSTANTS,
        };
    },
    computed: {
        ...mapGetters([
            'getActiveProjects',
            'getActiveTag',
            'getCurrentProject',
            'getKeywordAdding',
            'getKeywordDraft',
            'getOriginalItems',
            'getSuggestedRegions',
            'getSuggestedUrls',
            'getSuggestionsPaginated',
            'getIsFreePlan'
        ]),
        keywordsCount() {
            const kwLength = this.newKeyword.keywords.length;
            const kwsLength = this.newKeyword.keywords.join('\n').length;
            const locationLength = this.newKeyword.geolocation.location.length;
            return kwLength && kwsLength
                ? locationLength *
                      (this.newKeyword.platform === 'desktop-mobile'
                          ? kwLength * 2
                          : kwLength)
                : 0;
        },
        isMobile() {
            return isMobileWidth();
        },
        showSuggestedKeywords() {
            return (
                !!this.newKeyword.keywords.join('\n').length &&
                !this.newKeyword.isBulkImport &&
                !!this.getSuggestionsPaginated.length
            );
        },
        filteredItems() {
            return this.presetTags.filter(el =>
                el.text.toLowerCase().includes(this.tag.toLowerCase()),
            );
        },
        presetTags() {
            const tags = [];
            this.getCurrentProject &&
                this.getCurrentProject.tags.forEach(tag => {
                    if (tag && !_.find(tags, ['text', tag]))
                        tags.push({ text: tag.name });
                });

            return tags;
        },
        // computed classes
        keywordDomainClasses() {
            return {
                'text-input': true,
                'text-input_invalid': this.err.url.text,
                'domain-filed': true,
            };
        },
        keywordsClasses() {
            return {
                'text-input': true,
                screen__textarea_keywords: true,
                'text-input_invalid': this.err.keywords.text,
                'custom-scroll': true,
                'keywords-table__row-input': true,
            };
        },
        isAddButtonActive() {
            const isError = Object.keys(this.err).reduce(
                (acc, key) => (this.err[key].text ? true : acc),
                false,
            );
            let keywordsLength = 0;
            this.newKeyword.keywords.map(
                item => (keywordsLength += item.length),
            );
            return (
                isError ||
                (!this.newKeyword.url.length &&
                    !this.newKeyword.isBulkImport) ||
                (!this.newKeyword.isBulkImport && !keywordsLength)
            );
        },
        keywordsTextareaPlaceholderClasses() {
            return {
                'keywords-textarea__placeholder': true,
                'keywords-textarea__placeholder_transparent': this.textarea
                    .length,
            };
        },

        isTagsInputDisabled() {
            return this.newKeyword.isBulkImport;
        },

        getTagInputPlaceholder() {
            return this.isTagsInputDisabled ? '' : 'Add Tag';
        },

        getTagInputClasses() {
            return {
                disabled_tag_input: this.isTagsInputDisabled,
            };
        },
    },
    watch: {
        getActiveProjects: 'fetchKeywordsWithTags',
        getCurrentProject: 'emitViewEvent',
        active: 'emitViewEvent',
        getActiveTag(val) {
            if (val.id) {
                this.tags = [{ text: val.name, tiClasses: ['ti-valid'] }];
            }
        },
        getIsFreePlan() {
            this.newKeyword.platform = this.getIsFreePlan ? 'desktop' : 'desktop-mobile';
        }
    },
    created() {
        this.unSubscribe = this.$store.subscribe(({ type }) => {
            if (type === SET_SUGGESTED_REGIONS) {
                this.setDraftOrDefaultValues();
            }

            if (type === SET_SUGGESTED_URLS) {
                this.setSingleDomain();
            }
        });
    },
    mounted() {
        const lastDevice = window.localStorage.getItem('lastDevice');
        if (lastDevice) {
            this.newKeyword.platform = lastDevice;
        }
        this.fetchKeywordsWithTags();
        this.setDraftOrDefaultValues();
        if (this.getActiveTag.id) {
            this.tags = [
                { text: this.getActiveTag.name, tiClasses: ['ti-valid'] },
            ];
        }

        this.emitViewEvent();
        if (this.getIsFreePlan) {
            this.newKeyword.platform = 'desktop';
        }
    },
    beforeDestroy() {
        if (typeof this.unSubscribe === 'function') {
            this.unSubscribe();
        }
    },
    methods: {
        ...mapActions([
            'fetchKeywords',
            'getSuggestions',
            'fetchSuggestions',
            'addKeyword',
            'setKeywordDraft',
        ]),
        emitViewEvent() {
            if (!this.getCurrentProject || !this.active) {
                return;
            }

            EventBus.emit(Events.ADD_KEYWORD_GOOGLE_VIEW_EVENT, {
                project_name: this.getCurrentProject?.name,
                leading_source:
                    MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.PROJECT_DASHBOARD,
            });
        },
        setSingleDomain() {
            if (
                this.getSuggestedUrls(this.apiKeywordTypes).length === 1 &&
                (!this.getKeywordDraft(this.keywordType) ||
                    this.getKeywordDraft(this.keywordType).url === '')
            ) {
                this.$refs.urlInput.onSelect(this.getSuggestedUrls(this.apiKeywordTypes)[0]);
            }
        },
        setDraftOrDefaultValues() {
            if (
                this.getKeywordDraft(this.keywordType) &&
                !_.isEqual(
                    this.getKeywordDraft(this.keywordType),
                    this.newKeyword,
                )
            ) {
                this.newKeyword = this.getKeywordDraft(this.keywordType);
                this.textarea = this.newKeyword.keywords.join('\n');
                this.fetchSuggestions(this.newKeyword.keywords);
                if (this.newKeyword.tags) {
                    this.tags = this.newKeyword.tags.map(el => ({
                        text: el.text,
                        tiClasses: ['ti-valid'],
                    }));
                }

                if (!this.newKeyword.isBulkImport) {
                    this.urlInputHandler();
                }

                this.draftSettedNoSuggestions = true;
            }

            if (!this.draftSettedNoSuggestions) {
                const firstRegion = this.getSuggestedRegions(
                    this.apiKeywordTypes,
                )[0];

                if (firstRegion) {
                    this.newKeyword.region.value = firstRegion.region;
                    // nextTick because of autoselect of language
                    this.$nextTick(() => {
                        this.newKeyword.language.value =
                            languages.find(
                                lang =>
                                    lang.indexOf(`${firstRegion.lang} `) === 0,
                            ) || DEFAULT_LANGUAGE;
                    });
                } else {
                    this.newKeyword.region.value = DEFAULT_REGION;
                }
            }
        },
        buttonIconText(value, arr, status) {
            const item = arr.find(el => el.value === value);
            return `<span class="${
                status ? item.class + ' active' : item.class
            }"></span>${item.name}`;
        },
        toggleDropMenuStatus(type) {
            if (!this.dropMenuStatuses[type]) {
                this.closeAllTutorialPopups();
                this.dropMenuStatuses[type] = true;
            } else {
                this.dropMenuStatuses[type] = false;
            }
        },
        setDropItem(value) {
            this.newKeyword.url_tracking_method = value;
        },
        setPlatform(value) {
            this.newKeyword.platform = value;
            window.localStorage.setItem('lastDevice', value);
        },
        // methods for classes
        tutorialTabItemClasses(type) {
            return {
                'new-custom-dropdown': true,
                [`tutorial-tab__item_${type}`]: true,
                'new-custom-dropdown_active': this.dropMenuStatuses[type],
            };
        },
        closeAllTutorialPopups() {
            Object.keys(this.dropMenuStatuses).forEach(
                el => (this.dropMenuStatuses[el] = false),
            );
        },
        toggleBulkImport() {
            this.newKeyword.isBulkImport = !this.newKeyword.isBulkImport;
            if (this.newKeyword.isBulkImport) {
                this.newKeyword.url = '';
                this.err.url = {};
                if (!this.getActiveTag.id) {
                    this.tags = [];
                    this.tag = '';
                }
            } else {
                this.urlInputHandler();
            }

            this.validateTextarea();
        },
        toggleExcludeSov() {
            this.newKeyword.includedInSov = !this.newKeyword.includedInSov;
        },
        toggleIgnoreLocalListings() {
            this.newKeyword.geolocation.ignoreLocal = !this.newKeyword
                .geolocation.ignoreLocal;
            this.newKeyword.geolocation.GMB = '';
        },
        selectRegionValue(value) {
            const selectedRegion = this.googleRegions(
                this.getSuggestedRegions,
                regionsForSelect,
            ).find(region => region.value === value);
            this.newKeyword.region.value = value;

            if (
                selectedRegion &&
                selectedRegion.details &&
                selectedRegion.details.hl.length
            ) {
                this.newKeyword.language.value =
                    languagesForSelect.filter(
                        el =>
                            el.value.indexOf(
                                selectedRegion.details.hl.toLowerCase(),
                            ) === 0,
                    )[0].value || DEFAULT_LANGUAGE;
            } else {
                this.newKeyword.language.value = DEFAULT_LANGUAGE;
            }
        },
        selectRegionLabel(label) {
            this.newKeyword.region.name = label[0];
        },
        selectLanguageValue(value) {
            this.newKeyword.language.value = value;
        },
        // keywords
        validateTextarea() {
            this.newKeyword.keywords = this.textarea.split('\n');
            if (!this.newKeyword.isBulkImport) {
                this.err.keywords = this.newKeyword.keywords.every(el => !el)
                    ? {
                          text: this.$t('enter-least-one-keyword'),
                          type: 'error',
                      }
                    : {};
            } else {
                this.err.keywords = this.bulkKeywordsValidator(
                    this.newKeyword.keywords,
                );
            }
        },
        urlInputHandler(sendRequest) {
            this.err.url = this.urlValidator(this.newKeyword.url);
            if (!sendRequest) {
                const str = this.getRegionDomain(this.newKeyword.url);

                if (str) {
                    const regions = regionsForSelect.filter(
                        el => str === el.value.replace('google.', ''),
                    );
                    const region = !regions.length
                        ? regionsForSelect.find(el => el.value === 'google.com')
                        : regions.length > 1
                        ? regions[1]
                        : regions[0];

                    this.selectRegionValue(region.value);
                    this.selectRegionLabel(region.name);
                }
            }
        },
        async keywordsInputHandler() {
            this.validateTextarea();

            if (!this.newKeyword.isBulkImport) {
                const keywordsArray = this.newKeyword.keywords.reduce(
                    (acc, el) => (el.length ? [...acc, el] : acc),
                    [],
                );
                if (!keywordsArray.length) return;
                this.debouncedHandler(
                    async () => await this.fetchSuggestions(keywordsArray),
                );
            }
            this.trackKeywordsListed();
        },
        trackKeywordsListed() {
            EventBus.emit(Events.KEYWORDS_LISTED_EVENT, {
                num_keywords: this.newKeyword.keywords.length,
                num_keywords_suggested: this.getSuggestions.length,
                bulk_import_enabled: this.newKeyword.isBulkImport,
                keyword_type:
                    MIXPANEL_EVENT_CONSTANTS.KEYWORD_TYPE[this.keywordType],
                leading_source:
                    MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE
                        .PROJECT_DASHBOARD,
            });
        },
        handleAddKeywordsClick() {
            if (
                (!this.newKeyword.geolocation.ignoreLocal &&
                    !this.newKeyword.geolocation.GMB &&
                    this.$refs.webGMBInputRef.$refs.newWebKeywordGMB
                        .autocompleteText) ||
                (this.newKeyword.geolocation.isManualGmb &&
                    this.getKeywordDraft(this.keywordType))
            ) {
                this.showManualGMBModal(
                    this.$refs.webGMBInputRef.$refs.newWebKeywordGMB,
                    this.addKeywords,
                );
                return;
            }
            this.addKeywords();
        },
        async addKeywords() {
            let keywords = this.newKeyword.keywords;
            for (let i = 0; i < keywords.length; i++) {
                let encodeStr = encodeURIComponent(keywords[i]);
                if (unescape(encodeStr).length > 191) {
                    let url = `<a href="http://support.keyword.com/en/articles/3347467-adding-editing-keywords#h_e9f5e25fd0" target="_blank">Read more about character limits</a>`;
                    this.$toastr.createToastr(
                        {
                            msg: this.$t('error-add-keywords', { value: url }),
                            type: 'error',
                            title: this.$t('error-add-keywords-title'),
                        },
                        { timeOut: 350000, onclick: false },
                    );
                    return;
                }
            }
            this.validateTextarea();
            this.newKeyword.tags = this.tags;

            if (this.newKeyword.isBulkImport) {
                return await this.bulkCreateKeywords();
            }
            await this.manuallyCreateKeywords();
        },

        async bulkCreateKeywords() {
            if (this.err.keywords.text) {
                this.$toastr.e(this.err.keywords.text);
            } else {
                // if bulk import has tags - show confirm modal to made its as part of keywords (or cancel it)

                if (
                    this.newKeyword.keywords.some(
                        el =>
                            el.split(':').length > 2 && el.split(':')[2].length,
                    )
                ) {
                    this.tagsFromBulkAsPartOfKeyword = false;
                    const fullKeyword = this.newKeyword.keywords.find(
                        el => el.split(':')[2],
                    );
                    const keywordArray = fullKeyword.split(':');
                    const header = `How would you like to treat "${fullKeyword}" ?`;

                    this.$modal.show(
                        BulkConfirmationModal,
                        {
                            header,
                            handler: this.bulkImportModalOptionHandler,
                            fullKeyword: `${keywordArray[1]}:${keywordArray[2]}`,
                            keyword: keywordArray[1],
                            tag: keywordArray[2],
                        },
                        {
                            width: 350,
                            height: 'auto',
                            classes: 'v--modal bulk-import-modal',
                        },
                    );
                } else {
                    await this.sendRequestToAddKeywords();
                }
            }
        },

        async manuallyCreateKeywords() {
            this.urlInputHandler(true);

            if (this.err.url.text) {
                this.$toastr.e(this.err.url.text);
            }

            if (this.err.keywords.text) {
                this.$toastr.e(this.err.keywords.text);
            }

            if (!this.err.url.text && !this.err.keywords.text) {
                await this.sendRequestToAddKeywords();
            }
        },

        async bulkImportModalOptionHandler(val) {
            this.tagsFromBulkAsPartOfKeyword = !val;
            await this.sendRequestToAddKeywords();
        },
        async sendRequestToAddKeywords() {
            this.removeEmptyLocations();
            let textError = true;
            await this.addKeyword({
                keyword: {
                    ...this.newKeyword,
                    language: {
                        value: this.newKeyword.language.value.split(' ')[0],
                    },
                },
                type: this.keywordType,
                tagsAsPartOfKeyword: this.tagsFromBulkAsPartOfKeyword,
                exact: this.newKeyword.url_tracking_method === 'exact',
                leading_source: MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.PROJECT_DASHBOARD,
            })
                .then(res => {
                    if (res === undefined) {
                        textError = false;
                    }

                    EventBus.emit(Events.ADD_GOOGLE_KEYWORDS, {
                        keyword: {
                            ...this.newKeyword,
                            language: {
                                value: this.newKeyword.language.value.split(
                                    ' ',
                                )[0],
                            },
                        },
                        type: this.keywordType,
                        tagsAsPartOfKeyword: this.tagsFromBulkAsPartOfKeyword,
                        exact: !!this.newKeyword.domain,
                    });
                })
                .catch(error => {
                    textError = error?.response?.data?.errors?.[0]?.detail;
                });

            this.isKeywordAdded = true;
            this.setKeywordDraft({ type: this.keywordType, payload: null });
            this.setDraftsQuantity({ payload: 0, type: this.keywordType });

            if (
                !textError &&
                'Domain does not exist, please check your spelling and try again.' !==
                    textError
            ) {
                const initialTab = this.$route.query?.tab;
                this.$router.push({
                    name: 'keywordList',
                    params: this.$route.params,
                    ...(initialTab ? {query: {tab: initialTab}} : {})
                });
            }
        },
        // tags
        updateTags(newTags) {
            this.tags = newTags;
            this.newKeyword.tags = newTags;
        },
        fetchKeywordsWithTags() {
            if (!this.getCurrentProject || !this.getOriginalItems.length) {
                return;
            }

            this.fetchKeywords({
                shortkey: this.getCurrentProject.shortkey,
                id: this.getCurrentProject.id,
                password: this.getCurrentProject.password,
            });
        },
        handleGmbChange({ oldVal }) {
            if (oldVal) {
                this.resetGMB();
                this.newKeyword.geolocation.isManualGmb = false;
            }
        },
        toggleIgnoreSubDomains() {
            this.newKeyword.ignore_sub_domains = !this.newKeyword.ignore_sub_domains;
        },
        handleInput(value) {
            this.newKeyword.url = value;
        }
    },
};
</script>

<style lang="scss">
@import '~sass/partials/dropdowns';
@import '~sass/partials/tutorial';
@import '~sass/partials/custom-simple-suggest';

.modal-for-mobile {
    @media (max-width: 649px) {
        width: fit-content !important;
    }
}
.domain-select-wrap {
    @media (max-width: 649px) {
        min-width: none !important;
    }
}
button {
    &.kw-btn-primary {
        .span {
            &.kw-plus {
                font-size: 10px;
            }
        }

        .button-loader {
            position: relative !important;
            background-color: transparent !important;
            border-color: transparent transparent transparent
                kw-color(kw-white, 1) !important;
            left: 0 !important;
            height: 18px !important;
            min-width: 18px !important;
            width: 18px !important;
        }
    }
}

.add-keywords-web {
    .dropdown-toggle {
        &:after {
            color: #828f9c !important;
        }
    }

    .ti-autocomplete {
        .ti-item {
            &.ti-selected-item {
                background-color: #eff4fe;
                color: kw-color(kw-black, 1);
            }
        }
    }
}

.screen__content_keyword {
    padding-top: 17px;
}

.screen__question {
    @extend .tutorial-question;

    align-self: center;
    margin-left: 2px;
}

.screen__field_keyword {
    label {
        user-select: none;

        span {
            cursor: pointer;
        }
    }

    .keyword-to-track {
        line-height: 13px;
    }


    .keywords-wrap {
        .new-custom-dropdown-button {
            padding: unset !important;
            margin-left: 10px;

            &:after {
                color: #828f9c;
            }
        }
    }

    .tags-wrap {
        padding: 12px 0;
    }

    .tags-label {
        display: flex;
        align-items: center;
        gap: 4px;
        .required-hint {
            text-transform: capitalize;
            color: kw-color(kw-grey, 2);
        }
    }

    .region-language-wrap {
        height: 84px !important;
        padding: 12px 0 0 !important;
    }

    .device-sov-wrap {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 0 12px;

        .screen__field {
            max-width: 224px;
            padding: unset;
            height: 100%;
            width: 100%;
            @media screen and (max-width: 600px) {
                max-width: 49%;
            }
        }

        .dropdown-device {
            .btn-content {
                padding-left: 1px;

                .kw {
                    color: black !important;
                }
            }
        }
    }

    .ignore-location-field-wrap {
        margin-bottom: 24px;
        &.screen__field {
            padding: unset;
        }

        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 0 !important;

        input {
            width: 100%;
        }

        .text-label {
            display: flex;

            .text-extra {
                position: unset;
                font-size: 13px;
                color: #828f9c;
                text-transform: capitalize;
                margin-left: 6px;
                cursor: default;
            }
        }
    }

    .option-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-right: 1px;
        height: 47px;

        span {
            margin-right: 5px;
        }

        .active {
            span,
            a {
                color: kw-color(kw-blue, 1) !important;
            }

            &:after {
                color: kw-color(kw-blue, 1) !important;
            }
        }

        .ignore-location-wrap {
            label {
                cursor: pointer;
            }
        }
    }

    .location-toggler {
        padding-left: 43px;
        padding-bottom: unset !important;

        .toggler {
            left: 0;
        }
    }

    .resizable-b {
        width: 16px !important;
        height: 16px !important;
        border-radius: 50%;
        left: 50% !important;
        margin-left: -8px;
        background-repeat: no-repeat;
        background-position: center;
        background: #dee5ec;
        background-image: url('~img/icon-resizable.svg');
    }

    .keywords-wrap {
        @media screen and (min-width: 960px) {
            max-width: 490px;
        }
        width: 100%;
        height: 100%;
        box-shadow: 0px 3px 10px rgba(18, 27, 41, 0.1);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        z-index: 1;

        .required-hint {
            font-size: 13px;
            color: #828f9c;
            cursor: default;
            text-transform: capitalize;
        }
    }

    .resizable-component {
        width: 100% !important;
        //height: 100%!important;
        display: flex;
        border-bottom: 1px solid #dee5ec;
    }

    .seggestions-title {
        font-size: 13px;
        color: kw-color(kw-black, 1);
        background: #fcfcfc;
        padding-left: 57px;
        box-shadow: 0 2px 4px rgba(18, 27, 41, 0.1);
        border-radius: 0 4px 0 0;
    }

    .keywords-seggestions-wrap {
        position: relative;
        display: flex;
        @media screen and (max-width: 959px) {
            flex-direction: column;
        }

        .header {
            display: flex;
        }
    }

    .screen__question {
        @extend .tutorial-question;

        align-self: center;
        margin-left: 2px;
    }

    .keywords-teaxtarey-wrap {
        min-height: 412px;
        position: relative;
        z-index: 1;
    }

    .keywords-textarea__placeholder {
        color: #b9babd;
        display: flex;
        flex-flow: column nowrap;
        pointer-events: none;
        position: absolute;
        padding-top: 5px;
        transition: opacity 0.25s ease;
        user-select: none;
        z-index: 2;
    }

    .keywords-textarea__placeholder_transparent {
        opacity: 0;
    }

    .screen__field_toggler {
        label {
            position: relative;
            padding-left: 44px;

            .toggler {
                position: absolute;
                left: 0;
            }
        }
    }

    .screen__field_select-region {
        padding-top: 14px;
        padding-bottom: 24px;
        .kw-info {
            margin-left: 6px;
        }
    }

    .screen__field_select-language {
        padding-bottom: 24px;
        .kw-info {
            margin-left: 6px;
        }
    }

    .dropdown-region-lang {
        max-width: 224px !important;
    }

    .tippy-box {
        a.link {
            color: kw-color(kw-blue, 1);
        }
    }

    .kw-information{
        position: relative;
        &::after{
            position: absolute;
            content: '';
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
.screen__content {
    &.screen__content_keyword.add-keywords-web .screen__field {
        .url-input-wrap {
            .form-control.dropdown-toggle {
                border: none !important;
            }
        }
    }
    .location-wrap {
        border: none;
    }
}
.add-keywords-web {
    .screen__column .screen__field .custom-dropdown_filled .form-control.dropdown-toggle {
        border-color: kw-color(kw-white, 5)!important;
    }
}

.url-row {
    display: flex;
    width: 100%;
    height: 36px;
    gap: 8px;
    align-items: center;
    .url-input-wrap{
        flex: 1;
        border: 1px solid kw-color(kw-white, 5);
    } 
    .custom-simple-suggest__input-wrapper {
        box-shadow: none!important;
    }
}
.screen__field{
    &.keyword-url-wrap {
        padding-bottom: 16px;
    }
    &.ignore-sub-domain {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 0;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        .toggler {
            position: relative;
        }
    }
}
.text-label {
    .req {
        color: kw-color(kw-red, 1);
    }
}
.kw-info {
    color: kw-color(kw-light-grey, 5);
    font-size: 12px;
    font-weight: 700;
}
.screen__field {
    .keyword-type-title {
        font-size: 13px!important;
        .req {
            color: kw-color(kw-red, 1);
            margin-left: -2px;
            margin-right: 4px;
        }
    }
}
</style>
