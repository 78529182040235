<template>
  <div class="display-settings__item">
    <checkbox
      :id="`${settingName}-checkbox`"
      :data-cy="`checkbox-${settingName}`"
      :checkbox="isChecked"
      @checkbox-click="toggleCheckbox(settingName)"
    />
    <label
      :for="`${settingName}-checkbox`"
      @click="toggleCheckbox(settingName)"
      v-text="label"
    />
    <span
      v-if="settingTooltip"
      class="kw kw-info"
      @mouseover="tooltip({ text: settingTooltip, event: $event, maxWidth: 247 })"
    />
  </div>
</template>

<script>
import ToolTipRenderMixin from "@/mixins/ToolTipRenderMixin";

export default {
    name: "SettingsCheckbox",
    mixins: [ToolTipRenderMixin],
    props: {
        settingName: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        settingTooltip: {
            type: String,
            required: false
        },
        isChecked: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        toggleCheckbox(value) {
            this.$emit('toggleCheckbox', value);
        },
    }
};
</script>

<style lang="scss" scoped>
.kw-info {
    color: kw-color(kw-light-grey, 5);
    font-size: 12px;
    font-weight: 700;
    margin-top: -2px;
}

.display-settings__item {
    min-width: max-content;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    position: relative;
    gap: 8px;

    .checkbox {
        position: relative !important;
        top: unset;
    }

    & > label {
        font-family: Roboto;
        font-size: 13px;
        font-weight: 400;
        margin: 0;
    }
}
</style>
