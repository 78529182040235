import { render, staticRenderFns } from "./KwField.vue?vue&type=template&id=bd667b48&scoped=true&functional=true&"
import script from "./KwField.vue?vue&type=script&lang=js&"
export * from "./KwField.vue?vue&type=script&lang=js&"
import style0 from "./KwField.vue?vue&type=style&index=0&id=bd667b48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "bd667b48",
  null
  
)

export default component.exports