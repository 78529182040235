<template>
    <div class="empty-table">
        <div class="image" /> 
        <div class="content-wrap">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: "EmptyTable",
    }
</script>

<style lang="scss" scoped>
    .empty-table {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding: 24px;
        .image {
            width: 115px;
            height: 58px;
            background-image: url(~img/cactus.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .content-wrap {
            max-width: 600px;
            width: 100%;
            text-align: center;
            color: kw-color(kw-grey, 1);
            font-family: Roboto;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
        }
    }
</style>