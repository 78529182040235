<template>
    <div class="save-filter-modal">
        <h2>{{ $t('save-filter') }}</h2>
        <p class="desc">{{ $t('add-filter-name') }}</p>
        <kw-input-field
            name="filterName"
            v-model.trim="filterName"
            :placeholder="$t('add-filter-name')"
        />
        <div class="buttons-wrapper">
            <kw-button
                button-type="secondary"
                :button-text="$t('close')"
                @click="close"
            />
            <kw-button
                :button-text="$t('save-filter')"
                @click="submit"
                :disabled="loading"
            />
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: "SaveFilterModal",
    props: {
        filters: {
            type: Object,
            required: true
        },
        project: {
            type: Object,
            required: true
        },
        tabName: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            filterName: '',
            err: {
                url: {},
                keywords: {},
            },
            loading: false
        };
    },
    methods: {
        ...mapActions(['saveGSCFilter']),
        async submit() {
            this.loading = true;
            this.saveGSCFilter({
                projectId: this.project.project_id,
                filter_name: this.filterName,
                tab_name: this.tabName,
                filters: this.filters
            }).then(() => {
                this.$emit('onSave');
                this.close();
            }).catch((e) => {
                return e;
            }).finally(() => {
                this.loading = false;
            });
        },
        close() {
            this.$emit("close");
        },
    }
};
</script>

<style lang="scss" scoped>
@import "~sass/modal";

.save-filter-modal {
    font-family: Roboto;
    width: 100%;
    padding: 16px;
    & > h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        margin: 0;
    }
    .desc {
        margin: 8px 0;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
    }
    /deep/ .kw__inputfield__name .kw__input:not(:focus) {
        border: 1px solid kw-color(kw-white, 5);
    }
    .buttons-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        margin-top: 15px;
        button {
            height: 40px;
        }
    }
}
</style>
