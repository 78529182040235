<template>
    <div v-if="loading" class="loader-wrapper">
        <placeholder-cell :width="100" />
    </div>
    <span
        v-else
        :title="message"
        :class="{ errored: message, disabled: emptyValue }"
    >
        {{ getValue }}
    </span>
</template>

<script>
export default {
    name: 'KeywordFieldRenderer',
    computed: {
        loading() {
            return (
                this.params.data.updating &&
                this.params.data.changes.includes(this.params.colDef.field)
            );
        },
        emptyValue() {
            return this.params.value === '';
        },
        message() {
            return this.params.data.errors[this.params.colDef.field];
        },
        getValue() {
            return this.emptyValue ? this.message : this.params.value;
        },
    },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
}
.disabled {
    opacity: 0.7;
}
</style>
