<template>
    <div class="table-header">
        <span class="header-description">
            {{ $t('file-name') }}
            <span  v-text="fileName" />
        </span>
        <span class="header-description">
            {{ totalRows }}
            <span v-text="totalRowsCount" />
        </span>
    </div>
</template>

<script>
export default {
    name: 'HeaderDescription',
    props: {
        totalRows: {
            type: String,
            default: '',
        },
        totalRowsCount: {
            type: String | Number,
            default: "",
        },
        fileName:{
            type: String,
            require: true
        }
    },
};
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    width: 100%;
    gap: 1%;
    font-size: 13px;

    .header-description {
        width: 27%;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
            font-weight: 500;
        }
    }
}
</style>
