<template>
    <form
        class="edit-project"
        data-cy="edit-project-sidebar"
        name="edit-project"
        @submit.prevent.stop="update"
        @keypress.enter.prevent.stop="update"
    >
        <div class="row header" />
        <div class="row content">
            <div class="row head" data-cy="edit-project-title">
                <span>{{ getSideBarTitle }}</span>
            </div>
            <div class="row description" data-cy="edit-project-description">
                <span>{{ projectData }}</span>
            </div>
            <div class="row edit-project-name">
                <kw-input-field
                    id="project-name"
                    :label="$t('project-name')"
                    class="text-input"
                    name="projectName"
                    :value="editableCategory.name"
                    :placeholder="$t('name')"
                    type="text"
                    data-cy="edit-project-name-field"
                    :error-data="projectNameFieldError('name')"
                    @input="handleInputChange"
                />
                <div v-if="editableCategory.isSubproject" class="switch-row">
                    <div class="switch-input">
                        <toggler
                            data-cy="toggl-edit-subproject"
                            :disabled="transformToSubprojectDisabled"
                            :toggler="isParentEnabled"
                            @toggler_click="toggleParentEnabled"
                        />
                    </div>
                    <div class="switch-label">
                        {{ $t('group') }}
                    </div>
                </div>
            </div>
            <div v-if="isParentEnabled" class="row parent-project">
                <label for="parent-project" data-cy="parent-project-label">
                    {{ $t('parent-project') }}
                </label>
                <custom-v-select
                    :class="parentProjectClasses"
                    :options="allCategories"
                    options-label="name"
                    options-value="name"
                    :placeholder="$t('select-project')"
                    search
                    search-key="name"
                    :value.sync="editableCategory.parent"
                    data-cy="sub-dropdown-toggle"
                    dropdown-menu-data-cy="parent-dropdown-menu"
                    dropdown-search-data-cy="dropdown-search"
                    @change="changeParent($event)"
                />
            </div>

            <div class="row edit-project-name mt20">
                <label class="text-label" for="edit-project-reporting-currency">
                    {{ $t('project-reporting-currency') }}
                </label>
                <custom-v-select
                    :class="visibilityClass"
                    id="edit-project-reporting-currency"
                    data-cy="edit-project-reporting-currency"
                    :options="getCurrencies"
                    search
                    search-key="label"
                    :value="editableCategory.currencyCode"
                    @change="handleCurrencyChange"
                    :placeholder="getCurrency"
                />
            </div>

            <div class="row edit-project-name mt20">
                <div class="edit-project__info">
                    {{ $t('project-reporting-currency-info-1') }}
                </div>
                <div class="edit-project__info">
                    {{ $t('project-reporting-currency-info-2') }}
                </div>
            </div>
        </div>
        <div class="row footer">
            <kw-button
                button-type="text"
                :button-text="$t('cancel')"
                data-cy="edit-update-cancel"
                @click="closeEditProject"
            />
            <kw-button
                button-type="primary"
                :button-text="getConfirmButtonText"
                :loading="isEditProjectRunning"
                data-cy="edit-update-confirm"
                @click.prevent="update"
            />
        </div>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ValidationMixin from '@/mixins/ValidationMixin';
export default {
    name: 'EditProjectScreen',
    mixins: [ValidationMixin],
    data() {
        return {
            visibilityClass: '',
            editableCategory: {},
            isEditProjectRunning: false,
            isParentEnabled: false,
            editProjectErr: {
                name: {},
                password: {},
            },
        };
    },
    computed: {
        ...mapGetters([
            'getActiveProjects',
            'getEditableProject',
            'getProjectByName',
            'getFullProjectName',
            'getOriginalActiveProjects',
            'getParsedEditableProject',
            'getDisplaySetting',
            'getCurrencyByCode',
            'getCurrencies',
        ]),
        getCurrency() {
            let code = this.getDisplaySetting('currency_code');

            return this.getCurrencyByCode(code)?.label;
        },
        projectData() {
            return this.getParsedEditableProject
                ? `${this.getParsedEditableProject.name} (${
                      this.getParsedEditableProject.keywords_count.ACTIVE || 0
                  } ${this.$t('keywords')})`
                : '';
        },
        allCategories() {
            const id = this.editableCategory.id;
            return this.getActiveProjects.filter(
                el => !el.name.includes('[sub]') && el.id !== id,
            );
        },
        transformToSubprojectDisabled() {
            const { name } = this.getEditableProject.attributes;
            return (
                !name.includes('[sub]') &&
                this.getOriginalActiveProjects.some(project =>
                    project.attributes.name.includes(`${name}[sub]`),
                )
            );
        },
        parentProjectClasses() {
            return {
                profile: true,
                profile_active: this.editableCategory.parent,
            };
        },
        getSideBarTitle() {
            return this.getParsedEditableProject.isSubproject
                ? this.$t('edit-group')
                : this.$t('edit-project');
        },
        getConfirmButtonText() {
            return this.getParsedEditableProject.isSubproject
                ? this.$t('update-group')
                : this.$t('update-project');
        },
    },
    created() {
        const {
            name,
            parent,
            currency_code: currencyCode,
        } = this.getParsedEditableProject;
        this.editableCategory = _.cloneDeep(this.getParsedEditableProject);

        this.editableCategory.currencyCode = currencyCode;
        this.editableCategory.name = name;
        this.editableCategory.parent = parent || '';

        this.isParentEnabled = !!parent;
    },
    methods: {
        ...mapActions(['updateProjectSettings', 'selectEditProject']),
        closeEditProject() {
            this.selectEditProject();
        },
        inputHandler(type) {
            const { id, name, parent } = this.editableCategory;

            switch (type) {
                case 'name':
                    this.editProjectErr[type] = this.projectNameValidator(
                        name,
                        parent,
                        id,
                    );
                    break;
                default:
                    return;
            }
        },
        changeParent(newParent) {
            this.editableCategory.parent = newParent;
        },
        toggleParentEnabled() {
            this.isParentEnabled = !this.isParentEnabled;

            if (!this.isParentEnabled) {
                this.editableCategory.parent = '';
            }
        },
        async update() {
            this.editableCategory.name = this.editableCategory.name.trim();
            Object.keys(this.editProjectErr).forEach(key =>
                this.inputHandler(key),
            );

            const errors = Object.keys(this.editProjectErr).filter(
                key =>
                    this.editProjectErr[key] && this.editProjectErr[key].text,
            );
            if (errors.length) {
                errors.forEach(el =>
                    this.$toastr.e(this.editProjectErr[el].text),
                );
                return;
            }
            try {
                this.isEditProjectRunning = true;
                await this.updateProjectSettings({
                    projectId: this.editableCategory.id,
                    payload: {
                        data: {
                            attributes: {
                                name: this.getFullProjectName(
                                    this.editableCategory,
                                ),
                                currency_code: this.editableCategory.currencyCode,
                            },
                        },
                    },
                });
                this.$router.push({
                    name: 'keywordList',
                    params: { id: this.getFullProjectName(this.editableCategory) },
                });
                this.closeEditProject();
            } catch (error) {
                const errorData = error.response?.data?.errors?.[0];
                if (errorData) {
                    this.editProjectErr.name = { text: errorData.detail };
                }
            } finally {
                this.isEditProjectRunning = false;
            }
            
        },
        projectNameFieldError(type) {
            return {
                error:
                    this.editProjectErr[type] && this.editProjectErr[type].text,
                message: this.editProjectErr[type] && this.editProjectErr[type].text,
            };
        },
        handleInputChange(value) {
            this.editableCategory.name = value;
            this.inputHandler('name');
        },
        handleCurrencyChange(value) {
            this.editableCategory.currencyCode = value;
            this.visibilityClass = value ? 'selected' : '';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/partials/dropdowns';

.edit-project {
    color: kw-color(kw-black, 1);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 348px;

    .row {
        margin: 0;

        .edit-project__info {
            color: #818f9c;
            font-family: Roboto;
            font-size: 11px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        &.header {
            height: 76px;
            flex: 0 0 auto;
        }

        &.content {
            @extend .custom-scroll;
            flex: 1 1 auto;
            overflow: auto;
        }

        .head,
        .description,
        .edit-project-name {
            padding: 0 24px;
        }

        .mt20 {
            margin-top: 20px;
        }

        h6 {
            color: #909399;
            text-transform: uppercase;
        }

        label {
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: kw-color(kw-black, 1);
        }

        input {
            width: 280px;
        }

        .profile {
            /deep/ .form-control.dropdown-toggle {
                @extend .custom-dropdown;
                width: 100%;
            }

            /deep/ .dropdown-menu {
                width: 100%;
                @extend .custom-scroll;
                @extend .custom-dropdown-menu;
            }
        }

        .profile.open {
            /deep/ .form-control.dropdown-toggle {
                border-color: #4f4bcb !important;
                @extend .custom-dropdown_active;
            }
        }

        .profile.profile_active {
            /deep/ .form-control.dropdown-toggle {
                span {
                    color: kw-color(kw-black, 1);
                }
            }
        }

        .switch-row {
            padding: 10px 0;
            display: flex;

            .switch-input {
                position: relative;
                width: 40px;
            }

            .switch-label {
                padding-left: 10px;
            }
        }

        &.head {
            span {
                font-weight: 500;
                line-height: 36px;
                font-size: 28px;
                letter-spacing: 0.01em;
            }
        }

        &.description {
            color: #3f4248;
            font-size: 11px;
            margin-bottom: 10px;
            margin-top: 5px;
        }

        &.parent-project {
            padding: 0 24px 15px 24px;

            .profile {
                padding: 0;
                width: 100%;
            }
        }

        &.footer {
            display: flex;
            justify-content: space-between;
            padding: 20px 35px 20px 0;
            flex: 0 0 auto;

            .button-component_transparent {
                width: 142px;
            }
        }

        /deep/ .open .form-control.dropdown-toggle {
            @extend .custom-dropdown_active;
        }

        /deep/ .form-control.dropdown-toggle {
            @extend .custom-dropdown;
            width: 280px;
        }

        .custom-dropdown_filled {
            /deep/ .form-control.dropdown-toggle {
                color: kw-color(kw-black, 1);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        /deep/ .dropdown-menu {
            @extend .custom-dropdown-menu;
            @extend .custom-scroll;
            width: 280px;

            .glyphicon.glyphicon-ok.check-mark:before {
                content: '';
            }
        }

        /deep/ .selected {
            &.btn-group {
                .form-control {
                    &.dropdown-toggle {
                        @extend .custom-dropdown;
                        color: kw-color(kw-black, 1);
                    }
                }
            }
        }
    }
}
</style>
