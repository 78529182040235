<template>
    <div v-else class="url-component-wrapper">
        <span
            v-if="params.data.exact"
            class="kw kw-crosshair target_exact_icon"
            :data-important-tooltip="$t('indicated-tracking-exact-url')"
        />
        <div
            class="table-cell__url-link-blok table-cell__content_url"
        >
            <a
                class="table-cell__url-link"
                :href="'//' + params.data.originalUrl"
                :data-important-tooltip="params.data.originalUrl"
                target="_blank"
                v-html="params.data.originalUrl"
            />
        </div>
    </div>
</template>

<script>
import ToolTipRenderMixin from "@/mixins/ToolTipRenderMixin";

export default {
  name: 'UrlRenderer',
  mixins: [ToolTipRenderMixin],
  components: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.url-component-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    gap: 5px;
    padding: 0 15px 0 20px;
    position: relative;

    .url-not-found {
        color: #63697b;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        left: 3px;
    }

    .kw-lock {
        color: kw-color(kw-grey, 1);
        font-size: 12px;
        font-weight: 700;
        left: 3px;
        position: absolute;
    }

    .table-cell__url-not-found {
        color: kw-color(kw-grey, 1);
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        left: 3px;
    }

    .table-cell__content_url {
        display: grid;
    }

    .table-cell__url-link {
        color: kw-color(kw-blue, 1);
        display: block;
        @import '~sass/cutting-name';
    }

    .not-found__icon {
        color: kw-color(kw-grey, 1);
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        left: 3px;
        top: 9px;
    }

    .not-found {
        color: #ccc;
        text-transform: uppercase;
    }

    .target_exact_icon {
        color: kw-color(kw-grey, 1);
        font-size: 12px;
        font-weight: 700;
        margin-right: 5px;
    }
}
</style>
