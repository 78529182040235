

<template>
    <div class="field">
        <label class="text-label">{{ $t('devices') }}</label>
        <button-group class="devices-row" :value="value" type="primary" :buttons="false" @input="setPlatform($event)">
            <radio selected-value="desktop-mobile" data-value="desktop-mobile">Both</radio>
            <radio selected-value="desktop" data-value="desktop">Desktop</radio>
            <radio selected-value="mobile" data-value="mobile">Mobile</radio>
        </button-group>
    </div>
</template>
   
<script>
    import Radio from 'vue-strap/src/Radio';
    import buttonGroup from 'vue-strap/src/buttonGroup.vue';
   
    export default {
        name: 'DevicePicker',
        props: {
            value: {
                type: String,
            }
        },
        components: {
            radio: Radio,
            'button-group': buttonGroup,
        },
        methods: {
            setPlatform(platform) {
                this.$emit('setPlatform', platform)
            }
        }
    };
</script>

<style lang="scss" scoped>
    .field {
        .devices-row {
            display: flex;
            align-items: center;
            gap: 12px;

            .radio {
                margin: 0;
            }
        }
        label {
            font-size: 13px;
            .required {
                color: #CB1A02;
            }
        }

        input {
            width: 100%;
        }

        textarea {
            width: 100%;
            height: 120px;
            border-radius: 4px;
            padding: 10px;
            border: 1px solid #dee5ec;
            background: var(--White, #fff);
            resize: none;
        }
    }
</style>
   