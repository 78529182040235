<template>
    <div class="filter-item">
        <span>{{ label }}: {{ getValue }}</span>
        <span @click="$emit('onDelete')" class="custom-x" />
    </div>
</template>

<script>
    export default {
        name: "FilterItem",
        props: {
            label: {
                type: String,
                required: true
            },
            filterType: {
                type: String,
                default: ''
            },
            value: {
                type: [String, Number, Array],
                required: true
            }
        },
        computed: {
            getValue() {
                if (this.filterType === 'between' && Array.isArray(this.value)) {
                    return `>${this.value[0]} & <${this.value[1]}`;
                } else {
                    return `${this.filterType === '=' ? '' : this.filterType}${this.value}`;
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .filter-item {
        font-family: 'Open Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        height: 32px;
        gap: 8px;
        color: kw-color(kw-grey, 1);
        font-weight: 600;
        font-size: 11px;
        background-color: kw-color(kw-white, 1);
        border: 1px solid kw-color(kw-light-blue, 13);
        border-radius: 4px;
        .custom-x {
            background-color: kw-color(kw-grey, 1);
            cursor: pointer;
        }
    }
</style>