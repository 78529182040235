<template>
    <upgrade-for-access v-if="isFreePlan" :title="$t('upgrade-to-integrate-gsc.title')">
        <div v-html="$t('upgrade-to-integrate-gsc.desc')" />
    </upgrade-for-access>
    <div v-else class="keyword-suggestions">
        <div
            v-if="!loading && !getGSCUsernameIsFetching && !getGSCUsername"
            class="not-authenticated empty"
        >
            <div class="connect-google-search-console">
                {{ $t('connect-google-search-console-and') }}
            </div>
            <div class="section">
                <div class="title">
                    <span class="step">{{ $t('step-n', { n: 1 }) }}</span>
                    <span>{{ $t('connect-google-search-console') }}</span>
                </div>
                <kw-button
                    :button-text="$t('connect-account')"
                    button-type="primary"
                    @click="locateTo(googleAuthLink)"
                />
            </div>
            <div class="section">
                <div class="title">
                    <span class="step">{{ $t('step-n', { n: 2 }) }}</span>
                    <span>{{ $t('set-rules-for-discovering') }}</span>
                </div>
                <kw-button
                    :button-text="$t('set-rules')"
                    button-type="default"
                    disabled
                    class="set-rules-button"
                />
            </div>
        </div>
        <div
            v-else-if="
                !loading &&
                !getGSCProfilesIsFetching &&
                !getGSCProfileConnectionsIsFetching
            "
            class="authenticated"
            :class="{
                empty: !getGSCProfileCurrentConnection,
            }"
        >
            <div class="title">{{ $t('active-google-search-console') }}</div>
            <div class="header-part">
                <div class="profile-select">
                    <v-select
                        id="search-console-profile"
                        :class="profilesSelectClasses"
                        :options="getGSCProfiles"
                        :placeholder="$t('select-profile')"
                        search
                        :value.sync="getConnectionProfile"
                        @change="setConnectionProfile"
                    />
                </div>
                <template v-if="getGSCProfileCurrentConnection">
                    <kw-button
                        class="delete-profile-button"
                        prepend-icon-class="kw kw-trash-2 bold-icon"
                        button-type="secondary"
                        @click="confirmDelete"
                    />
                    <kw-button
                        :button-text="setEditRulesButtonText"
                        button-type="primary"
                        class="set-rules-button"
                        @click="openRulesModal"
                    />
                    <div
                        class="view-removed-keyword-list"
                        v-if="
                            !getGSCDeletedSuggestionsIsFetching &&
                            getGSCDeletedSuggestions.length
                        "
                        @click="openDeletedSuggestionsModal"
                    >
                        {{ $t('view-removed-keyword-suggestions-list') }}
                    </div>
                </template>
            </div>
            <div class="table-part" v-if="getGSCProfileCurrentConnection">
                <keyword-suggestions-table
                    :loading="getGSCSuggestionsIsFetching"
                    :suggestions="getGSCSuggestions"
                />
            </div>
        </div>
        <placeholder-cell v-else :width="200" :height="14" />
    </div>
</template>

<script>
import { GTM_EVENTS } from '@/constants';
import { Events, EventBus } from '@/events';
import { mapGetters, mapActions } from 'vuex';
import vSelect from 'components/extended/VueStrapSelect';
import SocialSignInMixin from '@/mixins/SocialSignInMixin';
import { SET_CURRENT_GROUP_OBJECT } from '@/store/mutations';
import KeywordSuggestionsTable from './components/KeywordSuggestionsTable';
import SimpleDeleteWithCustomHandler from 'components/modals/SimpleDeleteWithCustomHandler';
import GSCProfileConnectionRulesModal from './components/modals/GSCProfileConnectionRulesModal';
import GSCDeletedSuggestionsModal from './components/modals/GSCDeletedSuggestionsModal';
import UpgradeForAccess from '@/components/common/UpgradeForAccess.vue';

export default {
    name: 'KeywordSuggestions',
    components: {
        'v-select': vSelect,
        'keyword-suggestions-table': KeywordSuggestionsTable,
        'upgrade-for-access': UpgradeForAccess,
    },
    mixins: [SocialSignInMixin],
    data() {
        return {
            service: 'search-console',
            loading: true,
        };
    },
    computed: {
        ...mapGetters([
            'getUserData',
            'getPermissionData',
            'getPermissionsData',
            'getGSCUsername',
            'getGSCUsernameIsFetching',
            'getGSCProfiles',
            'getGSCProfilesIsFetching',
            'getGSCSuggestionsSort',
            'getCurrentProject',
            'getGSCSuggestions',
            'getGSCProfileConnections',
            'getGSCProfileCurrentConnection',
            'getGSCSuggestionsIsFetching',
            'getGSCProfileConnectionsIsFetching',
            'getGSCDeletedSuggestions',
            'getGSCDeletedSuggestionsIsFetching',
            'getCurrentPlan'
        ]),
        setEditRulesButtonText() {
            return this.getGSCProfileCurrentConnection?.rules?.positionMin ||
                this.getGSCProfileCurrentConnection?.rules?.positionMax ||
                this.getGSCProfileCurrentConnection?.rules?.impressionMin ||
                this.getGSCProfileCurrentConnection?.rules?.impressionMax ||
                this.getGSCProfileCurrentConnection?.rules?.ctrMin ||
                this.getGSCProfileCurrentConnection?.rules?.ctrMax ||
                this.getGSCProfileCurrentConnection?.rules?.period
                ? this.$t('edit-rules')
                : this.$t('set-rules');
        },
        profilesSelectClasses() {
            return {
                'custom-dropdown_filled': this.getGSCProfileCurrentConnection,
            };
        },
        getConnectionProfile() {
            return this.getGSCProfileCurrentConnection
                ? this.getGSCProfileCurrentConnection.originalDomain
                : null;
        },
        isFreePlan(){
            return this.getCurrentPlan?.subscription?.is_free_plan;
        }
    },
    mounted() {
        EventBus.emit(Events.OPEN_SUGGESTIONS_TAB);
        /**
         * GTM custom event suggestions_tab_opened
         */
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: GTM_EVENTS.CLICK_SUGGESTIONS_TAB,
            uid: this.getUserData.userId,
        });
    },
    created() {
        if (!this.getPermissionData('google_integrations')) {
            this.$router.push({ name: 'googleSearchConsole' });
            return;
        }

        if (this.getCurrentProject) {
            this.fetchData();
        } else {
            this.setCurrentProject();
        }

        this.unSubscribe = this.$store.subscribe(({ type }) => {
            if (type === SET_CURRENT_GROUP_OBJECT) {
                this.fetchData();
            }
        });
    },
    beforeDestroy() {
        if (typeof this.unSubscribe === 'function') {
            this.unSubscribe();
        }
    },
    methods: {
        ...mapActions([
            'fetchGSCAccount',
            'fetchGSCProfiles',
            'setCurrentProject',
            'fetchGSCProfileConnection',
            'updateGSCProfileConnection',
            'deleteGSCProfileConnection',
            'createGSCProfileConnections',
            'fetchGSCDeletedSuggestions',
        ]),
        async fetchData() {
            try {
                await this.fetchGSCAccount();
                await this.fetchGSCProfiles();
                await this.fetchGSCProfileConnection();
                await this.fetchGSCDeletedSuggestions();
                this.loading = false;
            } catch (error) {
                this.$toastr.e(error);
            }
        },
        openRulesModal() {
            this.$modal.show(
                GSCProfileConnectionRulesModal,
                { connection: this.getGSCProfileCurrentConnection },
                {
                    classes: 'v--modal',
                    draggable: '.custom-modal_draggable',
                    height: 'auto',
                    name: 'GSCProfileConnectionRulesModal',
                    width: 400,
                },
            );
        },
        openDeletedSuggestionsModal() {
            this.$modal.show(
                GSCDeletedSuggestionsModal,
                { deletedSuggestions: this.getGSCDeletedSuggestions },
                {
                    classes: 'v--modal',
                    draggable: '.custom-modal_draggable',
                    height: 'auto',
                    name: 'GSCDeletedSuggestionsModal',
                    width: 400,
                },
            );
        },
        confirmDelete() {
            if (this.getGSCProfileCurrentConnection) {
                this.$modal.show(
                    SimpleDeleteWithCustomHandler,
                    {
                        item: this.getGSCProfileCurrentConnection.id,
                        deleteHandler: this.deleteConnection,
                        type: `${this.$t('profile-project-connection')}?`,
                    },
                    {
                        classes: 'v--modal',
                        height: 'auto',
                        name: 'SimpleDeleteWithCustomHandler',
                        width: 310,
                    },
                );
            }
        },
        async deleteConnection(connectionId) {
            try {
                let data = {
                    connectionId,
                    update: false,
                    fetchAll: false,
                };
                await this.deleteGSCProfileConnection(data);
                this.$modal.hide('SimpleDeleteWithCustomHandler');
            } catch (error) {
                this.$toastr.e(error);
            }
        },
        async setConnectionProfile(value) {
            if (this.getGSCProfileCurrentConnection) {
                if (
                    this.getGSCProfileCurrentConnection?.originalDomain !==
                    value
                ) {
                    let data = {
                        connection: this.getGSCProfileCurrentConnection?.id,
                        profile: value,
                        rules: this.getGSCProfileCurrentConnection?.rules ?? [],
                        fetchAll: false,
                    };
                    await this.updateGSCProfileConnection(data);
                }
            } else {
                if (value && this.getCurrentProject) {
                    try {
                        await this.createGSCProfileConnections({
                            profile: value,
                            selectedProjects: [this.getCurrentProject],
                            fetchAll: false,
                        });

                        this.$toastr.s(
                            this.$t('item-create-success', {
                                item: this.$t('profile-project-connection-s'),
                            }),
                        );
                    } catch (e) {
                        throw e;
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/partials/dropdowns';
.keyword-suggestions {
    .not-authenticated {
        margin: 35px 23px;

        .connect-google-search-console {
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 24px;
        }

        .section {
            margin-top: 16px;

            .title {
                margin-bottom: 16px;

                .step {
                    font-weight: 500;
                    line-height: 20px;
                }
            }

            .kw-button {
                min-width: 182px;
            }
        }
    }

    .authenticated {
        margin: 15px 0;

        #search-console-profile {
            min-width: 302px;

            span {
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
            }
        }

        .title {
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            margin-bottom: 8px;
        }

        .header-part {
            display: flex;
            align-items: center;

            .delete-profile-button {
                padding: 0 !important;
                border: 0 !important;
                box-shadow: none !important;
                color: kw-color(kw-blue, 1) !important;
                font-size: 16px;
                margin: 0 16px;

                /deep/ .bold-icon {
                    font-weight: 600;
                }
            }

            .view-removed-keyword-list {
                cursor: pointer;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #0074d9;
                margin-left: 8px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .table-part {
            margin-top: 8px;
        }
    }

    /deep/ .empty {
        background-image: url(~img/cactus.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 200px;
        padding-bottom: 300px;
    }
}
.profile-select {
    flex-grow: 1;

    /deep/ .open .form-control.dropdown-toggle {
        @extend .custom-dropdown_active;
    }

    /deep/ .form-control.dropdown-toggle {
        @extend .custom-dropdown;
    }

    /deep/ .dropdown-menu {
        @extend .custom-scroll;
        @extend .custom-dropdown-menu;

        .glyphicon.glyphicon-ok.check-mark:before {
            content: '';
        }
    }
}

.custom-dropdown_filled {
    min-width: 302px;
    color: kw-color(kw-black, 1);

    /deep/ .form-control.dropdown-toggle {
        color: kw-color(kw-black, 1);
    }
}

@media (min-width: 960px) {
    .profile-select {
        flex-grow: unset;

        /deep/ .form-control.dropdown-toggle {
            width: 360px;
        }

        /deep/ .dropdown-menu {
            width: 360px;
        }
    }
}
</style>
