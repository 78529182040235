<template>
    <div class="exclude-sov-wrap">
        <label
            data-cy="add-keyword-sov-exclude-label"
            class="text-label sov-exclude-toggler"
        >
            <toggler
                :toggler="value"
                @toggler_click="e => $emit('toggleExcludeSov', e)"
            />
            <span
                @click="e => $emit('toggleExcludeSov', e)"
                v-text="$t('exclude-from-sov')"
            />
            <span
                class="kw kw-info"
                @mouseover="
                        tooltip({
                        text: `<p>${$t('share-of-voice-tooltip')}
                                    <a class='link' href='https://support.keyword.com/en/articles/4761355-share-of-voice'
                                    target='_blank'>${$t('learn-more')}</a>
                                </p>`,
                        event: $event,
                        interactive: true,
                    })
                "
            />
        </label>
    </div>
</template>

<script>
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';

export default {
    name: 'ExcludeFromSov',
    mixins: [
        ToolTipRenderMixin,
    ],
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.exclude-sov-wrap {
    padding-left: 43px;

    .sov-exclude-toggler {
        color: kw-color(kw-black, 1);
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;

        .toggler {
            margin-left: -43px;
        }
        .kw-info {
            margin-left: 8px;
        }
    }
}
</style>
