<template>
    <main v-resize="appResizeHandler" :class="appClasses" @mousedown="appClick">
        <trial-period-header v-if="isTrialPeriodHeaderVisible" />

        <div
            v-if="
                $route.name !== 'shareViewkey' &&
                $route.name !== 'shareViewkeyWithHash' &&
                $route.name !== 'shareOfVoice' &&
                $route.name !== 'shareOfVoiceWithHash' &&
                $route.name !== 'shareKeywordsTable' &&
                $route.name !== 'shareKeywordsTableWithHash' &&
                $route.name !== 'pdfView'
            "
            :class="getAppHeaderClassNames"
            data-cy="app-header"
        >
            <keep-alive>
                <router-view name="AppHeader" />
            </keep-alive>
        </div>
        <div class="main-content">
            <template
                v-if="
                    $route.name !== 'search' &&
                    $route.name !== 'PageNotFound' &&
                    $route.name !== 'ServerError' &&
                    !$route.path.includes('/users') &&
                    $route.name !== 'privacy'
                "
            >
                <left-sidebar v-if="isLeftSidebarVisible" />

                <!-- add projects slide -->
                <vs-aside
                    :class="{
                        with_top_banner: isTrialPeriodHeaderVisible,
                    }"
                    placement="left"
                    :show.sync="isAddProjectOpen"
                    :width="373"
                >
                    <add-project-screen />
                </vs-aside>

                <!-- edit project slide -->
                <vs-aside
                    :class="{
                        with_top_banner: isTrialPeriodHeaderVisible,
                    }"
                    placement="left"
                    :show.sync="isEditProjectOpen"
                    :width="348"
                >
                    <edit-project-screen />
                </vs-aside>

                <!-- arhived categories slide -->
                <vs-aside
                    class="archived-projects-aside"
                    :class="{
                        with_top_banner: isTrialPeriodHeaderVisible,
                    }"
                    placement="left"
                    :show.sync="getIsArchivedCategoriesOpen"
                    :width="687"
                >
                    <archived-projects-screen />
                </vs-aside>

                <!-- add project tags -->
                <vs-aside
                    :class="{
                        with_top_banner: isTrialPeriodHeaderVisible,
                    }"
                    placement="left"
                    :show.sync="getIsAddProjectTagsOpen"
                    :width="373"
                >
                    <add-project-tags-screen />
                </vs-aside>

                <!-- add sub-project -->
                <vs-aside
                    :class="{
                        with_top_banner: isTrialPeriodHeaderVisible,
                    }"
                    placement="left"
                    :show.sync="getIsAddSubProjectOpen"
                    :width="373"
                >
                    <add-sub-project-screen />
                </vs-aside>

                <!-- edit project tag -->
                <vs-aside
                    :class="{
                        with_top_banner: isTrialPeriodHeaderVisible,
                    }"
                    placement="left"
                    :show.sync="getIsEditProjectTagOpen"
                    :width="373"
                >
                    <edit-project-tag-screen />
                </vs-aside>

                <div>
                    <div :class="mainPageClasses"
                    :style="{ marginLeft: `${getContentLeftMargin}px` }">
                    <router-view name="Overview" />
                    <router-view name="mainBlock" />
                    <router-view name="googleAnalytics" />
                    <router-view name="googleSearchConsole" />
                    <router-view name="googleLookerStudio" />
                    <router-view name="apiAccess" />
                    <router-view name="addKeywords" />
                    <router-view name="shareViewkey" />
                    <router-view name="shareOfVoice" />
                    <router-view name="shareKeywordsTable" />
                    <router-view name="pdfView" />
                    <router-view name="Reports" />
                    <router-view name="WhitelabelDomains" />
                    <router-view name="alerts" />
                    <router-view name="Pricing" />
                    <router-view name="settingsMain" />
                    <router-view name="contactUs" />
                    <router-view name="BillingSettings" />
                    <router-view name="searchResults" />
                    <router-view name="AccountDetails" />
                    <router-view name="ImportData" />
                    <router-view name="Tutorial" />
                    <div v-if="renderMobileBackground" class="mobile-background" />
                    </div>
                </div>

                <div :class="manageBackgroundClasses" />
            </template>

            <router-view name="Users" />
            <keep-alive>
                <router-view name="search" />
                <router-view name="PageNotFound" />
                <router-view name="ServerError" />
                <router-view name="privacy" />
            </keep-alive>

            <global-events
                @keydown.16="deniedUserSelect"
                @keyup.16="accessUserSelect"
                @scroll="mainPageScrollHandler"
            />
        </div>
    </main>
</template>

<script>
import { Events, EventBus } from '@/events';
import { mapGetters, mapActions } from 'vuex';
import { PUBLIC_ROUTES } from './constants';
import AddProjectScreen from './components/left-sidebar/AddProjectScreen';
import ArchivedProjectsScreen from './components/left-sidebar/ArchivedProjectsScreen';
import aside from 'components/extended/AsideVueStrap';
import DashboardMixin from './mixins/DashboardMixin';
import EditProjectScreen from './components/left-sidebar/EditProjectScreen';
import initServices from './services';
import LeftSidebar from 'components/left-sidebar/LeftSidebar';
import ProjectsActionsMixin from './mixins/ProjectsActionsMixin';
import resize from 'vue-resize-directive';
import setVipIcons from './helpers/set-vip-icons';
import AddProjectTagsScreen from './components/left-sidebar/AddProjectTagsScreen';
import AddSubProjectScreen from './components/left-sidebar/AddSubProjectScreen';
import EditProjectTagScreen from './components/left-sidebar/EditProjectTagScreen';
import { cookieGetters, cookieManager } from '@/helpers/cookieManager';
import MissingBillingDetailsModal from './components/modals/MissingBillingDetailsModal.vue';
import TrialExpiresModal from './components/modals/TrialExpiresModal';
import KeywordsExceededModal from './components/modals/KeywordsExceededModal';
import TrialPeriodHeader from './components/app-header/TrialPeriodHeader.vue';
export default {
    name: 'App',
    components: {
        'add-project-screen': AddProjectScreen,
        'archived-projects-screen': ArchivedProjectsScreen,
        'edit-project-screen': EditProjectScreen,
        'left-sidebar': LeftSidebar,
        'vs-aside': aside,
        'add-project-tags-screen': AddProjectTagsScreen,
        'add-sub-project-screen': AddSubProjectScreen,
        'edit-project-tag-screen': EditProjectTagScreen,
        'trial-period-header': TrialPeriodHeader,
    },
    directives: {
        resize,
    },
    mixins: [DashboardMixin, ProjectsActionsMixin],
    data() {
        return {
            appWidth: '100%',
            timeoutId: null
        };
    },
    computed: {
        ...mapGetters([
            'getContentLeftMargin',
            'getUserDataProp',
            'getKeywordsExceeded',
            'getSettingIsFetching',
            'getCurrentProject',
            'getIsArchivedCategoriesOpen',
            'getIsSidebarResizing',
            'getBillingDetails',
            'getCurrentPlan',
            'getBillingDetailsLoaded',
            'getPaymentDetailsLoaded',
            'getOtherSetting',
            'getSelectedAuth',
            'getViewkeyView',
            'isAddProjectOpen',
            'isEditProjectOpen',
            'isLeftSidebarOpen',
            'getIsAddProjectTagsOpen',
            'getIsAddSubProjectOpen',
            'getIsEditProjectTagOpen',
            'getTrialPeriod',
            'getIsFreePlan',
            'getIsExpired',
            'getTutorialMode',
            'getExpiresTillTomorrow',
            'getIsAuthUser',
            'getIsRestrictedAccessUser'
        ]),
        renderMobileBackground() {
            return (
                !this.getViewkeyView &&
                this.isLeftSidebarOpen &&
                window.MOBILE_WIDTH_RESOLUTION_PHONE > window.screen.width
            );
        },
        isTrialPeriodHeaderVisible() {
            return (
                !this.getCurrentPlan?.default_card?.type &&
                this.getTrialPeriod &&
                !PUBLIC_ROUTES.includes(this.$route.name)
            );
        },
        isLeftSidebarVisible() {
            return (
                !this.getIsExpired && !PUBLIC_ROUTES.includes(this.$route.name) && !this.getIsRestrictedAccessUser
            );
        },
        showActionBar() {
            return (
                this.getSelectedAuth.length &&
                (this.$route.name === 'keywordList' ||
                    this.$route.name === 'shareViewkey' ||
                    this.$route.name === 'shareViewkeyWithHash' ||
                    this.$route.name === 'shareOfVoice' ||
                    this.$route.name === 'shareOfVoiceWithHash' ||
                    this.$route.name === 'shareKeywordsTable' ||
                    this.$route.name === 'shareKeywordsTableWithHash' ||
                    this.$route.name === 'archived')
            );
        },
        getAppHeaderClassNames() {
            return {
                app__header: true,
                with_top_banner: this.isTrialPeriodHeaderVisible,
            };
        },
        appClasses() {
            return {
                'app container-fluid': true,
                'container-fluid-with-trial': this.isTrialPeriodHeaderVisible,
                app_settings: this.$route.path.includes('/settings/'),
                app_packages: this.$route.path.includes('/packages'),
                app_overview: this.$route.name === 'Overview',
                app_login: this.$route.name === 'login',
                app_share: this.getViewkeyView,
                app_pdf: this.$route.path.includes('/pdf-view/'),
                app_expanded: this.getOtherSetting('widetable') === 'show',
            };
        },
        mainPageClasses() {
            return {
                'main-page': true,
                'main-page_no-transition': this.getIsSidebarResizing,
                'main-page_pdf-view': this.$route.name === 'pdfView',
                'main-page-add-keywords': this.$route.name === 'addKeywords',
                'main-page_share-viewkey':
                    this.$route.name === 'shareViewkey' ||
                    this.$route.name === 'shareViewkeyWithHash',
                'main-page_share-of-voice':
                    this.$route.name === 'shareOfVoice' ||
                    this.$route.name === 'shareOfVoiceWithHash',
                'main-page_share-keywords-table':
                    this.$route.name === 'shareKeywordsTable' ||
                    this.$route.name === 'shareKeywordsTableWithHash',
                'main-page_sidebar-opened':
                    this.isLeftSidebarOpen && this.isLeftSidebarVisible,
                'main-page_dashboard': this.$route.name === 'Overview',
            };
        },
        manageBackgroundClasses() {
            return {
                'fade-in': true,
                active:
                    this.getIsArchivedCategoriesOpen &&
                    this.isLeftSidebarOpen &&
                    this.$route.name !== 'shareViewkey' &&
                    this.$route.name !== 'shareViewkeyWithHash' &&
                    this.$route.name !== 'shareOfVoice' &&
                    this.$route.name !== 'shareOfVoiceWithHash' &&
                    this.$route.name !== 'shareKeywordsTable' &&
                    this.$route.name !== 'shareKeywordsTableWithHash' &&
                    this.$route.name !== 'pdfView',
            };
        },
    },
    watch: {
        async $route() {
            this.checkAndShowModals();
        },
        getIsArchivedCategoriesOpen: function () {
            this.addAsideBgClickListener();
        },
        async getIsAuthUser(cur, prev){
            if (cur && !prev) {
                await initServices(this, this.$route);
            }
        }
    },
    created() {
        if (!PUBLIC_ROUTES.includes(this.$route.name)) {
            this.fetchSettings();

            if (this.getIsAuthUser) {
                initServices(this);
            }

            if (!this.getTutorialMode) {
                this.checkAndShowModals();
                this.fetchAlertsCount();
                this.fetchDuplicationProgressesAction();
            }
        }

        this.checkForReportActivityLogs();
        this.checkForImportActivityLog();

        EventBus.on(Events.USER_UPDATE, setVipIcons);
    },
    mounted() {
        document.addEventListener('keyup', this.close);
        this.addAsideBgClickListener();
    },
    beforeDestroy() {
        EventBus.off(Events.USER_UPDATE, setVipIcons);
        this.removeAsideBgClickListener();
    },
    methods: {
        ...mapActions([
            'closeAllBars',
            'fetchAlertsCount',
            'fetchSettings',
            'runIntervalChecking',
            'resetCheckedProjects',
            'recurringCheckProgress',
            'fetchDuplicationProgressesAction',
        ]),
        appClick(e) {
            EventBus.emit(Events.DOCUMENT_CLICK, e);
        },
        deniedUserSelect() {
            document.getElementsByClassName('app')[0].style.webkitUserSelect =
                'none';
            document.getElementsByClassName('app')[0].style.userSelect = 'none';
        },
        accessUserSelect() {
            document.getElementsByClassName('app')[0].style.webkitUserSelect =
                '';
            document.getElementsByClassName('app')[0].style.userSelect = '';
        },
        close(e) {
            if (e.keyCode === 27) {
                this.closeAllAndUnselect();
            }
        },
        addAsideBgClickListener() {
            const backdrops = document.querySelectorAll('.aside-backdrop');
            Array.from(backdrops).forEach(el =>
                el.addEventListener('click', this.closeAllAndUnselect),
            );
        },
        removeAsideBgClickListener() {
            const backdrops = document.querySelectorAll('.aside-backdrop');
            Array.from(backdrops).forEach(el =>
                el.removeEventListener('click', this.closeAllAndUnselect),
            );
        },
        closeAllAndUnselect() {
            this.resetCheckedProjects();
            this.closeAllBars();

            if (this.getCurrentProject && this.$route.name === 'archived') {
                this.$router.push({
                    name: 'keywordList',
                    params: { id: this.getCurrentProject.id },
                });
            }
        },
        appResizeHandler(e) {
            this.appWidth = getComputedStyle(e, null).getPropertyValue('width');
        },
        checkAndShowModals() {
            this.checkAndShowBillingModal();
            this.checkAndShowKeywordsExceededModal();
            this.handleExpiredUser();
        },
        checkAndShowBillingModal() {
            if (
                !this.getIsExpired &&
                !this.getTrialPeriod &&
                !this.getIsFreePlan &&
                !this.billingModalShowed &&
                ![
                    ...PUBLIC_ROUTES,
                    'settingsPlan',
                    'Pricing',
                    'AccountDetails',
                ].includes(this.$route.name)
            ) {
                if (this.getBillingDetailsLoaded) {
                    if (
                        this.getBillingDetails.country === '' ||
                        this.getBillingDetails.postal_code === ''
                    ) {
                        this.billingModalShowed = true;
                        this.showBillingModal();
                    }
                } else {
                    setTimeout(this.checkAndShowBillingModal, 1000);
                }
            }
        },
        handleExpiredUser() {
            if (Object.keys(this.getCurrentPlan).length) {
                const subscription = this.getCurrentPlan.subscription;
                const isExpired = new Date() > new Date(subscription.account_expire_at);
                const trialIsUsed = this.getUserDataProp('trialIsUsed');
                const isFreePlan = subscription.is_free_plan;

                if (subscription.expire_post_trial && !isFreePlan && trialIsUsed && isExpired &&
                    ![...PUBLIC_ROUTES, 'Pricing', 'AccountDetails'].includes(
                        this.$route.name,
                    )
                ) {
                    this.showExpireModal();
                    
                    if (this.timeoutId) {
                        clearTimeout(this.timeoutId)
                        this.timeoutId = null
                    }
                }
            } else {
              this.timeoutId = setTimeout(this.handleExpiredUser, 1000);
            }
        },
        checkAndShowKeywordsExceededModal() {
            if (!this.getSettingIsFetching) {
                if (
                    cookieGetters.getKeywordExceededModalShown() !==
                        this.getUserDataProp('email') &&
                    this.getKeywordsExceeded &&
                    ![...PUBLIC_ROUTES, 'Pricing', 'AccountDetails'].includes(
                        this.$route.name,
                    )
                ) {
                    this.showKeywordsExceededModal();
                }
            } else {
                setTimeout(this.checkAndShowKeywordsExceededModal, 1000);
            }
        },
        showKeywordsExceededModal() {
            this.$modal.show(
                KeywordsExceededModal,
                {},
                {
                    clickToClose: false,
                    width: 450,
                    height: 'auto',
                    classes: 'v--modal center-modal-popup',
                },
            );
        },
        showExpireModal() {
            this.$modal.show(
                TrialExpiresModal,
                {},
                {
                    clickToClose: false,
                    width: 765,
                    height: 'auto',
                    classes: 'v--modal-full-height center-modal-popup',
                },
            );
        },
        showBillingModal() {
            this.$modal.show(
                MissingBillingDetailsModal,
                {},
                {
                    clickToClose: false,
                    width: 400,
                    height: 'auto',
                    classes: 'v--modal',
                },
                {
                    'before-close': () => {
                        this.billingModalShowed = false;
                    },
                },
            );
        },
        checkForReportActivityLogs() {
            let activityLogs = cookieGetters.getReportActionLogs();

            if (activityLogs) {
                activityLogs.forEach(logId =>
                    this.runIntervalChecking({ logId }),
                );
            }
        },
        checkForImportActivityLog() {
            let activityLogId = cookieGetters.getImportActivityLogId();

            if (activityLogId) {
                this.recurringCheckProgress(activityLogId);
            }
        },
    },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
.app.container-fluid {
    box-shadow: 0 7px 14px rgba(60, 66, 87, 0.12), 0 3px 6px rgba(0, 0, 0, 0.12);
    margin: auto;
    min-height: 100%;
    min-width: 1150px;
    padding-left: 0;
    width: 100%;
    padding: 0;
  
    .main-content{
        max-width: 1392px;
        min-height: calc(100vh - #{$header-height});
        margin: 0 auto;
        background-color: kw-color(kw-white, 4);   
    }

    &.container-fluid-with-trial {
        .main-content{
            min-height: calc(100vh - #{$header-height} - #{$trial-header-height});
        }
        .mobile-background{
            top: calc(#{$header-height} + #{$trial-header-height})
        }
    }
    
    @media screen and (min-width: 960px) and (max-width: 1200px) {
        min-width: unset;
        width: 100%;
    }
    .main-page {
        max-width: 100%;
    }

    .main-page-add-keywords {
        background-color: #f5f6f7;
        min-height: 100vh;
    }

    @media screen and (max-width: 959px) {
        min-width: unset;
        max-width: 100vw;
        display: block;
    }

    &.app_settings,
    &.app_overview {
        .main-page {
            background-color: kw-color(kw-white, 1);
        }
    }

    &.app_packages .main-page {
        background-color: kw-color(kw-white, 2);
    }

    &.app_settings {
        @media screen and (min-width: 960px) {
            .main-page {
                padding: 0 26px 200px 26px;
            }
        }

        @media (max-width: 1331px) {
            .main-page {
                &.main-page_sidebar-opened {
                    .settings {
                        .settings__content {
                            .settings_plan {
                                .settins_plan_container {
                                    .custom_row {
                                        .settings_plan_block {
                                            margin-bottom: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.app_expanded {
        .main-content{
            max-width: 100%;
        }
    }

    &.app_pdf {
        max-width: 1144px;
    }

    &.app_share {
        max-width: 100% !important;
        .main-content {
            max-width: 100% !important;
        }
        .share-viewkey .dashboard-table-wrap,
        .share-data__wrapper,
        .share-top-bar {
            max-width: 100% !important;
        }
    }

    @media screen and (max-width: 959px) {
        .new-project-sidebar,
        .edit-project-sidebar {
            z-index: 1065;
        }
    }
}

.app__header {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 500;

    &.with_top_banner {
        top: 48px;
    }

    @media (max-width: 959px) {
        margin: 0;
    }
}

.main-page {
    @extend .custom-scroll;
    margin-left: 0;
    position: relative;
    transition: all 0.3s ease;

    &.main-page_no-transition {
        transition: none;
    }

    &.main-page_share-viewkey {
        min-height: 100vh;
        padding: 0;
    }

    @media screen and (min-width: 960px) {
        padding: 0 26px 0 26px;
    }

    &.main-page_tutorial {
        @media screen and (max-width: 959px) {
            height: 100vh;
        }
    }

    .fade-in {
        background-color: rgba(0, 0, 0, 0.5);
        display: none;
        height: 100vh;
        overflow: hidden;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1040;

        &.active {
            display: block;
        }
    }
}

@media (min-width: 960px) {
    .main-page {
        &.main-page_share-viewkey {
            padding: 0 32px;
        }

        &:not(.main-page_sidebar-opened) {
            margin-left: 0 !important;
        }
    }
    .main-page_sidebar-opened {
        .display-settings {
            max-width: 1200px;
        }
    }
    .main-block_expanded {
        .pdf-project,
        .share-viewkey {
            max-width: 1716px;
        }
    }
}

@media (min-width: 1441px) {
    .main-page_sidebar-opened {
        .main-block,
        .dashboard-overview {
            width: 100%;
        }
    }
}

@media screen and (max-width: 960px) {
    .main-page_sidebar-opened {
        overflow: hidden;
    }
}

@media (max-width: 959px) {
    .main-page {
        margin-left: 0 !important;
    }
    .mobile-background {
        content: '';
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s ease;
        top: $header-height;
        // top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        opacity: 0.5;
    }
}

.aside {
    @extend .custom-scroll;
    background-color: #f5f6f7 !important;
    display: flex;
    left: unset !important;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1062 !important;
    height: 100vh;
    top: unset !important;

    &.with_top_banner {
        height: calc(100vh - 48px);
    }

    .aside-dialog,
    .aside-content {
        display: flex;
        width: 100%;
    }

    .aside-header {
        display: none;
    }

    .aside-body {
        padding: 0 !important;
        display: flex;
        width: 100%;
    }

    &.archived-projects-aside {
        z-index: 1041;

        &.with_top_banner {
            height: calc(100vh - 48px);
        }
    }
}

.aside-backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
    width: 100% !important;
}

.main-page_dashboard {
    background-color: #f5f6f7;
}
</style>
