import {switchDateFormat, getDayFromNow, getFirstDayOfMonth, getMonthsBeforeDate} from '@/helpers/format-date-service';

export const datePickerList = {
    today: { label: 'Today', value: [switchDateFormat(new Date(), 'Y-m-d'), switchDateFormat(new Date(), 'Y-m-d')], count: 0, gscCount: 0 },
    yesterday: { label: 'Yesterday', value: [getDayFromNow('Y-m-d'), switchDateFormat(new Date(), 'Y-m-d')], count: 1, gscCount: 1 },
    this_week: { label: 'This week', value: [getDayFromNow('Y-m-d', 6), switchDateFormat(new Date(), 'Y-m-d')], count: 7, gscCount: 7 },
    this_month: { label: 'This month', value: [getFirstDayOfMonth('Y-m-d'), switchDateFormat(new Date(), 'Y-m-d')], count: 15, gscCount: 15 },
    last_month: { label: 'Last month', value: [getMonthsBeforeDate('Y-m-d', 1), switchDateFormat(new Date(), 'Y-m-d')], count: 30, gscCount: 30 },
    last_3_months: { label: 'Last 3 months', value: [getMonthsBeforeDate('Y-m-d', 3), switchDateFormat(new Date(), 'Y-m-d')], count: 90, gscCount: 91 },
    last_6_months: { label: 'Last 6 months', value: [getMonthsBeforeDate('Y-m-d', 6), switchDateFormat(new Date(), 'Y-m-d')], count: 180, gscCount: 184 },
    last_year: { label: 'Last year', value: [getMonthsBeforeDate('Y-m-d', 12), switchDateFormat(new Date(), 'Y-m-d')], count: 365, gscCount: 365 },
    all_time: { label: 'All time', value: [switchDateFormat(new Date('2010-01-01'), 'Y-m-d'), switchDateFormat(new Date(), 'Y-m-d')], count: 666666, gscCount: 666666 }
};

export const exactDatePickerList = {
    today: { label: 'Today', value: switchDateFormat(new Date(), 'Y-m-d'), altLabel: 'today', count: 0 },
    yesterday: { label: 'Yesterday', value: getDayFromNow('Y-m-d'), altLabel: 'daily', count: 1 },
    this_week: { label: 'This week', value: getDayFromNow('Y-m-d', 6), altLabel: 'weekly', count: 7 },
    this_month: { label: 'This month', value: getFirstDayOfMonth('Y-m-d'), altLabel: 'thismonth', count: 15 },
    last_month: { label: 'Last month', value: getMonthsBeforeDate('Y-m-d', 1), altLabel: 'monthly', count: 30 },
    last_3_months: { label: 'Last 3 months', value: getMonthsBeforeDate('Y-m-d', 3), altLabel: '3months', count: 90 },
    last_6_months: { label: 'Last 6 months', value: getMonthsBeforeDate('Y-m-d', 6), altLabel: 'semester', count: 180},
    last_year: { label: 'Last year', value: getMonthsBeforeDate('Y-m-d', 12), altLabel: 'yearly', count: 365 },
    all_time: { label: 'All time', value: switchDateFormat(new Date('2010-01-01'), 'Y-m-d'), altLabel: 'life', count: 666666 }
};
